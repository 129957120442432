import React from "react";
import Skeleton from "react-loading-skeleton";


function TrackingCardLoading({ count = 3 }) {
  const Card = () => {
    const array = [];
    for (let i = 0; i < count; i++) {
      array.push(
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            padding: "0.5rem",
            marginBottom: "0.2rem",
            border: "1px solid lightgrey",
            borderRadius: "0.5rem",
          }}
          key={`loading_${i}`}
        >
          <Skeleton height={50} width={50} count={1} borderRadius={"0.5rem"} />
          <div style={{ width: "100%" }}>
            <Skeleton
              height={10}
              count={3}
              borderRadius={"0.5rem"}
              style={{ marginBottom: "0.5rem" }}
            />
          </div>
        </div>
      );
    }
    return array;
  };
  return <Card />;
}

export default TrackingCardLoading;
