import { defaultThemes } from "react-data-table-component";
import { APIPayment } from "../utils/API";

// function
export const tableStyle = {
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: { fontWeight: "600", fontSize: "1rem" },
  },
  pagination: {
    style: {
      minHeight: "1rem",
      padding: "0.25rem 8px",
    },
    pageButtonsStyle: {
      padding: "0",
      height: "20px",
    },
  },
};

export const getPayoutRpt = async (payload) => {
  const { type, from_date, to_date, search, filter, page, limit } = payload;
  let list = undefined;
  if (type.name === "Payout") {
    list = await getRptPayoutList(
      from_date,
      to_date,
      search,
      filter,
      page,
      1
    );
  }
  if (type.name === "Invoice") {
    list = await getPayoutList(from_date, to_date, search, filter, page, 1);
  }

  return list;
};

// call API
export const getPayoutStatus = async () => {
  return APIPayment.get(`param/payout_status`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getPayoutList = async (
  from_date,
  to_date,
  search = "",
  filter = [],
  page = 1,
  limit = 20
) => {
  const _from_date = `?from_date=${from_date}`;
  const _to_date = `&to_date=${to_date}`;
  const _pages = `&page=${page}`;
  const _limits = `&limit=${limit}`;
  const _search = `&search=${search}`;
  const _filter = `&filter=[${filter}]`;
  return APIPayment.get(
    `payout_request${_from_date}${_to_date}${_pages}${_limits}${_search}${_filter}`
  )
    .then((res) => res.data.payout_request_data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const getRptPayoutList = async (
  from_date,
  to_date,
  search = "",
  filter = [],
  page = 1,
  limit = 20
) => {
  const _from_date = `?from_date=${from_date}`;
  const _to_date = `&to_date=${to_date}`;
  const _pages = `&page=${page}`;
  const _limits = `&limit=${limit}`;
  const _search = `&search=${search}`;
  const _filter = `&filter=[${filter}]`;
  return APIPayment.get(
    `report/payout${_from_date}${_to_date}${_pages}${_limits}${_search}${_filter}`
  )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const getPayoutDetail = async (req_id) => {
  return APIPayment.get(`report/payout_detail?payout_id=${req_id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const createPayoutRequest = async (payload) => {
  const data = new FormData();
  data.append(`payout_request_data`, JSON.stringify(payload.data));
  data.append(`bank`, JSON.stringify(payload.bank));
  return APIPayment.post(`payout_request/create`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};
