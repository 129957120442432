export const SET_USER_DATA = "SET_USER_DATA";
export const SET_STORE_DATA = "SET_STORE_DATA";
export const LOGIN_MODAL = "LOGIN_MODAL";

export const changeUser = (userData) => {
  return { type: SET_USER_DATA, userData };
};

export const changeStore = (storeData) => {
  return { type: SET_STORE_DATA, storeData };
};

export const toggleLogin = (flag) => {
  return { type: LOGIN_MODAL, flag };
};
