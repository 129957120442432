import React, { useMemo, useState } from 'react';
import style from "../../../css/sellerProfile/etalase.module.css"
import { Button, CDropdown } from '../../CustomComponent/StyledComponent';
import Switcher from '../../CustomComponent/Switcher';
import useDebounce from '../../../utils/useDebounce';
import { addUpdateEtalase } from '../../../controller/productEtalaseController';
import SubEtalaseCardView from './SubEtalaseCardView';
import { useSelector } from 'react-redux';

function EtalaseCardView({ data, is_private = false, cg_flag = false, length = 0,
    handleDelete, handlePopup, handleSubPopup, handleSubDelete,
}) {
    const sub_seller = useSelector((s) => s.global.userData.user_level_id) === 8;
    const [toggle, setToggle] = useState(sub_seller)
    const [status, setStatus] = useState(data.is_active === 1)
    const [seq, setSeq] = useState(data.seq)
    const etalase = { id: data.id, name: data.name }
    const _expan_icon = `bi bi-caret-${toggle ? 'up' : 'down'}-fill`
    const seqList = useMemo(() => Array.from(Array(length), (_, index) => index + 1), [length])

    const _handleChecked = (event) => setStatus(event.target.checked);
    const handleShow = () => setToggle(!toggle)
    const handleEdit = () => handlePopup(true, "EDIT", data)

    const handleAddSub = () => handleSubPopup(true, etalase)
    const handleEditSub = (subdata) => handleSubPopup(true, etalase, "EDIT", subdata)
    const handleDeleteSub = (sub_id) => handleSubDelete(data.id, sub_id)
    const ActionBtn = () => {
        if (sub_seller) return <></>
        return (
            <>
                <div className={style.seq_container}>
                    <label>Urutan :</label>
                    <CDropdown color="white" scroll_color='red'>
                        <CDropdown.Toggle id="dropdown-autoclose-true" style={{ minWidth: '50px' }}
                            disabled={seqList.length === 1}
                        >
                            {seq}
                        </CDropdown.Toggle>
                        <CDropdown.Menu className={style.seq_drop_menu}>
                            {seqList.map((item, index) => {
                                return (
                                    <CDropdown.Item
                                        key={`${data.name}_${index}`}
                                        onClick={() => setSeq(item)}
                                        className={style.seq_drop_item}
                                    >
                                        {item}
                                    </CDropdown.Item>
                                );
                            })}
                        </CDropdown.Menu>
                    </CDropdown>
                </div>
                {!status && (
                    <Button active onClick={() => handleDelete(data.id)}>
                        <i className="bi bi-trash-fill" />
                    </Button>
                )}
                <Switcher id={`user_acc${data.id}`} toggle={status} onChangeToggle={_handleChecked} />
                <Button active onClick={handleEdit}>
                    <i className='bi bi-pencil-square' />
                </Button>
            </>
        )
    }

    useDebounce(async () => {
        addUpdateEtalase({ ...data, is_active: status ? 1 : 0, seq })
    }, 500, [status, seq])

    return (
        <div>
            <div className={style.card}>
                <div className={style.name}>{data.name}</div>
                {!cg_flag && (
                    <div className={style.action_container}>
                        <ActionBtn />
                        {is_private && (
                            <>
                                <Button onClick={handleAddSub} style={{ minWidth: 'fit-content' }}>
                                    + Tambah Sub Etalase
                                </Button>
                                <Button
                                    onClick={handleShow}
                                    className={style.show_more}
                                    disabled={data.sub_etalase.length === 0}
                                >
                                    <i className={_expan_icon} />
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </div>
            {is_private && (
                <div className={`${style.warp_container} ${toggle ? style.open : ''}`}
                    style={{ overflow: toggle ? 'unset' : 'hidden' }}
                >
                    <div className={`${style.sub_etalase_container}`}>
                        {data.sub_etalase.map((subdata, idx) => {
                            return (
                                <SubEtalaseCardView key={`subet_${idx}`}
                                    data={subdata}
                                    etalase={etalase}
                                    length={data?.sub_etalase?.length ?? 0}
                                    handleDelete={handleDeleteSub}
                                    handleEdit={handleEditSub}
                                />
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default EtalaseCardView;