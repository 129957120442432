import React, { useState } from "react";
import { CNavDropdown } from "../CustomComponent/StyledComponent";
import style from "../../css/dropdown.module.css";
import { logout } from "../../controller/authController";
import { TRANS_PENDING, WISHLIST_UMENU } from "../../utils/ConstantUser";
import { useNavigate } from "react-router-dom";
import { globalStorage } from "../../utils/Storage";
import { useMemo } from "react";
import useDeviceDetect from "../../utils/useDeviceDetect";

function UserMenu({ user = undefined, ...props }) {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const isMobile = useDeviceDetect(toggle);

  const ecodomain = globalStorage.getItem("ED");
  const eco_flag = useMemo(() => {
    return ![undefined, "", null].includes(ecodomain) && isMobile;
  }, [ecodomain, isMobile]);
  const onClick = (link) => navigate(link);

  if (["", null, undefined].includes(user)) return <></>;
  return (
    <li>
      <div
        className={style.outer_navbar}
        onMouseEnter={() => setToggle(true)}
        onMouseLeave={() => setToggle(false)}
      >
        <CNavDropdown
          // title={`Halo, ${user.name}`}
          title={<i className="bi bi-person-circle" style={{fontSize:'2rem'}}></i>}
          className={style.navdrop}
          // onMouseEnter={() => setToggle(true)}
          // onMouseLeave={() => setToggle(false)}
          onClick={() => setToggle(!toggle)}
          show={toggle}
        >
          <CNavDropdown.Item
            onClick={() => onClick(`user_profile/transaction/${TRANS_PENDING}`)}
          >
            Transaksi
          </CNavDropdown.Item>
          <CNavDropdown.Item onClick={() => onClick(`user_profile`)}>
            Pengaturan
          </CNavDropdown.Item>
          <CNavDropdown.Item
            onClick={() => onClick(`user_profile/${WISHLIST_UMENU}`)}
          >
            Wishlist
          </CNavDropdown.Item>
          {!eco_flag && (
            <CNavDropdown.Item onClick={() => logout()}>Keluar</CNavDropdown.Item>
          )}
        </CNavDropdown>
      </div>
    </li>
  );
}

export default UserMenu;
