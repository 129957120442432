import React, { useState } from "react";
import { getTransItemList } from "../../../controller/transactionController";
import { Button } from "../../CustomComponent/StyledComponent";
import style from "../../../css/sellerProfile/sellerTransaction.module.css";
import {
  formatCurrency,
  formatDate,
} from "../../../controller/globalController";
import TransactionItem from "../../Transaction/TransactionItem";
import { updateTransStatus } from "../../../controller/sellerTransactionController";
import { PRINT_INV, PRINT_SENT_LBL } from "../../../utils/Constants";
import Swal from "sweetalert2";
import TrackingModalView from "../../Transaction/TrackingModalView";
import { useMemo } from "react";

const dateFormat = "DD MMMM YYYY";
function TransactionSellerView(props) {
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const {
    invoice_id,
    cart,
    status,
    status_name,
    customer_details,
    transaction_details,
  } = props.data;
  const {address, city, postal_code, phone } = customer_details.shipping_address;
  const _address = `${address}, ${city}, ${postal_code}`.toLowerCase();
  const first_name = customer_details.address.name;
  const item_list = useMemo(() => getTransItemList([cart]));

  // function
  const handlePrint = (type = PRINT_INV) => {
    const origin = window.location.origin;
    window.open(`${origin}/print/${type}/${invoice_id}`);
  };

  const handleProcess = (res) => {
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        confirmButtonColor: "var(--main_color)",
      }).then(() => window.location.reload());
    } else {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        confirmButtonColor: "var(--main_color)",
      }).then(() => Swal.close());
    }
  };

  const handleConfirm = () => {
    Swal.fire({
      title: "Loading...",
      didOpen: async () => {
        Swal.showLoading();
        const res = await updateTransStatus(invoice_id, "next");
        handleProcess(res);
      },
      allowOutsideClick: false,
    });
  };

  const handleReject = async () => {
    Swal.fire({
      title: "Loading...",
      didOpen: async () => {
        Swal.showLoading();
        const res = await updateTransStatus(invoice_id, "cancel");
        handleProcess(res);
      },
      allowOutsideClick: false,
    });
  };

  // view
  return (
    <>
      {toggle1 && (
        <TrackingModalView
          toggle={toggle1}
          courier={{ awb: props.data.awb, code: props.data.courier_code }}
          popupHandler={() => setToggle1(false)}
        />
      )}
      <div className={style.trans_container_item}>
        <div style={{ width: "100%" }}>
          <div className={style.inner_trans_container1}>
            <div className={style.inv_no}>{`No. Invoice : ${invoice_id}`}</div>
            <div id={style.stat}>{`Status : ${status_name}`}</div>
            {status === "1" && (
              <div id={style._date}>
                {`Konfirmasi Sebelum ${formatDate(
                  props.data.expired_time,
                  dateFormat
                )}`}
              </div>
            )}
          </div>
          <div className={style.inner_trans_container2}>
            <div className={`${style.trans_item_container}`}>
              <div
                className={`${style.trans_item} ${toggle ? style.show : ""}`}
              >
                {item_list.map((data, idx) => {
                  const key = `${data._product.dtl_product_id}_${idx}`;
                  if ((!toggle && idx === 0) || toggle) {
                    data.awb = props.data.awb;
                    return <TransactionItem key={key} data={data} />;
                  }
                  return;
                })}
              </div>
              {item_list.length > 1 && (
                <div
                  className={style.show_more}
                  onClick={() => setToggle(!toggle)}
                >
                  {!toggle
                    ? `+${item_list.length - 1} Produk lainnya`
                    : "Lihat lebih sedikit"}
                </div>
              )}
            </div>

            <div className={style.trans_detail}>
              <table className={style.detail}>
                <tbody>
                  <tr>
                    <td>Nama</td>
                    <td>:</td>
                    <td>{first_name}</td>
                  </tr>
                  <tr>
                    <td>Alamat</td>
                    <td>:</td>
                    <td>{_address}</td>
                  </tr>
                  <tr>
                    <td>No.Hp</td>
                    <td>:</td>
                    <td>{phone}</td>
                  </tr>
                  <tr>
                    <td className={style.detail_title}>Total Belanja</td>
                    <td>:</td>
                    <td>{formatCurrency(transaction_details.gross_amount)}</td>
                  </tr>
                  <tr>
                    <td className={style.detail_title}>Metode Pembayaran</td>
                    <td>:</td>
                    <td>{transaction_details?.payment_type?.name ?? ""}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className={style.btn_container}
            style={{ justifyContent: "flex-end" }}
          >
            <Button active onClick={() => handlePrint()}>
              Cetak Invoice
            </Button>
            {status === "3" && (
              <>
                <Button active onClick={() => handlePrint(PRINT_SENT_LBL)}>
                  Cetak Label
                </Button>
                <Button active onClick={() => setToggle1(!toggle1)}>
                  Lacak
                </Button>
              </>
            )}
            {["1", "2", "6", "7", "8", "98"].includes(status) && (
              <>
                <Button
                  active
                  bg_color={["1", "98"].includes(status) ? 'green' : ""}
                  onClick={() => handleConfirm()}
                >
                  {["1", "98"].includes(status) && "Terima"}
                  {status === "2" && "Pickup"}
                  {status === "6" && "Paket sudah diambil"}
                  {status === "7" && "Pesanan sampai"}
                </Button>
                <Button bg_color='red' active onClick={() => handleReject()}>
                  Tolak
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TransactionSellerView;
