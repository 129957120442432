import React from "react";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import Swal from "sweetalert2";
import { deleteWishList } from "../../controller/wishlistController";
import style from "../../css/sellerProfile/actionProductMenu.module.css";
import w_style from "../../css/userProfile/wishlist.module.css";

function WishlistActionView({ product_id, dtl_product_id = null, ...props }) {
  const [toggle, setToggle] = useState(false);
  const menu_style = `${style.menu_container} ${style[toggle ? "down" : "up"]}`;

  // function
  const handleDelete = async (e) => {
    e.stopPropagation();
    Swal.fire({
      didOpen: () => Swal.showLoading(),
      allowOutsideClick: false,
    });

    const res = await deleteWishList(product_id, dtl_product_id);
    Swal.fire({
      icon: res ? "success" : "error",
      text: `Wishlist ${res ? "berhasil" : "gagal"} dihapus`,
      confirmButtonColor: "var(--main_color)",
    }).then(() => {
      Swal.close();
      if (res) window.location.reload();
    });
  };
  const handleToggle = (e) => {
    e.stopPropagation();
    setToggle(!toggle);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setToggle(false)}>
      <div className={style.container}>
        <div className={w_style.action_btn} onClick={(e) => handleToggle(e)}>
          <i className="bi bi-three-dots" />
        </div>
        <ul className={menu_style} style={{ width: "120px", fontSize: "1rem" }}>
          <li onClick={(e) => handleDelete(e)}>Hapus</li>
        </ul>
      </div>
    </OutsideClickHandler>
  );
}

export default WishlistActionView;
