import React from "react";
import { useLocation } from "react-router-dom";
import style from "../../css/mainCSS/footer.module.css";
import { useSelector } from "react-redux";
import google_play_img from "../../assets/images/google-play-badge.png";

export const FooterPart = (props) => {
  const { title = "", children } = props;
  return (
    <div className={style.part_container}>
      <div className={style.part_title}>{title}</div>
      {children}
    </div>
  );
};

function Footer(props) {
  const location = useLocation();
  const storeData = useSelector((s) => s.global.storeData);
  const path = location.pathname.split("/")[1];
  const exception_page = [
    "login", "cart", "print", "register", "midtrans", "user_profile",
    "seller_dashboard", "create_store", "reset_password", "ecosystem_login"
  ];
  const exception_flag = exception_page.includes(path);
  const multiproFlag = [308, 27, 46].includes(storeData?.id ?? undefined)

  // view
  if (exception_flag) return <></>;
  return (
    <div className={`body_part_container ${style.container}`}>
      <FooterPart title="1TOKO">
        <a
          href="https://about.1toko.com"
          target="_blank"
          rel="noopener noreferrer"
        >{`Tentang Kami`}</a>
        <a href="/terms">{`Syarat & Ketentuan`}</a>
        <a href="/privacy">{`Kebijakan Privasi`}</a>
        <a href="#!">{`Kebijakan Pengiriman`}</a>
        <a href="#!">{`Kebijakan Pengembalian`}</a>
      </FooterPart>
      <FooterPart title="Bantuan">
        <a href="#!">{`Cara Pemesanan`}</a>
        <a href="#!">{`1Toko Care`}</a>
      </FooterPart>
      <FooterPart title="Hubungi Kami">
        <a href="#!">
          <i className="bi bi-envelope" />
          {`cs@1toko.com`}
        </a>
        <a href="#!">
          <i className="bi bi-telephone-fill" />
          {`(CS) +62 812 1322 8501`}
        </a>
        <a href="#!">
          <i className="bi bi-telephone-fill" />
          {`(021) 2126 2365`}
        </a>
      </FooterPart>
      <FooterPart title="Ikuti Kami">
        <a href={`https://www.twitter.com/@1tokoofficial`} target="blank">
          <i className="bi bi-twitter" style={{ color: "#00acee" }} />
          {`@1tokoofficial`}
        </a>
        <a href={`https://www.instagram.com/1toko_official`} target="blank">
          <i className="bi bi-instagram" />
          {`@1tokoofficial`}
        </a>
      </FooterPart>
      <FooterPart title="Unduh Aplikasi">
        <img
          src={google_play_img}
          alt={"google play"}
          style={{ maxWidth: "200px" }}
        />
      </FooterPart>
    </div>
  )
}

export default Footer;
