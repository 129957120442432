import {
  SET_CART_LIST,
  SET_USER_LOC,
  SET_TPR,
  SET_TCP,
  INIT_SHIPMENT,
  SET_USER_LOC_LIST,
  SET_TIN,
} from "./transactionAction";

const initialState = {
  // cart
  cartList: [],
  totalItem: 0,
  totalPrice: 0,
  totalInsurance: 0,
  // shipment
  shipmentList: [],
  userLocationList: [],
  paymentList: [],
  userLocation: undefined,
  detailPromo: undefined,
  totalDiscount: 0,
  totalCommision: 0,
  totalCourierPrice: 0,
};

const TransactionReducer = (state = initialState, payload) => {
  const {
    cartList,
    totalItem = 0,
    totalPrice = 0,
    cartData,
    detailPromo,
    initShipment,
  } = payload;

  let tempList = [...state.shipmentList];
  switch (payload.type) {
    case SET_CART_LIST:
      return { ...state, cartList, totalItem, totalPrice, loading: false };
    case SET_USER_LOC_LIST:
      return {
        ...state,
        userLocationList: payload.userLocList,
      };
    case INIT_SHIPMENT:
      let {
        shipmentList,
        totalData,
        userLocationList,
        userLocation,
        paymentList,
      } = initShipment;
      return {
        ...state,
        shipmentList,
        userLocationList,
        paymentList,
        userLocation,
        totalItem: totalData.totalItem,
        totalPrice: totalData.totalPrice,
      };
    case SET_USER_LOC:
      return { ...state, userLocation: payload.userLocation };
    case SET_TPR:
      return {
        ...state,
        detailPromo,
        totalDiscount: payload.totalDiscount,
        totalCommision: payload.totalCommision,
      };
    case SET_TIN:
      tempList.map((data) => {
        if (data.id === cartData.id) data.insurance = cartData.insurance;
        return data;
      });
      let tin = tempList.reduce((total, data) => {
        return total + parseInt(data.insurance);
      }, 0);
      return { ...state, totalInsurance: tin, shipmentList: tempList };
    case SET_TCP:
      tempList.map((data) => {
        if (data.id === cartData.id) data.selected_courier = cartData.service;
        return data;
      });
      let tcp = tempList.reduce((total, data) => {
        return total + parseInt(data.selected_courier?.price ?? 0);
      }, 0);
      return { ...state, totalCourierPrice: tcp, shipmentList: tempList };
    default:
      return state;
  }
};

export default TransactionReducer;
