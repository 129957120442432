import React, { useState } from "react";
import { useEffect } from "react";
import style from "../../../../css/varianForm/varianDropdown.module.css";
import { Input } from "../../../CustomComponent/StyledComponent";

function InputTypeVarianView({
  idx,
  value,
  toggle,
  handleChange,
  handleChangeType,
  ...props
}) {
  const [state, setState] = useState({
    type: value,
    is_edit: false,
  });
  //  function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeNewType = (e) => changeState({ type: e.target.value });
  const changeEdit = (is_edit = false) => changeState({ is_edit });
  const handleDetele = () => handleChange(idx, "delete", state.type);
  const handleEditSubmit = (e) => {
    e.preventDefault();
    changeState({ is_edit: false });
    handleChange(idx, "edit", state.type);
  };

  useEffect(() => changeState({ is_edit: false }), [toggle]);

  return (
    <li className={props.className}>
      {!state.is_edit && (
        <>
          <span onClick={() => handleChangeType()}>{value}</span>
          <div className={style.action_container}>
            {!props.selected && (
              <i className="bi bi-trash-fill" onClick={() => handleDetele()} />
            )}
            <i
              className="bi bi-pencil-square"
              onClick={() => changeEdit(true)}
            />
          </div>
        </>
      )}
      {state.is_edit && toggle && (
        <div className={style.add_btn_container}>
          <div className={style.input_title}>
            <span>Buat varian baru</span>
            <span>{`${state.type.length} / 30`}</span>
          </div>
          <div className={style.input_container}>
            <form onSubmit={(e) => handleEditSubmit(e)}>
              <Input
                value={state.type}
                onChange={(e) => changeNewType(e)}
                maxLength={30}
                autoFocus
              />
            </form>
            <i className="bi bi-x-lg" onClick={() => changeEdit()} />
          </div>
        </div>
      )}
    </li>
  );
}

export default InputTypeVarianView;
