import { APIAuth } from "../utils/API";

// function
export const addUpdateUserLoc = async (data, process) => {
  let res = undefined;
  if (process === "ADD") {
    const _data = new FormData();
    _data.append("name", data.name);
    _data.append("hp", `+62${data.hp}`);
    _data.append("alias", data.alias);
    _data.append("zip_id", data.zip_id);
    _data.append("address", data.address);
    _data.append("longitude", data.longitude);
    _data.append("latitude", data.latitude);
    _data.append("detail", data.detail);
    res = await storeUserLoc(_data);
  }

  if (process === "UPDATE") {
    const _data = {
      name: data.name,
      hp: `+62${data.hp.replaceAll("+62", "")}`,
      alias: data.alias,
      zip_id: data.zip_id.toString(),
      address: data.address,
      longitude: data.longitude,
      latitude: data.latitude,
      detail: data.detail,
    };
    res = await updateUserLoc(_data, data.id);
  }

  return res;
};

// call API
export const getUserLocation = async () => {
  return APIAuth.get(`userlocation`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return undefined;
    });
};

const storeUserLoc = async (data) => {
  return APIAuth.post(`userlocation`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

const updateUserLoc = async (data, id) => {
  return APIAuth.put(`userlocation/${id}`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const deleteUserLoc = async (id) => {
  return APIAuth.delete(`userlocation/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const setLU_AsPrimary = async (id) => {
  return APIAuth.put(`userlocation/setprimary/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const getProvinceList = async () => {
  return APIAuth.get(`location/province`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getSubProvinceList = async (data) => {
  const prov = `?province=${data}`;
  return APIAuth.get(`location/sub_province${prov}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getDistrictList = async (data) => {
  const prov = `?province=${data.province}`;
  const subprov = `&sub_province=${data.sub_province}`;
  return APIAuth.get(`location/district${prov}${subprov}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getSubDistrictList = async (data) => {
  const prov = `?province=${data.province}`;
  const subprov = `&sub_province=${data.sub_province}`;
  const dist = `&district=${data.district}`;
  return APIAuth.get(`location/sub_district${prov}${subprov}${dist}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};
