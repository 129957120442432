import React from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import { formatDate } from "../../../../controller/globalController";
import { Button } from "../../../CustomComponent/StyledComponent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function PayoutReportExcel({ dataset = [], type = "", ...props }) {
  const FD = "DD - MM - YYYY";
  if (type === "Payout") {
    return (
      <ExcelFile
        element={
          <Button active disabled={props.disabled}>
            Ekspor Excel
          </Button>
        }
        filename="Laporan Penarikan Dana"
      >
        <ExcelSheet data={dataset} name="Laporan Penarikan Dana">
          <ExcelColumn label="No. Permintaan" value="id" />
          <ExcelColumn label="Status" value="status_name" />
          <ExcelColumn
            label="Tanggal Pengajuan"
            value={(col) => formatDate(col.request_date, FD)}
          />
          <ExcelColumn
            label="Tanggal Disetujui"
            value={(col) => formatDate(col.approve_date, FD)}
          />
          <ExcelColumn
            label="Tanggal Ditolak "
            value={(col) => formatDate(col.reject_date, FD)}
          />
          <ExcelColumn label="Status Penarikan Dana" value="status_name" />
          <ExcelColumn label="Nominal" value="paid_amount" />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  if (type === "Invoice") {
    return (
      <ExcelFile
        element={
          <Button active disabled={props.disabled}>
            Ekspor Excel
          </Button>
        }
        filename="Laporan Penarikan Dana (Invoice ver.)"
      >
        <ExcelSheet data={dataset} name="Laporan Penarikan Dana">
          <ExcelColumn label="No. Invoice" value="invoice_id" />
          <ExcelColumn label="Tanggal Transaksi" value="date" />
          <ExcelColumn label="Status Penarikan Dana" value="status" />
          <ExcelColumn label="Nominal" value="paid_amount" />
        </ExcelSheet>
      </ExcelFile>
    );
  }

  return <></>;
}

export default PayoutReportExcel;
