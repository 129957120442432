import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import style from "../../../css/sellerProfile/promo.module.css";
import { PRM_UNIT } from "../../../utils/ConstantSeller";
import {
  Button,
  CDropdown,
  Input,
} from "../../CustomComponent/StyledComponent";
import DatePicker from "react-datepicker";
import {
  regexName,
  regexNumber,
  regexReferral,
} from "../../../utils/Constants";
import {
  addUpdatePromo,
  checkPromo,
  generatePromoCode,
} from "../../../controller/promoController";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import id from "date-fns/locale/id";

const from_date = dayjs().format("YYYY-MM-DD");
const to_date = dayjs().add(1, "days").format("YYYY-MM-DD");
const _FDate = "dd - MM - yyyy";
const initState = {
  // form
  id: undefined,
  promo_code: "",
  name: "",
  discount_value: "0",
  max_discount_value: 0,
  discount_unit: "",
  commission_value: 0,
  commission_unit: "",
  start_period: dayjs(from_date).toDate(),
  end_period: dayjs(to_date).toDate(),

  // err
  err_name: "",
  err_pcode: "",
  err_discv: "",
  err_mdiscv: "",
};

const UnitSelectView = (props) => {
  const { value, name, onClick } = props;
  return (
    <CDropdown className={style.unit_dropdown} color="white">
      <CDropdown.Toggle id="dropdown-autoclose-true">
        {value ? value.label : "Unit"}
      </CDropdown.Toggle>
      <CDropdown.Menu>
        {PRM_UNIT.map((data, idx) => {
          return (
            <CDropdown.Item
              key={`${name}_${idx}`}
              onClick={() => onClick({ [name]: data })}
            >
              {data.label}
            </CDropdown.Item>
          );
        })}
      </CDropdown.Menu>
    </CDropdown>
  );
};

function ReferralSettingFormView(props) {
  const { selected_promo, loadList, isEdit } = props;
  const seller = useSelector((s) => s.global.userData).seller;
  const [state, setState] = useState(initState);

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeDate = (date, type) => {
    const data = { [`${type}_period`]: date };
    if (type === "start") data.end_period = dayjs(date).add(1, "day").toDate();
    changeState(data);
  };

  const changeNumber = (event) => {
    const { name, value } = event.target;
    if (regexNumber.test(value) || value === "") changeState({ [name]: value });
  };

  const changeUnit = (data) => {
    const _val = data?.discount_unit?.value ?? "";
    if (_val === "rupiah") data.max_discount_value = 0;
    changeState(data);
  };

  const changeName = (event) => {
    const { name, value } = event.target;
    const _val = name === "promo_code" ? value.toUpperCase() : value;
    const name_flag = regexName.test(_val) && name === "name";
    const ref_flag = regexReferral.test(_val) && name === "promo_code";
    if (name_flag || ref_flag || _val === "") {
      changeState({ [name]: _val });
    }
  };

  const checkSubmit = () => {
    let res = true;
    let _err = {};
    const {
      name,
      promo_code,
      discount_value,
      discount_unit,
      max_discount_value,
    } = state;
    const max_disc = max_discount_value ?? 0;
    const flag_limit = discount_unit.value === "percent" && max_disc === 0;
    if (name === "" || promo_code === "" || discount_value === 0 || flag_limit)
      res = false;

    if (name === "") _err.err_name = "Nama Pengguna tidak boleh kosong";
    else _err.err_name = "";

    if (promo_code === "") _err.err_pcode = "Kode Referral tidak boleh kosong";
    else _err.err_pcode = "";

    if (discount_value === 0) _err.err_discv = "Nominal diskon tidak boleh 0";
    else _err.err_discv = "";

    if (flag_limit) _err.err_mdiscv = "Maksimal nominal diskon tidak boleh 0";
    else _err.err_mdiscv = "";

    changeState(_err);
    return res;
  };

  const handleSubmit = async () => {
    if (checkSubmit()) {
      Swal.fire({
        didOpen: () => Swal.showLoading(),
        allowOutsideClick: false,
      });
      const check = await checkPromo(state.promo_code, seller.id);
      if (check || isEdit) {
        const res = await addUpdatePromo(state);
        if (res) {
          loadList();
          setState(initState);
        }
      } else {
        changeState({ err_pcode: "Kode referral sudah digunakan" });
      }
      Swal.close();
    }
  };

  const _generatePromoCode = async () => {
    const res = await generatePromoCode();
    changeState({ promo_code: res });
  };

  const initComponent = () => {
    if (selected_promo && isEdit) {
      let {
        commission_value,
        commission_unit,
        discount_value,
        discount_unit,
        start_period,
        end_period,
        max_discount_value,
      } = selected_promo;
      commission_unit = PRM_UNIT.find((data) => data.value === commission_unit);
      discount_unit = PRM_UNIT.find((data) => data.value === discount_unit);
      start_period = dayjs(start_period).toDate();
      end_period = dayjs(end_period).toDate();
      max_discount_value = max_discount_value ?? 0;
      changeState({
        id: selected_promo.id,
        name: selected_promo.name,
        promo_code: selected_promo.promo_code,
        commission_unit,
        commission_value,
        discount_value,
        discount_unit,
        start_period,
        end_period,
        max_discount_value,
      });
    }

    if (!isEdit) setState(initState);
  };
  // useEffect
  useEffect(() => initComponent(), [selected_promo, isEdit]);

  // view
  return (
    <div className={style.referal_form}>
      <div className={style._form}>
        <div>
          <div className={style.input_container}>
            <label>Nama Pengguna</label>
            <Input
              name="name"
              value={state.name}
              onChange={(e) => changeName(e)}
            />
            {state.err_name !== "" && (
              <label className="error_txt">{state.err_name}</label>
            )}
          </div>
          <div className={style.input_container}>
            <label>Kode Referral</label>
            <div className="group_input">
              <Input
                name="promo_code"
                maxLength={8}
                value={state.promo_code}
                onChange={(e) => changeName(e)}
                disabled={isEdit}
              />
              <Button
                active
                onClick={() => _generatePromoCode()}
                disabled={isEdit}
              >
                ACAK
              </Button>
            </div>
            {state.err_pcode !== "" && (
              <label className="error_txt">{state.err_pcode}</label>
            )}
          </div>
          <div className={style.input_container}>
            <label>Periode Diskon</label>
            <div className={style.date_container}>
              <DatePicker
                locale={id}
                dateFormat={_FDate}
                minDate={dayjs().toDate()}
                selected={state.start_period}
                onChange={(date) => changeDate(date, "start")}
              />
              <span>s/d</span>
              <DatePicker
                locale={id}
                dateFormat={_FDate}
                minDate={state.start_period}
                selected={state.end_period}
                onChange={(date) => changeDate(date, "end")}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={style.input_container}>
            <label>Nominal Diskon</label>
            <div className="group_input">
              <Input
                name="discount_value"
                value={state.discount_value}
                onChange={(e) => changeNumber(e)}
                maxLength={state.discount_unit.value === "percent" ? 2 : ""}
                disabled={state.discount_unit === ""}
              />
              <UnitSelectView
                name="discount_unit"
                value={state.discount_unit}
                onClick={changeUnit}
              />
            </div>
            {state.err_discv !== "" && (
              <label className="error_txt">{state.err_discv}</label>
            )}
          </div>
          <div className={style.input_container}>
            <label>Nominal Komisi</label>
            <div className="group_input">
              <Input
                name="commission_value"
                value={state.commission_value}
                onChange={(e) => changeNumber(e)}
                maxLength={state.commission_unit.value === "percent" ? 2 : ""}
                disabled={state.commission_unit === ""}
              />
              <UnitSelectView
                name="commission_unit"
                value={state.commission_unit}
                onClick={changeUnit}
              />
            </div>
          </div>
          {state.discount_unit.value === "percent" && (
            <div className={style.input_container}>
              <label>Limit Diskon</label>
              <Input
                name="max_discount_value"
                value={state.max_discount_value}
                onChange={(e) => changeNumber(e)}
                disabled={state.discount_unit.value !== "percent"}
              />
              {state.err_mdiscv !== "" && (
                <label className="error_txt">{state.err_mdiscv}</label>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={style.btn_container}>
        <Button onClick={() => setState(initState)}>Batal</Button>
        <Button active onClick={() => handleSubmit(state)}>
          Simpan
        </Button>
      </div>
    </div>
  );
}

export default ReferralSettingFormView;
