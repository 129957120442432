import { APIAuth, APIImg } from "../utils/API";

export const changePassword = async (data) => {
  let res = undefined;
  let post_data = new FormData();
  post_data.append("old_password", data.old_pass);
  post_data.append("password", data.new_pass);
  post_data.append("password_confirmation", data.conf_pass);
  res = await APIAuth.post("auth/changepassword", post_data)
    .then((res) => res.data)
    .catch((err) => err.response.data);
  return res;
};

export const updateProfile = async (type, value) => {
  return APIAuth.post(`auth/update${type}`, { [type]: value })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const updateHp = (handphone) => {
  return APIAuth.post(`auth/updatehp`, { handphone })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const updateEmail = (payload) => {
  const postdata = new FormData();
  postdata.append("email", payload.email);
  postdata.append("password", payload.password);
  postdata.append("password_confirmation", payload.conf_password);
  return APIAuth.post(`auth/updateemail`, postdata)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const updateImageProfile = async (image) => {
  let res = false;
  // upload img
  const data = new FormData();
  data.append("image", image);
  const upload_img = await APIImg.post(`image/upload`, data)
    .then((res) => res.data)
    .catch((err) => {
      return undefined;
    });

  if (upload_img) {
    res = await APIAuth.post(`auth/updatelogo`, { logo: upload_img.name })
      .then((res) => res.data.logo)
      .catch((err) => {
        console.log(err);
        return false;
      });
  }
  return res;
};
