import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import {
  getDistrictList,
  getProvinceList,
  getSubDistrictList,
  getSubProvinceList,
} from "../../controller/userLocationController";
import { Tab, InputLine } from "../CustomComponent/StyledComponent";
import style from "../../css/location/locationInput.module.css";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import Loading from "../Loading/Loading";

function MenuItemView({ list, name, value, onClick }) {
  return list.map((d, idx) => {
    const flag = d[name] === value;
    const _style = `${style.item} ${flag ? style.selected : ""}`;
    return (
      <div key={name + idx} className={_style} onClick={() => onClick(d)}>
        {d[name]}
      </div>
    );
  });
}

const initState = {
  _location: "",
  province: "",
  subProvince: "",
  district: "",
  subDistrict: "",
  zip: "",
  zip_id: "",
  // address prm
  provinceList: [],
  subProvinceList: [],
  districtList: [],
  subDistricList: [],
  // other
  tab: 0,
  toggle: false,
  is_loading: true,
};

function LocationInputView(props) {
  const { location = undefined, getLocation, error } = props;
  const [state, setState] = useState(initState);
  const {
    _location,
    province,
    subProvince,
    district,
    zip,
    zip_id,
    subDistrict,
    provinceList,
    subProvinceList,
    districtList,
    subDistricList,
    tab,
    toggle,
  } = state;
  const placeholder = !_location
    ? "Provinsi, Kota, Kecamatan, Kelurahan, Kode Pos"
    : _location;
  const fsp = subProvinceList.length === 0 ?? false;
  const fd = districtList.length === 0 ?? false;
  const fsd = subDistricList.length === 0 ?? false;

  //   function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeTab = (tab) => changeState({ tab });
  const changeProvice = async (data) => {
    changeState({ is_loading: true });
    const subProvinceList = await getSubProvinceList(data.province);
    changeState({
      tab: 1,
      province: data.province,
      subProvinceList,
      districtList: [],
      subDistricList: [],
      subProvince: undefined,
      district: undefined,
      subDistrict: undefined,
      zip: undefined,
      zip_id: undefined,
      is_loading: false,
    });
  };

  const changeSubProvince = async (data) => {
    changeState({ is_loading: true });
    const subProvince = data.sub_province;
    const districtList = await getDistrictList({
      province: province,
      sub_province: subProvince,
    });
    const loc = `${province}, ${subProvince}`;
    changeState({
      tab: 2,
      subProvince: subProvince,
      districtList,
      subDistricList: [],
      district: undefined,
      subDistrict: undefined,
      zip: undefined,
      zip_id: undefined,
      _location: loc,
      is_loading: false,
    });
  };

  const changeDistrict = async (data) => {
    changeState({ is_loading: true });
    const subDistricList = await getSubDistrictList({
      province: province,
      sub_province: subProvince,
      district: data.district,
    });
    const loc = `${province}, ${subProvince}, ${data.district}`;
    changeState({
      tab: 3,
      district: data.district,
      subDistricList,
      subDistrict: undefined,
      zip: undefined,
      zip_id: undefined,
      _location: loc,
      is_loading: false,
    });
  };

  const changeSubDistrict = async (data) => {
    const { sub_district, zip, id } = data;
    const loc = `${province}, ${subProvince}, ${district}, ${sub_district}, ${zip}`;
    changeState({
      subDistrict: sub_district,
      _location: loc,
      zip,
      zip_id: id,
      tab: 0,
      toggle: false,
    });
  };

  const handlePopup = async (val) => {
    changeState({ toggle: val, tab: 0 });
  };

  //   useEffect
  useMemo(() => {
    if (location) {
      const { province, subProvince, district, subDistrict, zip } = location;
      const _location = `${province}, ${subProvince}, ${district}, ${subDistrict}, ${zip}`;
      changeState({
        _location,
        province,
        subProvince,
        district,
        subDistrict,
        zip,
      });
    }
  }, []);

  useEffect(() => {
    const initPage = async () => {
      changeState({ is_loading: true });
      const data = {};
      data.provinceList = await getProvinceList();
      if (location) {
        data.subProvinceList = await getSubProvinceList(province);
        data.districtList = await getDistrictList({
          province,
          sub_province: subProvince,
        });
        data.subDistricList = await getSubDistrictList({
          province,
          sub_province: subProvince,
          district: district,
        });
      }
      changeState({ ...data, is_loading: false });
    };
    initPage();
  }, [location]);

  useEffect(() => {
    if (zip_id) {
      getLocation(_location, {
        province,
        subProvince,
        district,
        subDistrict,
        zip,
        zip_id,
      });
    }
  }, [zip_id]);

  //   view
  return (
    <OutsideClickHandler onOutsideClick={() => handlePopup(false)}>
      <div className={style.container}>
        <div className="line_input_container">
          <label htmlFor="address">
            Provinsi, Kota, Kecamatan, Kelurahan, Kode Pos
          </label>
          <InputLine
            id="address"
            value={_location}
            placeholder={placeholder}
            readOnly
            onClick={() => handlePopup(!toggle)}
          />
          {error !== "" && <label className="error_txt">{error}</label>}
        </div>
        <div
          className={`${style.container_menu} ${
            toggle ? style.show : style.hide
          }`}
        >
          <div className={style.tab_menu}>
            <Tab active={tab === 0} onClick={() => changeTab(0)}>
              Provinsi
            </Tab>
            <Tab active={tab === 1} disabled={fsp} onClick={() => changeTab(1)}>
              Kota
            </Tab>
            <Tab active={tab === 2} disabled={fd} onClick={() => changeTab(2)}>
              Kecamatan
            </Tab>
            <Tab active={tab === 3} disabled={fsd} onClick={() => changeTab(3)}>
              Kelurahan
            </Tab>
          </div>
          <div className={`${style.menu_item_container} `}>
            {state.is_loading ? (
              <Loading />
            ) : (
              <>
                {tab === 0 && (
                  <MenuItemView
                    name="province"
                    value={state.province}
                    list={provinceList}
                    onClick={changeProvice}
                  />
                )}
                {tab === 1 && (
                  <MenuItemView
                    name="sub_province"
                    value={state.subProvince}
                    list={subProvinceList}
                    onClick={changeSubProvince}
                  />
                )}
                {tab === 2 && (
                  <MenuItemView
                    name="district"
                    value={state.district}
                    list={districtList}
                    onClick={changeDistrict}
                  />
                )}
                {tab === 3 && (
                  <MenuItemView
                    name="sub_district"
                    value={state.subDistrict}
                    list={subDistricList}
                    onClick={changeSubDistrict}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export default LocationInputView;
