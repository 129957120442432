import React from "react";
import Skeleton from "react-loading-skeleton";

import { InlineWrapper } from "../../controller/loadingController";
import loc_style from "../../css/location/location.module.css";

function LocationCardLoading({ count = 5 }) {
  const LocationCard = () => {
    return (
      <div
        className={loc_style.card_container}
        style={{ borderColor: "lightgrey" }}
      >
        <div className={loc_style.dtl_container}>
          <Skeleton
            height={20}
            count={count}
            wrapper={(obj) => InlineWrapper(obj, { marginBottom: "0.2rem" })}
          />
        </div>
        <div className={loc_style.act_container}>
          <Skeleton height={20} width={100} count={1} />
          <Skeleton height={20} width={100} count={1} />
        </div>
      </div>
    );
  };
  return (
    <div>
      <LocationCard />
      <LocationCard />
    </div>
  );
}

export default LocationCardLoading;
