import dayjs from "dayjs";
import { APIPayment } from "../utils/API";
import { TMS_CANCELED, TMS_COMPLAINT, TMS_CONFRIM, TMS_FINISHED, TMS_NOT_PAID, TMS_PICKUP, TMS_RCANCEL, TMS_SENT } from "../utils/ConstantSeller";

export const getTransactionData = async (seller_id, status, from_date, to_date, cancel) => {
  if (status === TMS_COMPLAINT) return await getComplaintList();
  if (status === TMS_NOT_PAID) return await getPendingList(seller_id, cancel);

  let stat = "";
  if (status === TMS_CONFRIM) stat = 1;
  if (status === TMS_PICKUP) stat = "ready_pickup";
  if (status === TMS_SENT) stat = "delivery";
  if (status === TMS_FINISHED) stat = "complete";
  if (status === TMS_RCANCEL) stat = 98;
  if (status === TMS_CANCELED) stat = 99;
  return await getTransactionList(stat, from_date, to_date, cancel);
};

// call Api
const getTransactionList = async (status, from_date, to_date, cancel = null) => {
  const post = new FormData();
  post.append("status", status);
  post.append("seller_view", 1);
  post.append("start_date", dayjs(from_date).format("YYYY-MM-DD"));
  post.append("end_date", dayjs(to_date).format("YYYY-MM-DD"));
  return APIPayment.post(`status/transaction`, post, { signal: cancel?.signal ?? null })
    .then((res) => res.data)
    .catch((err) => {
      // console.log(err);
      return [];
    });
};

const getComplaintList = async () => {
  return [];
};

export const updateTransStatus = async (invoice_id, status) => {
  return APIPayment.post(`status/update`, { status, invoice_id })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const getStorePerformance = async (date, cancel) => {
  const _date = `?date=${date}`;
  return APIPayment.get(`report/store_performance${_date}`, {
    signal: cancel?.signal ?? null,
  })
    .then((res) => res.data)
    .catch((err) => {
      // console.log(err);
      return undefined;
    });
};

export const getProductRank = async (cancel) => {
  return APIPayment.get(`report/product_rank`, {
    signal: cancel?.signal ?? null,
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getPendingList = async (seller_id, cancel) => {
  return APIPayment.get(`transaction/pending?seller_id=${seller_id}`, {}, { signal: cancel?.signal ?? null })
    .then((res) => res.data)
    .catch((err) => { console.log(err); return []; });
}