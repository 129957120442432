import React from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import { deleteProduct } from "../../../controller/sellerProductController";
import style from "../../../css/sellerProfile/actionProductMenu.module.css";
import {
  ADD_PRODUCT_SMENU,
  EDIT_PRODUCT_SMENU,
} from "../../../utils/ConstantSeller";
import { useNavigate } from "react-router-dom";

function ActionMenuView({ toggle, changeToggle, ...props }) {
  const { product_id } = props;
  const navigate = useNavigate();

  const menu_style = `${style.menu_container} ${style[toggle ? "down" : "up"]}`;

  // function
  const handleDelete = () => deleteProduct(product_id);
  const handleEdit = () => navigate(`/seller_dashboard/${EDIT_PRODUCT_SMENU}/${product_id}`);
  const handleDuplicate = () => navigate(`/seller_dashboard/${ADD_PRODUCT_SMENU}/${product_id}`);

  return (
    <OutsideClickHandler onOutsideClick={() => changeToggle(false)}>
      <div className={style.container}>
        <div
          className={style.btn_container}
          onClick={() => changeToggle(!toggle)}
        >
          <span>Atur</span>
          <i className={`bi bi-chevron-${toggle ? "up" : "down"}`} />
        </div>
        <ul className={menu_style}>
          <li onClick={() => handleEdit()}>Ubah</li>
          <li onClick={() => handleDuplicate()}>Duplikasi Produk</li>
          <li onClick={() => handleDelete()}>Hapus</li>
        </ul>
      </div>
    </OutsideClickHandler>
  );
}

export default ActionMenuView;
