import React, { useMemo, useState } from "react";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import style from "../../../css/sellerProfile/bank.module.css";
import style1 from "../../../css/userProfile/changeEmailHp.module.css";
import {
  addUpdateBank,
  getBankList,
} from "../../../controller/bankAccountController";
import {
  Button,
  CDropdown,
  Input,
} from "../../CustomComponent/StyledComponent";
import { regexName, regexNumber } from "../../../utils/Constants";
import { useSelector } from "react-redux";
import { getLoginOTP, validateOTP } from "../../../controller/authController";
import InputOTP_ChangeEmailHp from "../../CustomComponent/InputOTP_ChangeEmailHp";
import BankAccFormLoading from "../../Loading/BankAccFormLoading";
import Loading from "../../Loading/Loading";

const initState = {
  id: undefined,
  name: "",
  no_rek: "",
  bank: undefined,
  bank_list: [],
  step: 1,

  // validation
  otp: "",
  otp_type: "",

  // err
  err_name: "",
  err_no_rek: "",
  err_bank: "",
  error: "",

  // other
  is_loading: false,
  is_loading_page: false,
};

function BankAccountFormModalView(props) {
  const { data, toggle, popupHandler } = props;
  const title = (data ? "Ubah" : "Tambah") + " Akun Bank";
  const userData = useSelector((s) => s.global.userData);
  const [state, setState] = useState(initState);
  const { step, otp_type, bank, is_loading, is_loading_page } = state;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeNumber = (event) => {
    const { name, value } = event.target;
    if (regexNumber.test(value) || value === "")
      changeState({ [name]: value, [`err_${name}`]: "" });
  };
  const changeName = (event) => {
    const { name, value } = event.target;
    if (regexName.test(value) || value === "") {
      changeState({ [name]: value, [`err_${name}`]: "" });
    }
  };
  const handleSelect = (bank) => changeState({ bank, err_bank: "" });
  const selectValidation = async (otp_type) => {
    changeState({ is_loading: true });
    const user = otp_type === "email" ? userData.email : userData.handphone;
    const res = await getLoginOTP(user, otp_type);
    if (res) changeState({ otp_type, step: 3, is_loading: false, otp: "" });
    else changeState({ is_loading: false, error: "Gagal kirim OTP", otp: "" });
  };
  const changeOTP = (event) => {
    const _val = event.target.value.toUpperCase();
    if (regexName.test(_val) || _val === "") {
      changeState({ otp: _val, error: "" });
    }
  };

  const checkSubmit = () => {
    let res = true;
    const err = { err_name: "", err_no_rek: "", err_bank: "" };
    const { bank, name, no_rek } = state;
    const rek_flag = no_rek === "" || no_rek.length < 6;
    if (name === "" || rek_flag || bank === undefined) res = false;
    if (name === "") err.err_name = "Tidak boleh kosong";
    if (no_rek === "") err.err_no_rek = "Tidak boleh kosong";
    if (no_rek.length < 6) err.err_no_rek = "Nomor Rekening minimal 6 digit";
    if (bank === undefined) err.err_bank = "Silahkan pilih bank";
    changeState(err);
    return res;
  };

  const handleSubmit = async () => {
    if (step === 1 && checkSubmit()) {
      changeState({ step: 2, error: "" });
    }

    if (step === 3) {
      if (state.otp !== "") {
        changeState({ is_loading: true });
        const user = otp_type === "email" ? userData.email : userData.handphone;
        let res = await validateOTP(user, state.otp);
        if (res) {
          res = await addUpdateBank(state);
          if (res) popupHandler(false, "FINISH");
          else {
            const err_text = `Gagal ${
              state.id !== undefined ? "mengubah" : "menambah"
            } akun bank`;
            changeState({ is_loading: false, error: err_text });
          }
        } else {
          changeState({ is_loading: false, error: "OTP tidak valid", otp: "" });
        }
      } else {
        changeState({
          is_loading: false,
          error: "OTP tidak boleh kosong",
          otp: "",
        });
      }
    }
  };

  // useEffect
  useMemo(() => {
    async function initForm() {
      changeState({ is_loading_page: true });
      const bank_list = await getBankList();
      const _data = {};
      if (data) {
        _data.id = data.id;
        _data.name = data.name;
        _data.no_rek = data.no_rek;
        _data.bank = { id: data.bank_id, name: data.bank_name };
      }
      changeState({ ..._data, bank_list, is_loading_page: false });
    }
    initForm();
  }, []);

  // view
  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={() => popupHandler(false, "CLOSE")}
      size="sm"
    >
      <HeaderModal
        title={title}
        modalHandler={() => popupHandler(false, "CLOSE")}
      />
      <div className={style._form}>
        {is_loading_page ? (
          <BankAccFormLoading />
        ) : (
          <>
            {is_loading && <Loading />}
            {!is_loading && step === 1 && (
              <>
                <div className={style.input_container}>
                  <label>Nama Bank</label>
                  <CDropdown
                    color="black"
                    bg_color="white"
                    className={style.bank_dropdown}
                  >
                    <CDropdown.Toggle id="dropdown-autoclose-true">
                      {bank ? bank.name : "Pilih Bank"}
                    </CDropdown.Toggle>
                    <CDropdown.Menu style={{ width: "100%" }}>
                      {state.bank_list.map((item, idx) => {
                        return (
                          <CDropdown.Item
                            key={`prm_bank_${idx}`}
                            onClick={() => handleSelect(item)}
                          >
                            {item.name}
                          </CDropdown.Item>
                        );
                      })}
                    </CDropdown.Menu>
                  </CDropdown>
                  {state.err_bank !== "" && (
                    <label className="error_txt">{state.err_bank}</label>
                  )}
                </div>
                <div className={style.input_container}>
                  <label>Nomor Rekening</label>
                  <Input
                    name="no_rek"
                    value={state.no_rek}
                    onChange={(e) => changeNumber(e)}
                  />
                  {state.err_no_rek !== "" && (
                    <label className="error_txt">{state.err_no_rek}</label>
                  )}
                </div>
                <div className={style.input_container}>
                  <label>Atas nama</label>
                  <Input
                    name="name"
                    value={state.name}
                    onChange={(e) => changeName(e)}
                  />
                  {state.err_name !== "" && (
                    <label className="error_txt">{state.err_name}</label>
                  )}
                </div>
              </>
            )}

            {!is_loading && step === 2 && (
              <div className={style1.otp_opt_container}>
                <label className={style1.otp_opt_title}>
                  Pilih Metode Verifikasi
                </label>
                <label className={style1.otp_opt_subtitle}>
                  Pilih salah satu metode dibawah ini untuk mendapatkan kode
                  verifikasi.
                </label>
                {userData.handphone !== "" && (
                  <div
                    className={style1.otp_card}
                    onClick={() => selectValidation("wa")}
                  >
                    OTP WhatsApp
                  </div>
                )}
                {userData.email !== "" && (
                  <div
                    className={style1.otp_card}
                    onClick={() => selectValidation("email")}
                  >
                    OTP Email
                  </div>
                )}
              </div>
            )}

            {!is_loading && step === 3 && (
              <InputOTP_ChangeEmailHp
                type={otp_type}
                value={state.otp}
                onChange={changeOTP}
                user_data={
                  otp_type === "email" ? userData.email : userData.handphone
                }
                reCall={getLoginOTP}
              />
            )}

            {!is_loading && [1, 3].includes(step) && (
              <div className={style.btn_container}>
                {step === 1 && (
                  <Button onClick={() => popupHandler(false, "CANCEL")}>
                    Batal
                  </Button>
                )}
                <Button active onClick={() => handleSubmit()}>
                  {step === 1 && "Simpan"}
                  {step === 3 && "Submit"}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </CustomModal>
  );
}

export default BankAccountFormModalView;
