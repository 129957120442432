import React from "react";
import Skeleton from "react-loading-skeleton";

function CardProductLoading({ count = 2 }) {
  const Card = () => {
    const array = [];
    for (let i = 0; i < count; i++) {
      array.push(
        <div style={{ display: "flex", gap: "0.5rem" }} key={`loading_${i}`}>
          <Skeleton
            height={100}
            width={100}
            count={1}
            borderRadius={"0.5rem"}
          />
          <div style={{ width: "100%" }}>
            <Skeleton
              height={20}
              count={4}
              borderRadius={"0.5rem"}
              style={{ marginBottom: "0.5rem" }}
            />
          </div>
        </div>
      );
    }
    return array;
  };

  return <Card />;
}

export default CardProductLoading;
