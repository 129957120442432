import React, { useCallback, useEffect, useState } from 'react';
import style from './user_access.module.css'
import { getUserAccList } from '../../../controller/userAccessController';
import Skeleton from 'react-loading-skeleton';
import { Button } from '../../CustomComponent/StyledComponent';
import Switcher from '../../CustomComponent/Switcher'
import UserAccessForm from './UserAccessForm';
import UserAccessCardView from './UserAccessCardView';

const initState = { list: [], selectedData: undefined, is_loading: false, toggle: false }
function UserAccessMain(props) {
    const [state, setState] = useState(initState)

    const changeState = (data) => setState((prev) => ({ ...prev, ...data }))
    const initPage = useCallback(async () => {
        changeState({ is_loading: true })
        const list = await getUserAccList();
        changeState({ is_loading: false, list })
    }, [])

    // modal function
    const handleAdd = () => { changeState({ toggle: true, selectedData: undefined }) }
    const handleEdit = (selectedData) => { changeState({ toggle: true, selectedData }) }
    const handleCancel = () => { changeState({ toggle: false, selectedData: undefined }) }
    const handleSubmit = async () => {
        changeState({ is_loading: true })
        const list = await getUserAccList();
        changeState({ toggle: false, is_loading: false, list, selectedData: undefined })
    }
    // end modal function

    // useEffect
    useEffect(() => {
        initPage()
        return () => setState(initState)
    }, [])

    return (
        <>
            {state.toggle && (
                <UserAccessForm
                    data={state.selectedData}
                    toggle={state.toggle}
                    handleCancel={handleCancel}
                    handleSubmit={handleSubmit}
                />
            )}
            <div className={style.container}>
                <div className={style.header}>
                    <Button active onClick={handleAdd}>
                        + Tambah User
                    </Button>
                </div>
                <div className={style.list_container}>
                    <div className={style.list_inner_container}>
                        {state.is_loading && <LoadingView count={3} />}
                        {!state.is_loading &&
                            state.list.map((data, idx) => {
                                return (
                                    <UserAccessCardView key={`user_acc${idx}`} data={data}
                                        handleEdit={handleEdit}
                                    />
                                )
                            })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserAccessMain;

function LoadingView({ count }) {
    const loading = []
    for (let i = 0; i < count; i++) {
        loading.push(
            <div className={style.card} style={{ borderColor: "lightgrey" }} key={`user_acc${i}`}>
                <Skeleton height={20} width={100} count={1} />
                <div className={style.action_container}>
                    <Skeleton height={20} width={50} count={1} />
                    <Skeleton height={20} width={50} count={1} />
                </div>
            </div>
        )
    }
    return loading;
}