import { APITrans } from "../utils/API";

// call API
export const getWishList = (page = 1, limit = 10) => {
  const _pages = `?page=${page}`;
  const _limits = `&limit=${limit}`;
  return APITrans.get(`wishlist${_pages}${_limits}`)
    .then((res) => res.data)
    .catch((err) => {
      return undefined;
    });
};

export const checkWishList = (product_id, dtl_product_id = null) => {
  const data = { product_id };
  if (dtl_product_id) data['dtl_product_id'] = dtl_product_id;
  return APITrans.post(`wishlist/check`, { ...data })
    .then((res) => res.data)
    .catch((err) => {
      return undefined;
    });
};

export const addWishList = (product_id, dtl_product_id = null) => {
  const data = { product_id };
  if (dtl_product_id) data['dtl_product_id'] = dtl_product_id;
  return APITrans.post(`wishlist`, { ...data })
    .then((res) => res.data)
    .catch((err) => undefined);
};

export const deleteWishList = (product_id, dtl_product_id = null) => {
  const data = { product_id };
  if (dtl_product_id) data['dtl_product_id'] = dtl_product_id;
  return APITrans.post(`wishlist/remove`, { ...data })
    .then((res) => res.data)
    .catch((err) => {
      return undefined;
    });
};
