import dayjs from "dayjs";
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { formatCurrency } from "../../../controller/globalController";
import style from "../../../css/sellerProfile/storePerformance.module.css";

dayjs.locale("id");
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChartView({ dataset, ...props }) {
  const chart_data = dataset.chart_data;
  const labels = chart_data.map((d) =>
    dayjs()
      .set("month", d.month - 1)
      .format("MMMM")
  );
  const dataset1 = chart_data.map((d) => d.total_income);

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (val) => formatCurrency(parseInt(val.raw)),
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `Grafik Pendapatan Bersih Tahun ${props.year}`,
      },
    },
    scales: {
      y: {
        ticks: {
          crossAlign: "far",
          autoSkip: false,
        },
      },
      x: {
        display: true,
        ticks: {
          callback: function (val) {
            if (parseInt(val) === 0) return "";
            return formatCurrency(val);
          },
        },
        tickLength: 5,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: ``,
        data: dataset1,
        borderColor: "rgb(88, 57, 133)",
        backgroundColor: "rgb(88, 57, 133, 0.8)",
      },
    ],
  };

  return (
    <div className={style.chart_container}>
      <Bar options={options} data={data} />
    </div>
  );
}

export default BarChartView;
