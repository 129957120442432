import React, { useCallback, useEffect, useState } from "react";
import { deleteEtalase, deleteSubEtalase, getProductEtalaseList } from "../../../controller/productEtalaseController";
import EtalaseFormModalView from "./EtalaseFormModalView";
import style from "../../../css/sellerProfile/etalase.module.css";
import { Button } from "../../CustomComponent/StyledComponent";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import SubEtalaseFormModalView from "./SubEtalaseFormModalView";
import EtalaseCardView from "./EtalaseCardView";

const subState = { selectedSub: undefined, toggle2: false }
const initState = { list: [], selectedData: undefined, toggle: false, is_loading: false };
function ProductEtalaseMain(props) {
  const [state, setState] = useState({ ...initState, ...subState });
  const { list, is_loading } = state;
  const cg_flag = useSelector((s) => s.global.cgFlag);
  const is_private = useSelector((s) => s.global.userData.seller.is_private) === 1;
  const sub_seller = useSelector((s) => s.global.userData.user_level_id) === 8;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const initPage = async () => { changeState({ is_loading: true }); loadList(); };
  const loadList = useCallback(async () => {
    const list = await getProductEtalaseList();
    changeState({ list, is_loading: false });
  }, []);

  // etalase function
  const _popupHandler = (toggle, process = "ADD", data = undefined) => {
    const flag = process === "FINISH";
    changeState({ toggle, selectedData: data, is_loading: flag });
    if (flag) loadList();
  };
  const handleDelete = (etalase_id) => {
    Swal.fire({
      title: "Hapus Etalase",
      icon: "question",
      text: "Produk dalam etalase tidak akan hilang, hapus etalase ini ?",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Hapus",
      confirmButtonColor: "var(--main_color)",
    }).then(async (res) => {
      if (!res.value) return;
      Swal.fire({
        didOpen: () => Swal.showLoading(),
        allowOutsideClick: false,
      });
      const del_res = await deleteEtalase(etalase_id);
      Swal.close();
      Swal.fire({
        icon: del_res ? "success" : "error",
        text: `${del_res ? "Berhasil " : "Gagal"} mengapus etalase !`,
        confirmButtonColor: "var(--main_color)",
      }).then((res) => {
        if (del_res === undefined) return;
        const etalase = list.findIndex((e) => e.id === etalase_id);
        list.splice(etalase, 1);
        changeState({ list });
      });
    });
  };
  //end etalase function

  // sub etalase function
  const _subPopupHandler = (toggle2, selectedData, process = "ADD", selectedSub = undefined) => {
    const flag = process === "FINISH";
    changeState({ toggle2, selectedSub, selectedData, is_loading: flag });
    if (flag) loadList();
  };
  const handleSubDelete = (etalase_id, sub_id) => {
    Swal.fire({
      title: "Hapus Sub Etalase",
      icon: "question",
      text: "Produk dalam sub etalase tidak akan hilang, hapus sub etalase ini ?",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Hapus",
      confirmButtonColor: "var(--main_color)",
    }).then(async (res) => {
      if (!res.value) return;
      Swal.fire({ didOpen: () => Swal.showLoading(), allowOutsideClick: false });
      const del_res = await deleteSubEtalase(sub_id);
      Swal.close();
      Swal.fire({
        icon: del_res ? "success" : "error",
        text: `${del_res ? "Berhasil " : "Gagal"} mengapus etalase !`,
        confirmButtonColor: "var(--main_color)",
      }).then((res) => {
        if (del_res === undefined) return;
        const tempList = [...list];
        const temp = tempList.find((data) => data.id === etalase_id);
        const subtemp = temp.sub_etalase.findIndex((data) => data.id === sub_id)
        temp.sub_etalase.splice(subtemp, 1);
        changeState({ list: tempList });
      });
    });
  };
  //end sub etalase function


  // useEffect
  useEffect(() => initPage(), []);

  const LoadingView = () => {
    if (is_loading && is_private) return <LoadingSubEtalase count={2} />
    if (is_loading && !is_private) return <LoadingSubEtalase count={3} />
    return <></>
  }

  // view
  return (
    <>
      {state.toggle && (
        <EtalaseFormModalView
          data={state.selectedData}
          toggle={state.toggle}
          popupHandler={_popupHandler}
        />
      )}
      {state.toggle2 && (
        <SubEtalaseFormModalView
          toggle={state.toggle2}
          data={state.selectedSub}
          etalase={state.selectedData}
          popupHandler={_subPopupHandler}
        />
      )}
      <div className={style.container}>
        <div className={style.header}>
          {(!cg_flag && !sub_seller) && (
            <Button active onClick={() => _popupHandler(true)}>
              + Tambah Etalase
            </Button>
          )}
        </div>
        <div className={`${style.list_container}`}>
          <div className={style.list_inner_container}>
            <LoadingView />
            {!is_loading &&
              list.map((data, idx) => {
                return (
                  <EtalaseCardView key={`et_${idx}`}
                    data={data}
                    length={list?.length ?? 0}
                    is_private={is_private}
                    cg_flag={cg_flag}
                    handleDelete={handleDelete}
                    handlePopup={_popupHandler}
                    handleSubDelete={handleSubDelete}
                    handleSubPopup={_subPopupHandler}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductEtalaseMain;

function LoadingEtalase({ containerStyle = {}, count = 1 }) {
  const loading = []
  for (let i = 0; i < count; i++) {
    loading.push(
      <div className={style.card} style={{ borderColor: "lightgrey", ...containerStyle }} key={`etalase${i}`}>
        <Skeleton height={20} width={100} count={1} />
        <div className={style.action_container}>
          <Skeleton height={20} width={20} count={1} />
          <Skeleton height={20} width={20} count={1} />
        </div>
      </div>
    )
  }
  return loading;
};

function LoadingSubEtalase({ count = 1 }) {
  const loading = []
  for (let i = 0; i < count; i++) {
    loading.push(
      <div key={`etalase${i}`} style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <div className={style.card} style={{ borderColor: "lightgrey", marginBlock: '0.5rem', marginBottom: '0' }}>
          <Skeleton height={20} width={100} count={1} />
          <Skeleton height={20} width={50} count={1} />
        </div>
        <LoadingEtalase count={2} containerStyle={{ marginLeft: '5rem' }} />
      </div>
    )
  }
  return loading;
};

