import React from "react";
import { BASE_URL_IMG, DOMAIN, webLink } from "../../utils/Constants";
import p_style from "../../css/productView.module.css";
import style from "../../css/userProfile/wishlist.module.css";
import { formatCurrency } from "../../controller/globalController";
import { Button } from "../CustomComponent/StyledComponent";
import WishlistActionView from "./WishlistActionView";
import Swal from "sweetalert2";
import { addUpdateCart } from "../../controller/transactionController";
import { isDomain } from "../../controller/authController";

function WishlistCardView({ data, lastElRef = null, ...props }) {
  const { product, dtl_product } = data;
  const { photo, varian, discount } = product;
  const sellerId = product.seller_id;
  const preorder = product.is_po === 1;
  const sold_out = product.out_of_stock === 1;

  var product_name = product.name;
  if (dtl_product !== null) {
    if (dtl_product.name1 !== "") product_name += `-${dtl_product.name1}`;
    if (dtl_product.name2 !== "") product_name += `-${dtl_product.name2}`;
  }
  const _fix_price = dtl_product ? dtl_product.fix_price : product.fix_price;
  const _price = dtl_product ? dtl_product.price : product.price;
  var image_name = dtl_product && dtl_product?.image !== "" ? dtl_product.image : photo[0]?.name;
  const img_link = `${BASE_URL_IMG}100/100/${sellerId}/${image_name}`;
  
  // function
  const redirect = () => {
    const domain = isDomain ? webLink : `${product.subdomain}.${DOMAIN}`
    window.location = `https://${domain}/product/${product.id}`;
  };

  const addToCart = async (e) => {
    e.preventDefault();
    const data = {
      product_id: dtl_product?.product_id ?? product.id,
      dtl_product_id: dtl_product?.id ?? "",
      qty: 1,
      note: "",
      seller_id: product.seller_id,
      action: "new",
    };
    Swal.fire({
      didOpen: () => Swal.showLoading(),
      allowOutsideClick: false,
    });
    const res = await addUpdateCart(data);
    Swal.fire({
      icon: res ? "success" : "error",
      text: `${res ? "Berhasil" : "Gagal"} menambahkan barang`,
      confirmButtonColor: "var(--main_color)",
    }).then(() => Swal.close());
  };

  return (
    <div ref={props?.lastElRef} className={style.card_container}>
      <div onClick={() => redirect()}>
        <div className={style.image_container}>
          {preorder && <div className={p_style.preorder_text}>Preorder</div>}
          {sold_out && <div className={p_style.sold_out_text}>Habis</div>}
          <img
            src={img_link}
            style={{ minHeight: "100px", minWidth: "100px" }}
          />
        </div>
        <div
          className={p_style.detail_container}
          style={{ padding: "0 0.5rem" }}
        >
          <div className={p_style.title}>{product.name}</div>
          <div style={{ fontSize: "0.6rem" }}>{`${product.weight} gram`}</div>
          <div style={{ fontSize: "0.6rem" }}>
            <i className="bi bi-geo-alt-fill" />
            {product.zip?.sub_province}
          </div>
          {discount !== 0 && (
            <div className={p_style.discount_container}>
              <div className={p_style.discount}>{`${discount}%`}</div>
              <div className={p_style.price}>{formatCurrency(_price)}</div>
            </div>
          )}
          <div className={p_style.price}>{formatCurrency(_fix_price)}</div>
        </div>
      </div>
      <div className={style.btn_container}>
        <WishlistActionView
          product_id={product.id}
          dtl_product_id={dtl_product?.id ?? null}
        />
        <Button active disabled={sold_out} onClick={(e) => addToCart(e)}>
          + Keranjang
        </Button>
      </div>
    </div>
  );
}

export default WishlistCardView;
