import React from "react";
import { formatCurrency } from "../../controller/globalController";
import { BASE_URL_IMG } from "../../utils/Constants";
import style from "../../css/transactionItem.module.css";

function TransactionItem(props) {
  const { _product, courier, seller, awb = null } = props.data;
  const img_link = `${BASE_URL_IMG}100/100/${seller.id}/${_product.img}`;
  let subTotal = _product.qty * _product.price;
  let note = _product.note;
  let note_flg = note === "";
  note = note_flg ? "Tidak ada catatan" : note;
  return (
    <div className={style.p_container}>
      <img src={img_link} />
      <table className={style.trans_item_detail}>
        <tbody>
          <tr style={{ fontSize: "1.2rem" }}>
            <td colSpan={3}>{seller.name}</td>
          </tr>
          <tr>
            <td colSpan={3}>{`${_product.qty} x ${_product.name}`}</td>
          </tr>
          <tr className={style.smallText}>
            <td>Subtotal</td>
            <td>:</td>
            <td>{formatCurrency(subTotal)}</td>
          </tr>
          <tr className={style.note}>
            <td>Catatan</td>
            <td>:</td>
            <td className={note_flg ? style._note : ""}>{note}</td>
          </tr>
          <tr className={style.smallText}>
            <td>Pengiriman</td>
            <td>:</td>
            <td>{courier}</td>
          </tr>
          {awb !== null && (
            <tr className={style.smallText}>
              <td>Nomor Resi</td>
              <td>:</td>
              <td>{awb}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TransactionItem;
