import React from "react";
import style from "../../../../css/varianForm/inputImageVarian.module.css";

function InputImageVarian({
  changeImage,
  deleteImage,
  input_text = undefined,
  btn_text,
  img_link,
  name,
  ...props
}) {
  const id = `file_${name}`;
  const img_flag = img_link !== null;
  const cursorstyle = { cursor: "pointer" };
  return (
    <div className={style.container}>
      <input
        id={id}
        type="file"
        value=""
        name={name}
        onChange={(e) => changeImage(e)}
        accept="image/png, image/jpeg, image/jpg"
      />
      {!img_flag && (
        <label htmlFor={id} className={style.btn_input} style={cursorstyle}>
          <i className="bi bi-camera-fill" />
        </label>
      )}
      {img_flag && (
        <div className={style.img_btn_container}>
          <label htmlFor={id} className={style.img_btn}>
            <img src={img_link} style={cursorstyle} />
          </label>
          <i className="bi bi-trash-fill" onClick={() => deleteImage(name)} />
        </div>
      )}
    </div>
  );
}

export default InputImageVarian;
