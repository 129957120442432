import React from "react";
import Skeleton from "react-loading-skeleton";
import { InlineWrapper } from "../../controller/loadingController";

function BankAccFormLoading(props) {
  return (
    <div>
      <Skeleton
        height={20}
        count={1}
        style={{ marginBottom: "0.5rem" }}
        wrapper={InlineWrapper}
        borderRadius={"0.5rem"}
      />
      <Skeleton
        height={20}
        count={1}
        style={{ marginBottom: "0.5rem" }}
        wrapper={InlineWrapper}
        borderRadius={"0.5rem"}
      />
      <Skeleton
        height={20}
        count={1}
        style={{ marginBottom: "0.5rem" }}
        wrapper={InlineWrapper}
        borderRadius={"0.5rem"}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "0.5rem",
        }}
      >
        <Skeleton
          height={30}
          count={1}
          style={{ marginBottom: "0.5rem" }}
          borderRadius={"0.5rem"}
        />
        <Skeleton
          height={30}
          count={1}
          style={{ marginBottom: "0.5rem" }}
          borderRadius={"0.5rem"}
        />
      </div>
    </div>
  );
}

export default BankAccFormLoading;
