import React from "react";
import Skeleton from "react-loading-skeleton";

import { InlineWrapper } from "../../controller/loadingController";
import trans_style from "../../css/userProfile/userTransaction.module.css";
import item_style from "../../css/transactionItem.module.css";

function InvoiceCardLoading(props) {
  const InvoiceCard = () => {
    return (
      <div
        className={trans_style.trans_container_item}
        style={{ borderColor: "lightgrey" }}
      >
        <div
          className={trans_style.inner_trans_container1}
          style={{ borderColor: "lightgrey" }}
        >
          <Skeleton height={20} width={200} count={1} />
          <Skeleton height={20} width={100} count={1} />
          <Skeleton height={20} width={100} count={1} />
        </div>
        <div className={trans_style.inner_trans_container2}>
          <div className={`${trans_style.trans_item_container}`}>
            <div className={item_style.p_container}>
              <Skeleton height={100} width={100} count={1} />
              <div style={{ flex: 1 }}>
                <Skeleton
                  height={15}
                  count={5}
                  wrapper={(obj) =>
                    InlineWrapper(obj, { marginBottom: "0.2rem" })
                  }
                />
              </div>
            </div>
          </div>
          <div className={trans_style.trans_detail}>
            <Skeleton
              height={15}
              count={5}
              wrapper={(obj) => InlineWrapper(obj, { marginBottom: "0.2rem" })}
            />
          </div>
        </div>
        <div
          className={trans_style.btn_container}
          style={{ justifyContent: "flex-end" }}
        >
          <Skeleton height={30} width={100} count={1} />
          <Skeleton height={30} width={100} count={1} />
        </div>
      </div>
    );
  };
  return (
    <div>
      <InvoiceCard />
      <InvoiceCard />
    </div>
  );
}

export default InvoiceCardLoading;
