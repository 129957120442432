import { BASE_URL_IMG, BASE_URL_IMG_LOGO } from "../utils/Constants";
import { SET_STORE_DATA, SET_USER_DATA, LOGIN_MODAL } from "./globalAction";

const initialState = {
  userData: undefined,
  storeData: undefined,
  storeLogo: undefined,
  communityFlag: undefined,
  cgFlag: false,
  loginModal: false,
  userLocModal: false,
};

const GlobalReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SET_USER_DATA:
      let cgFlag = payload.userData?.seller?.username !== "";
      return { ...state, userData: payload.userData, cgFlag };
    case SET_STORE_DATA:
      const { storeData } = payload;
      let flag = false;
      let _logo = `${BASE_URL_IMG_LOGO}150/40/0/logoputih_1toko.png`;
      if (storeData) {
        flag = storeData.is_community === 1;
        const flag_logo = storeData.logo !== "";
        _logo = flag_logo
          ? `${BASE_URL_IMG}100/100/${storeData.id}/${storeData.logo}`
          : "";
      }

      return {
        ...state,
        storeData: storeData,
        communityFlag: flag,
        storeLogo: _logo,
      };
    case LOGIN_MODAL:
      return { ...state, loginModal: payload.flag };
    default:
      return state;
  }
};

export default GlobalReducer;
