import React, { useEffect } from "react";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import style from "../../../../css/mulipleSelectDropdown.module.css";

function CustomMutipleSelectDropdown({
  option = [],
  selected = [],
  onChange,
  ...props
}) {
  const [state, setState] = useState({
    toggle: false,
    show: props.text ?? "",
    _selected: selected,
  });
  const { toggle, _selected } = state;
  const { containerClass, containerListClass } = props;
  const c_style = `${style.container} ${containerClass}`;
  const cl_style = `${style.container_list} ${containerListClass}`;
  const toggle_style = toggle ? style.down : style.up;
  const Allflag = _selected.length === option.length || _selected.length === 0;

  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeToggle = (toggle = !state.toggle) => changeState({ toggle });
  const checkSelected = (id) => {
    const temp = _selected.find((d) => d === id);
    return temp || temp === 0 ? false : true;
  };
  const handleSelect = (data) => {
    let temp = [..._selected];
    let show = "";
    if (data.id === -1) {
      temp = [];
      show = "Semua";
    } else {
      if (checkSelected(data.id)) temp.push(data.id);
      else temp = temp.filter((d) => d !== data.id);
      show = temp.map((d) => option[d - 1].name);
    }
    changeState({ _selected: temp, show });
  };

  // useEffect
  useEffect(() => onChange(_selected, state.show), [_selected]);

  return (
    <OutsideClickHandler onOutsideClick={() => changeToggle(false)}>
      <div className={c_style}>
        <div className={style.input_container} onClick={() => changeToggle()}>
          <input
            value={state.show}
            placeholder={"Status Permohonan Dana"}
            readOnly
          />
          <i className={`bi bi-chevron-down`} />
        </div>
        <div className={`${cl_style} ${toggle_style}`}>
          {option.length === 0 && (
            <div className={style.item}>Tidak ada Data</div>
          )}
          {option.length > 0 && (
            <div
              className={style.item}
              onClick={() => handleSelect({ id: -1, name: "" })}
            >
              <i
                className={`bi bi-${Allflag ? "check-square-fill" : "square"} `}
              />
              <span>Semua</span>
            </div>
          )}
          {option.length > 0 &&
            option.map((data, idx) => {
              const key = `${data.id}_${idx}`;
              const sel_flag = _selected.includes(data.id) || Allflag;
              const icon = `bi bi-${
                sel_flag ? "check-square-fill" : "square"
              } `;

              return (
                <div
                  key={key}
                  className={style.item}
                  onClick={() => handleSelect(data)}
                >
                  <i className={icon} />
                  <span>{data.name}</span>
                </div>
              );
            })}
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export default CustomMutipleSelectDropdown;
