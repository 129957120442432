import { APIPayment } from "../utils/API";

export const getSalesReport = async (
  from_date,
  to_date,
  search = "",
  page = 1,
  limit = 5
) => {
  const _from_date = `?from_date=${from_date}`;
  const _to_date = `&to_date=${to_date}`;
  const _pages = `&page=${page}`;
  const _limits = `&limit=${limit}`;
  const _search = `&search=${search}`;
  return APIPayment.get(
    `report/sales${_from_date}${_to_date}${_pages}${_limits}${_search}`
  )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const getSalesRptExcelData = async (from_date, to_date, search = "") => {
  const _from_date = `?from_date=${from_date}`;
  const _to_date = `&to_date=${to_date}`;
  const _search = `&search=${search}`;
  return APIPayment.get(`report/export_sales${_from_date}${_to_date}${_search}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getPayoutRptExcelData = (payload) => {
  const {
    from_date,
    to_date,
    search = "",
    filter = [],
    type = "Payout",
  } = payload;
  const _from_date = `?from_date=${from_date}`;
  const _to_date = `&to_date=${to_date}`;
  const _search = `&search=${search}`;
  const _filter = `&filter=[${filter}]`;
  const _type = `&type=${type?.name}`;
  return APIPayment.get(
    `report/export_payout${_from_date}${_to_date}${_search}${_filter}${_type}`
  )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};
