import React, { useCallback, useEffect, useState } from "react";
import { formatCurrency } from "../../../controller/globalController";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
// import DataTable, { defaultThemes } from "react-data-table-component";
import style from "../../../css/sellerProfile/promo.module.css";
import { regexNumber } from "../../../utils/Constants";
import { Button, Input } from "../../CustomComponent/StyledComponent";

const initState = {
  list: [],
  comm_nominal: 0,
  paid_comm: 0,
  is_loading: false,
};
function ReferralTransHistoryModalView(props) {
  const { selected_promo, toggle, popupHandler } = props;
  const [state, setState] = useState(initState);
  const title = `Riwayat Transaksi Referral`;

  let total_com = parseInt(selected_promo.total_commission);
  let total_com_paid = parseInt(state.paid_comm);
  let total_com_not_paid = total_com - total_com_paid;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeNumber = (data) => {
    if (regexNumber.test(data) || data === "") {
      changeState({ comm_nominal: data });
    }
  };

  const _handlePopup = () => popupHandler(undefined, false);
  const loadList = useCallback(async () => {
    const list = [];
    const paid_comm = 0;
    changeState({ list, paid_comm, is_loading: false });
  }, []);

  const initPage = () => {
    changeState({ is_loading: true });
    loadList();
  };

  const handleSubmmit = async (event) => {
    event.preventDefault();
    changeState({ is_loading: true });
  };

  // useEffect
  useEffect(() => initPage(), []);

  // table props
  const columns = [];

  // view
  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={() => _handlePopup()}
      size="lg"
    >
      <HeaderModal title={title} modalHandler={() => _handlePopup()} />
      <table className={style.com_tbl_info}>
        <tbody>
          <tr>
            <th>Kode Referral</th>
            <th>Total Nominal Komisi</th>
            <th>Komisi Dibayar</th>
            <th>Komisi Belum Dibayar</th>
          </tr>
          <tr>
            <td>{selected_promo.promo_code}</td>
            <td>{formatCurrency(total_com)}</td>
            <td>{formatCurrency(total_com_paid)}</td>
            <td>{formatCurrency(total_com_not_paid)}</td>
          </tr>
        </tbody>
      </table>
      {/* input */}
      <form className={style.input_container} style={{ marginTop: "0.5rem" }}>
        <label>Kode Referral</label>
        <div className="group_input">
          <Input
            value={state.comm_nominal}
            onChange={(e) => changeNumber(e.target.value)}
            disabled={state.is_loading}
          />
          <Button
            active
            disabled={state.is_loading}
            onClick={(e) => handleSubmmit(e)}
          >
            Submit
          </Button>
        </div>
        {state.err_pcode !== "" && (
          <label className="error_txt">{state.err_pcode}</label>
        )}
      </form>
      {/* table */}
    </CustomModal>
  );
}

export default ReferralTransHistoryModalView;
