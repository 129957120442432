import React, { useState } from "react";
import {
  formatCurrency,
  formatDate,
} from "../../../controller/globalController";
import { updateTransStatus } from "../../../controller/sellerTransactionController";
import { getTransItemList } from "../../../controller/transactionController";
import style from "../../../css/userProfile/userTransaction.module.css";
import { PRINT_INV } from "../../../utils/Constants";
import { Button } from "../../CustomComponent/StyledComponent";
import TrackingModalView from "../../Transaction/TrackingModalView";
import TransactionItem from "../../Transaction/TransactionItem";

const dateFormat = "DD MMMM YYYY HH : mm";
function TransactionUserView(props) {
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const {
    invoice_id,
    cart,
    status,
    status_name,
    customer_details,
    transaction_details,
    transaction_date,
  } = props.data;
  const { address, city, postal_code, phone } = customer_details.shipping_address;
  const _address = `${address}, ${city}, ${postal_code}`.toLowerCase();
  const first_name = customer_details.address.name;
  const item_list = getTransItemList([cart]);

  //  function
  const handlePrint = () => {
    const origin = window.location.origin;
    let type = PRINT_INV;
    window.open(`${origin}/print/${type}/${invoice_id}`);
  };
  const handleRequestCancelOrder = async () => {
    const res = await updateTransStatus(invoice_id, "cancel");
    if (res) window.location.reload();
  };
  const handleFinishTrans = async () => {
    const res = await updateTransStatus(invoice_id, "next");
    if (res) window.location.reload();
  };

  return (
    <>
      {toggle1 && (
        <TrackingModalView
          toggle={toggle1}
          courier={{ awb: props.data.awb, code: props.data.courier_code }}
          popupHandler={() => setToggle1(false)}
        />
      )}
      <div className={style.trans_container_item}>
        <div className={style.inner_trans_container1}>
          <div className={style.inv_no}>{`No. Invoice : ${invoice_id}`}</div>
          <div id={style.stat}>{`Status Pesanan : ${status_name}`}</div>
          <div id={style._date}>{formatDate(transaction_date, dateFormat)}</div>
        </div>
        <div className={style.inner_trans_container2}>
          <div className={`${style.trans_item_container}`}>
            <div className={`${style.trans_item} ${toggle ? style.show : ""}`}>
              {item_list.map((data, idx) => {
                const key = `${data._product.dtl_product_id}_${idx}`;
                if ((!toggle && idx === 0) || toggle) {
                  data.awb = props.data.awb;
                  return <TransactionItem key={key} data={data} />;
                }
              })}
            </div>
            {item_list.length > 1 && (
              <div
                className={style.show_more}
                onClick={() => setToggle(!toggle)}
              >
                {!toggle
                  ? `+${item_list.length - 1} Produk lainnya`
                  : "Lihat lebih sedikit"}
              </div>
            )}
          </div>

          <div className={style.trans_detail}>
            <table className={style.detail}>
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{first_name}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{_address}</td>
                </tr>
                <tr>
                  <td>No.Hp</td>
                  <td>:</td>
                  <td>{phone}</td>
                </tr>
                <tr>
                  <td className={style.detail_title}>Total Belanja</td>
                  <td>:</td>
                  <td>{formatCurrency(transaction_details.gross_amount)}</td>
                </tr>
                <tr>
                  <td>Metode Pembayaran</td>
                  <td>:</td>
                  <td>{transaction_details?.payment_type?.name ?? ""}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={style.btn_container}
          style={{ justifyContent: "flex-end" }}
        >
          <Button active onClick={() => handlePrint()} >
            Cetak Invoice
          </Button>
          {status === "3" && (
            <Button active onClick={() => setToggle1(!toggle1)}>
              Lacak
            </Button>
          )}
          {["4", "6"].includes(status) && (
            <Button bg_color='green' active onClick={() => handleFinishTrans()}>
              Selesai
            </Button>
          )}
          {status === "1" && (
            <Button bg_color='red' active onClick={() => handleRequestCancelOrder()}>
              Mohon Pembatalan
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default TransactionUserView;
