import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emailRegex,
  phoneRegex,
  regexName,
  regexNumber,
} from "../../../utils/Constants";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import style from "../../../css/userProfile/changeEmailHp.module.css";
import { Button, Input } from "../../CustomComponent/StyledComponent";
import {
  getLoginOTP,
  getRegisterOTP,
  validateOTP,
} from "../../../controller/authController";
import InputOTP_ChangeEmailHp from "../../CustomComponent/InputOTP_ChangeEmailHp";
import { globalStorage } from "../../../utils/Storage";
import { changeUser } from "../../../redux/globalAction";
import {
  updateEmail,
  updateHp,
} from "../../../controller/userProfileController";
import Loading from "../../Loading/Loading";

const PasswordInput = (payload) => {
  const { label, error, value, name, showPass } = payload;
  const { setShowPass, onChange } = payload;
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="input-group">
        <Input
          name={name}
          type={showPass ? "text" : "password"}
          className={`form-control`}
          value={value}
          onChange={(e) => onChange(e.target)}
        />
        <div className="input-group-append">
          <span className={`input-group-text`} id="basic-addon">
            <i
              className={showPass ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}
              onClick={() => setShowPass(!showPass)}
            />
          </span>
        </div>
      </div>
      {error !== "" && <label>{error}</label>}
    </div>
  );
};

const initState = {
  otp: "",
  old_data: "",
  new_data: "",
  password: "",
  conf_password: "",
  step: 1,
  otp_type: "wa",
  is_loading: false,
  error: "",
};

function ChangeEmailHpModalView(props) {
  const dispatch = useDispatch();
  const { type, toggle, popupHandler } = props;
  const userData = useSelector((s) => s.global.userData);
  const [state, setState] = useState(initState);
  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const { step, error, otp_type, old_data, is_loading } = state;

  var title = userData[type] === "" ? "Tambah" : "Ubah";
  title = `${title} ${type === "email" ? "Email" : "Nomor Telepon"}`;

  //   function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changePass = (event) => changeState({ [event.name]: event.value });
  const changeOTP = (event) => {
    const _val = event.target.value.toUpperCase();
    if (regexName.test(_val) || _val === "") {
      changeState({ otp: _val, error: "" });
    }
  };
  const changeNewData = (event) => {
    const _val = event.target.value;
    const hp_flag = regexNumber.test(_val) && type === "handphone";
    const email_flag = type === "email";
    if (hp_flag || email_flag || _val === "") {
      changeState({ new_data: _val, error: "" });
    }
  };

  const selectValidation = async (otp_type) => {
    changeState({ is_loading: true });
    const user = otp_type === "email" ? userData.email : userData.handphone;
    const res = await getLoginOTP(user, otp_type);
    if (res) changeState({ otp_type, step: 2, is_loading: false, otp: "" });
    else changeState({ is_loading: false, error: "Gagal kirim OTP", otp: "" });
  };

  const validEmailHp = () => {
    let res = true;
    let error = "";
    const flag_same = old_data === state.new_data.replace(/^0+/, "+62");
    const flag_email = !emailRegex.test(state.new_data) && type === "email";
    const flag_hp = !phoneRegex.test(state.new_data) && type === "handphone";
    const flag_pass_not_same = state.password !== state.conf_password;
    const flag_pass_empty = state.password === "" || state.conf_password === "";
    const flag_pass =
      (flag_pass_not_same || flag_pass_empty) &&
      type === "email" &&
      old_data === "";
    if (flag_email || flag_same || flag_hp || flag_pass) res = false;
    if (flag_email) error = "Email tidak valid";
    if (flag_hp) error = "Nomor Hp tidak valid";
    if (flag_pass_not_same) {
      error = "Kata sandi dan konfirmasi kata sandi tidak sama";
    }
    if (flag_pass_empty) {
      error = "Kata sandi atau konfirmasi kata sandi masih kosong";
    }
    if (flag_same)
      error = `${
        type === "email" ? "Email" : "Nomor Hp"
      } sama dengan yang lama`;
    changeState({ error });
    return res;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (step === 2) {
      if (state.otp !== "") {
        changeState({ is_loading: true, error: "", otp: "" });
        const res = await validateOTP(old_data, state.otp);
        if (res)
          changeState({ is_loading: false, step: 3, error: "", otp: "" });
        else changeState({ is_loading: false, error: "OTP tidak valid" });
      } else {
        changeState({ is_loading: false, error: "Silahkan masukan OTP" });
      }
    }

    if (step === 3 && validEmailHp()) {
      changeState({ is_loading: true, error: "" });
      let user = state.new_data;
      if (type === "handphone") user = user.replace(/^0+/, "+62");
      let res = await getRegisterOTP(user, otp_type);
      const data = { is_loading: false, otp: "", error: "" };
      if (res) data.step = 4;
      else data.error = "Gagal mengirim OTP";
      changeState(data);
    }

    if (step === 4) {
      if (state.otp !== "") {
        changeState({ is_loading: true, error: "", otp: "" });
        let user = state.new_data;
        if (type === "handphone") user = user.replace(/^0+/, "+62");
        let res = await validateOTP(user, state.otp);
        if (res) {
          if (type === "email") {
            const { password, conf_password } = state;
            res = await updateEmail({ email: user, password, conf_password });
          }
          if (type === "handphone") res = await updateHp(user);

          if (res) {
            userData[type] = user;
            if (type === "handphone") userData.is_verified_handphone = 1;
            if (type === "email") userData.is_verified_email = 1;
            globalStorage.setItem("UD", userData);
            dispatch(changeUser(userData));
            popupHandler(false, type, user);
          } else {
            let err_text = old_data === "" ? "menambah" : "mengubah";
            let err_text2 = type === "email" ? "Email" : "Nomor Telepon";
            err_text = `Gagal ${err_text} ${err_text2}`;
            changeState({ is_loading: false, error: err_text, otp: "" });
          }
        } else {
          changeState({ is_loading: false, error: "OTP tidak valid", otp: "" });
        }
      } else {
        changeState({
          is_loading: false,
          error: "OTP tidak boleh kosong",
          otp: "",
        });
      }
    }
  };

  //   useEffect
  useMemo(() => {
    let step = userData[type] === "" ? 3 : 1;
    let old_data = type === "email" ? userData.email : userData.handphone;
    let otp_type = userData[type] === "" && type === "email" ? "email" : "wa";
    changeState({ old_data, otp_type, step });
  }, []);

  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={() => popupHandler(false, "")}
      size="sm"
    >
      <HeaderModal title={title} modalHandler={() => popupHandler(false, "")} />
      <form className={style.form_container}>
        {step === 1 && (
          <div className={style.otp_opt_container}>
            <label className={style.otp_opt_title}>
              Pilih Metode Verifikasi
            </label>
            <label className={style.otp_opt_subtitle}>
              Pilih salah satu metode dibawah ini untuk mendapatkan kode
              verifikasi.
            </label>
            {userData.handphone !== "" && (
              <div
                className={style.otp_card}
                onClick={() => selectValidation("wa")}
              >
                OTP WhatsApp
              </div>
            )}
            {userData.email !== "" && (
              <div
                className={style.otp_card}
                onClick={() => selectValidation("email")}
              >
                OTP Email
              </div>
            )}
          </div>
        )}
        {step === 2 && (
          <InputOTP_ChangeEmailHp
            type={otp_type}
            value={state.otp}
            onChange={changeOTP}
            user_data={old_data}
            reCall={getLoginOTP}
          />
        )}
        {step === 3 && (
          <>
            <label>{type === "email" ? "Email" : "Nomor Telepon"}</label>
            <Input value={state.new_data} onChange={(e) => changeNewData(e)} />
            {old_data === "" && type === "email" && (
              <>
                <PasswordInput
                  label="Kata Sandi"
                  error=""
                  value={state.password}
                  name="password"
                  showPass={showPass}
                  setShowPass={setShowPass}
                  onChange={changePass}
                />
                <PasswordInput
                  label="Konfirmasi Kata Sandi"
                  error=""
                  value={state.conf_password}
                  name="conf_password"
                  showPass={showPass1}
                  setShowPass={setShowPass1}
                  onChange={changePass}
                />
              </>
            )}
          </>
        )}
        {step === 4 && (
          <InputOTP_ChangeEmailHp
            type={otp_type}
            value={state.otp}
            onChange={changeOTP}
            user_data={state.new_data}
            reCall={getRegisterOTP}
          />
        )}
        <label className={style.error_text}>{error}</label>
        {step !== 1 && (
          <Button
            active
            type="submit"
            onClick={(e) => handleSubmit(e)}
            disabled={is_loading}
          >
            {is_loading ? (
              <Loading />
            ) : (
              <>
                {[2, 3].includes(step) && "Selanjutnya"}
                {step === 4 && "Submit"}
              </>
            )}
          </Button>
        )}
      </form>
    </CustomModal>
  );
}

export default ChangeEmailHpModalView;
