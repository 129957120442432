import React from "react";
import style from "../../css/sellerProfile/payoutTransaction.module.css";
import loading_style from "../../css/loading.module.css";
import Skeleton from "react-loading-skeleton";

import InvoiceCardLoading from "./InvoiceCardLoading";

function SalesReportLoading(props) {
  return (
    <div className={style.container}>
      <div className={`${style.out_date_container} ${style.right_position}`}>
        <Skeleton width={300} count={1} />
      </div>
      <div className={`${style.btn_container} ${style.right_position}`}>
        <Skeleton width={120} count={1} />
        <Skeleton width={120} count={1} />
      </div>
      <div className={loading_style.flex_container}>
        <Skeleton count={1} />
        <Skeleton count={1} />
      </div>
      <hr />
      <InvoiceCardLoading />
    </div>
  );
}

export default SalesReportLoading;
