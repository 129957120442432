import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getLoginOTP, validateOTP } from "../../../controller/authController";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import { Button } from "../../CustomComponent/StyledComponent";
import style from "../../../css/userProfile/changeEmailHp.module.css";
import { regexName } from "../../../utils/Constants";
import { deleteBankAccount } from "../../../controller/bankAccountController";
import InputOTP_ChangeEmailHp from "../../CustomComponent/InputOTP_ChangeEmailHp";
import Loading from "../../Loading/Loading";

const initState = {
  otp: "",
  otp_type: "",
  otp_user: "",
  error: "",
  step: 1,
  is_loading: false,
};

function DeleteValidationModalView(props) {
  const { acc_id, toggle, popupHandler } = props;
  const userData = useSelector((s) => s.global.userData);
  const [state, setState] = useState(initState);
  const { is_loading, step } = state;

  //   function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeOTP = (event) => {
    const _val = event.target.value.toUpperCase();
    if (regexName.test(_val) || _val === "") {
      changeState({ otp: _val, error: "" });
    }
  };
  const selectValidation = async (otp_type) => {
    changeState({ is_loading: true });
    let otp_user = otp_type === "email" ? userData.email : userData.handphone;
    const res = await getLoginOTP(otp_user, otp_type);
    if (res) {
      changeState({
        otp_type,
        step: 2,
        otp_user,
        error: "",
        is_loading: false,
      });
    } else changeState({ is_loading: false });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let err_text = "";
    if (step === 2) {
      if (state.otp !== "") {
        let res = undefined;
        res = await validateOTP(state.otp_user, state.otp);
        if (res) {
          res = await deleteBankAccount(acc_id);
          err_text = `Gagal menghapus Akun Bank`;
          if (res) popupHandler("FINISH");
          else changeState({ is_loading: false, error: err_text, otp: "" });
        } else {
          changeState({ is_loading: false, error: "OTP tidak valid", otp: "" });
        }
      } else {
        err_text = "OTP tidak boleh kosong";
        changeState({ is_loading: false, error: err_text, otp: "" });
      }
    }
  };

  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={popupHandler}
      size="sm"
    >
      <HeaderModal
        title={"Validasi Hapus Akun Bank"}
        modalHandler={popupHandler}
      />
      {is_loading && <Loading />}
      {!is_loading && (
        <form className={style._form}>
          {step === 1 && (
            <div className={style.otp_opt_container}>
              <label className={style.otp_opt_title}>
                Pilih Metode Verifikasi
              </label>
              <label className={style.otp_opt_subtitle}>
                Pilih salah satu metode dibawah ini untuk mendapatkan kode
                verifikasi.
              </label>
              {userData.handphone !== "" && (
                <div
                  className={style.otp_card}
                  onClick={() => selectValidation("wa")}
                >
                  OTP WhatsApp
                </div>
              )}
              {userData.email !== "" && (
                <div
                  className={style.otp_card}
                  onClick={() => selectValidation("email")}
                >
                  OTP Email
                </div>
              )}
            </div>
          )}
          {step === 2 && (
            <InputOTP_ChangeEmailHp
              type={state.otp_type}
              value={state.otp}
              onChange={changeOTP}
              user_data={state.otp_user}
              reCall={getLoginOTP}
            />
          )}
          {step === 2 && (
            <Button
              active
              type="submit"
              onClick={(e) => handleSubmit(e)}
              disabled={is_loading}
            >
              {"Submit"}
            </Button>
          )}
        </form>
      )}
    </CustomModal>
  );
}

export default DeleteValidationModalView;
