import React from "react";
import style from "../../css/sellerProfile/colorInput.module.css";

function ColorInputView({ color, color_list, changeColor, is_edit = true }) {
  return (
    <div className={style.color_container}>
      <label className={style.title}>Warna Toko</label>
      <div className={style.color_input_container}>
        <div className={style.color_block_container}>
          {color_list.map((data, idx) => {
            const flag = data.id === color?.id;
            const block_class = `${style.color_block} ${
              style[flag ? "selected" : ""]
            }`;
            return (
              <div
                key={`color_${idx}`}
                className={block_class}
                onClick={() => (is_edit ? changeColor({ color: data }) : "")}
                style={{
                  backgroundColor: data.code,
                  cursor: is_edit ? "pointer" : "initial",
                }}
              />
            );
          })}
        </div>
        <div className={style.color_block_selected}>
          <label>{color?.name}</label>
          <div
            className={style.color_block}
            style={{ backgroundColor: color?.code }}
          />
        </div>
      </div>
    </div>
  );
}

export default ColorInputView;
