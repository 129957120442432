import React from "react";
import Skeleton from "react-loading-skeleton";

import { InlineWrapper } from "../../controller/loadingController";

function LoadMoreStore_ProductLoading({ count = 2 }) {
  const repeat = () => {
    const array = [];
    for (let i = 0; i < count; i++) {
      array.push(
        <Skeleton
          key={`loading_${i}`}
          width={120}
          height={170}
          wrapper={InlineWrapper}
          borderRadius={"0.5rem"}
        />
      );
    }
    return array;
  };

  return <>{repeat()}</>;
}

export default LoadMoreStore_ProductLoading;
