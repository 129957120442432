import React from "react";
import { useState } from "react";
import { createPayoutRequest } from "../../../controller/payoutTransactionController";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import { Button, CDropdown } from "../../CustomComponent/StyledComponent";
import style from "../../../css/sellerProfile/bank.module.css";
import style_payout from "../../../css/sellerProfile/payoutTransaction.module.css";

function SubmitPayoutModal({ toggle, popupHandler, bankList, selectedData }) {
  const title = "Kirim Permintaan";
  const [state, setState] = useState({ bank: undefined, is_loading: false });
  const { bank, is_loading } = state;
  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const handleSelect = (bank) => changeState({ bank });
  const handleSubmit = async () => {
    changeState({ is_loading: true });
    const { bank } = state;
    const res = await createPayoutRequest({ data: selectedData, bank });
    if (res) popupHandler(false, "FINISH");
    else changeState({ is_loading: false });
  };

  return (
    <CustomModal
      size="sm"
      outsideClick
      toggle={toggle}
      modalHandler={() => popupHandler()}
    >
      <HeaderModal title={title} modalHandler={() => popupHandler()} />
      <div className={style.input_container} style={{ marginBottom: "0.5rem" }}>
        <label>Nomor Rekening</label>
        <CDropdown
          color="black"
          bg_color="white"
          className={style.bank_dropdown}
        >
          <CDropdown.Toggle
            id="dropdown-autoclose-true"
            style={{ textTransform: "capitalize" }}
          >
            {bank
              ? `${bank.bank_name.toLowerCase()} - ${bank.no_rek}`
              : "Pilih Rekening"}
          </CDropdown.Toggle>
          <CDropdown.Menu style={{ width: "100%" }}>
            {bankList.map((item, idx) => {
              return (
                <CDropdown.Item
                  key={`prm_bank_${idx}`}
                  onClick={() => handleSelect(item)}
                  style={{ textTransform: "capitalize" }}
                >
                  {`${item.bank_name.toLowerCase()} - ${item.no_rek}`}
                </CDropdown.Item>
              );
            })}
          </CDropdown.Menu>
        </CDropdown>
      </div>
      {bank && (
        <div className={style_payout.dtl_account}>
          <div>{`Atas Nama : ${bank.name}`}</div>
          <div>{`${bank.bank_name.toLowerCase()} - ${bank.no_rek}`}</div>
        </div>
      )}
      <Button active onClick={() => handleSubmit()} disabled={state.is_loading}>
        {is_loading ? "Loading..." : "Kirim"}
      </Button>
    </CustomModal>
  );
}

export default SubmitPayoutModal;
