import React, { useState } from 'react';
import style from './user_access.module.css'
import Switcher from '../../CustomComponent/Switcher';
import { Button } from '../../CustomComponent/StyledComponent';
import useDebounce from '../../../utils/useDebounce';
import { addUpdateUserAcc } from '../../../controller/userAccessController';

function UserAccessCardView({ data, handleEdit }) {
    const { id, name, email, handphone, is_active } = data.user;
    const [status, setStatus] = useState(is_active === 1);

    const changeStatus = (event) => setStatus(event.target.checked);
    const _handleEdit = () => { handleEdit(data) }

    useDebounce(async () => addUpdateUserAcc({ id, is_active: status ? 1 : 0 }), 500, [status])

    return (
        <div className={style.card}>
            <div>
                <div className={style.fieldGroup}>
                    <label className={style.fieldName}>Nama</label>
                    <label>{name}</label>
                </div>
                <div className={style.fieldGroup}>
                    <label className={style.fieldName}>Email</label>
                    <label>{email}</label>
                </div>
                <div className={style.fieldGroup}>
                    <label className={style.fieldName}>No. Telp</label>
                    <label>{handphone}</label>
                </div>
            </div>
            <div className={style.action_container}>
                <Switcher id={`user_acc${id}`} toggle={status} onChangeToggle={changeStatus} />
                <Button active onClick={_handleEdit}>
                    <i className='bi bi-pencil-square' />
                </Button>
            </div>
        </div>
    );
}

export default UserAccessCardView;