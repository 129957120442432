import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import modal_style from "../../css/customModal.module.css";

export const HeaderModal = (props) => {
  const { title = "", modalHandler = null } = props;
  return (
    <div className={modal_style.modal_header}>
      <div className={modal_style.title}>{title}</div>
      <div
        className={`${modal_style.close_btn}`}
        data-toggle="tooltip"
        data-placement="right"
        title="Tutup"
        onClick={() => modalHandler()}
      >
        <i className="bi bi-x-lg"></i>
      </div>
    </div>
  );
};

function CustomModal({
  toggle = false,
  size = "md",
  position = "centered",
  modalHandler = () => { },
  outsideClick,
  hidden = false,
  modalProps = {},
  ...props
}) {
  const _visible = hidden ? "hidden" : "visible";
  const modal_props = {
    contentClassName: "custom-modal-content",
    show: toggle,
    onHide: modalHandler,
    centered: position === "centered",
    size: size,
    ...modalProps
  };

  if (outsideClick) modal_props.backdrop = "static";

  return (
    <Modal {...modal_props} style={{ visibility: _visible }}>
      <ModalBody>{props.children}</ModalBody>
    </Modal >
  );
}

export default CustomModal;
