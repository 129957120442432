import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import style from "../../../css/sellerProfile/storePerformance.module.css";
import id from "date-fns/locale/id";
import {
  getProductRank,
  getStorePerformance,
} from "../../../controller/sellerTransactionController";
import DatePicker from "react-datepicker";
import { InputDate } from "../../CustomComponent/StyledComponent";
import DtlCardView from "./DtlCardView";
import { formatCurrency } from "../../../controller/globalController";
import { globalStorage } from "../../../utils/Storage";
import { BASE_URL_IMG } from "../../../utils/Constants";
import RankCardView from "./RankCardView";
import BarChartView from "./BarChartView";
import StorePerformanceLoading from "../../Loading/StorePerformanceLoading";

const initState = {
  date: dayjs(dayjs().format("YYYY-MM-DD")).toDate(),
  num_new_invoice: 0,
  num_sold_product: 0,
  product_rank: [],
  store_net_income_today: 0,
  store_net_income_thirty_day: 0,
  store_net_income_chart_data: undefined,
  is_loading: false,
};

dayjs.locale("id");
function StorePerformanceMain(props) {
  const storeId = globalStorage.getItem("UD").seller.id;
  const abortCtrl = new AbortController();
  const [state, setState] = useState(initState);
  const { is_loading } = state;
  const date = dayjs(state.date).format("YYYY-MM-DD");
  const income_today = formatCurrency(state.store_net_income_today);
  const income_30d = formatCurrency(state.store_net_income_thirty_day);

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeDate = (date) => changeState({ date });
  const initPage = async () => {
    changeState({ is_loading: true });
    const temp = await getStorePerformance(date, abortCtrl);
    const data = { is_loading: false };
    if (state.product_rank.length === 0) {
      const product_rank = await getProductRank(abortCtrl);
      data.product_rank = product_rank;
    }
    if (temp) {
      data.num_new_invoice = temp.num_new_invoice;
      data.num_sold_product = temp.num_sold_product;
      data.num_sold_product = temp.num_sold_product;
      data.store_net_income_today = temp.store_net_income_today;
      data.store_net_income_thirty_day = temp.store_net_income_thirty_day;
      data.store_net_income_chart_data = temp.store_net_income_chart_data;
    }
    changeState(data);
  };
  const abortPage = () => {
    abortCtrl.abort();
    setState(initState);
  };

  // useEffect
  useEffect(() => {
    initPage();
    return () => abortPage();
  }, [date]);

  return (
    <div className={`${style.container} scroll_container`}>
      <div className={style.header}>
        <span>{`Hari ini : ${dayjs().format("DD MMMM YYYY", id)}`}</span>
        <div className={style.date_container}>
          <DatePicker
            locale={id}
            dateFormat={"dd - MM - yyyy"}
            selected={state.date}
            onChange={(date) => changeDate(date)}
            customInput={<InputDate />}
          />
        </div>
      </div>
      <div className={style.body}>
        <div className={style.dtl_container}>
          <div className={style.dtl_part}>
            {is_loading ? (
              <StorePerformanceLoading type={1} />
            ) : (
              <>
                <DtlCardView
                  title="Pesanan Baru"
                  content={state.num_new_invoice}
                />
                <DtlCardView
                  title="Produk Terjual"
                  content={state.num_sold_product}
                />
              </>
            )}
          </div>
          <div className={style.dtl_part}>
            {is_loading ? (
              <StorePerformanceLoading type={1} />
            ) : (
              <>
                <DtlCardView
                  title="Pendapatan Bersih Hari ini"
                  content={income_today}
                />
                <DtlCardView
                  title="Pendapatan Bersih dalam 30 hari"
                  content={income_30d}
                />
              </>
            )}
          </div>
          {!is_loading && state.store_net_income_chart_data ? (
            <BarChartView
              dataset={state.store_net_income_chart_data}
              year={state.date.getFullYear()}
            />
          ) : (
            <StorePerformanceLoading type={2} />
          )}
        </div>
        <div className={`${style.rank_part}`}>
          <div className={style.title}>Peringkat Produk</div>
          <div className={style.rank_list_container}>
            {is_loading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <StorePerformanceLoading type={1} />
              </div>
            ) : (
              state.product_rank.map((data, idx) => {
                const key = `${data.name}_${idx}`;
                const img_link = `${BASE_URL_IMG}70/70/${storeId}/${data.photo}`;
                return (
                  <RankCardView
                    key={key}
                    rank={idx + 1}
                    image_lnk={img_link}
                    name={data.name}
                    qty={data.qty}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StorePerformanceMain;
