import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "../../css/location/location.module.css";
import { Button, Input } from "../CustomComponent/StyledComponent";
import LocationModalView from "./LocationModalView";
import {
  deleteUserLoc,
  getUserLocation,
  setLU_AsPrimary,
} from "../../controller/userLocationController";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import {
  deleteSellerLoc,
  getSellerLocation,
  setLS_AsPrimary,
} from "../../controller/sellerLocationController";
import LocationCardLoading from "../Loading/LocationCardLoading";

const initState = {
  loc_list: [],
  search_list: [],
  process: "ADD",
  search: "",
  selectedLoc: undefined,
  is_loading: true,
  toggle: false,
};

function LocationMain(props) {
  const { seller } = props;
  const [state, setState] = useState(initState);
  // const store = useSelector((s) => s.global.storeData);
  const { selectedLoc, search_list, is_loading, toggle, process } = state;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const updateList = useCallback(async () => {
    let loc_list = await (seller ? getSellerLocation() : getUserLocation());
    let search_list = fillterList(loc_list);
    changeState({ loc_list, search_list, is_loading: false });
  }, []);

  const initPage = () => {
    changeState({ is_loading: true });
    updateList();
  };

  const _popupHandler = (toggle, process = "ADD", data = undefined) => {
    const flag = process === "FINISH";
    changeState({ toggle, selectedLoc: data, process, is_loading: flag });
    if (flag) updateList();
  };

  const alert = async (icon, text) => {
    return Swal.fire({
      icon,
      text,
      allowOutsideClick: false,
      cancelButtonText: "Batal",
      showCancelButton: true,
      confirmButtonText: "Ok",
      confirmButtonColor:"var(--main_color)",
      cancelButtonColor: "#d33",
    }).then((res) => res);
  };

  const handleSetPrimary = async (id) => {
    const res = await (seller ? setLS_AsPrimary(id) : setLU_AsPrimary(id));
    if (res) updateList();
  };

  const handleDeleteLoc = async (id) => {
    let conf = await alert("warning", "Hapus alamat ini ?");
    if (conf.value) {
      const res = await (seller ? deleteSellerLoc(id) : deleteUserLoc(id));
      if (res) updateList();
    }
  };

  const handleUpdateLoc = (data) => _popupHandler(true, "UPDATE", data);
  const handleAddLoc = () => _popupHandler(true);
  const handleSearch = (event) => changeState({ search: event.target.value });
  const fillterList = (list) => {
    return list.filter((data) => {
      const name = data.name.toLowerCase();
      const alias = data.alias.toLowerCase();
      const address = data.address.toLowerCase();
      const district = data.zip.district.toLowerCase();
      const sub_province = data.zip.sub_province.toLowerCase();
      const zip = data.zip.zip.toLowerCase();
      const search = state.search.toLowerCase();

      return (
        name.indexOf(search) > -1 ||
        alias.indexOf(search) > -1 ||
        address.indexOf(search) > -1 ||
        district.indexOf(search) > -1 ||
        sub_province.indexOf(search) > -1 ||
        zip.indexOf(search) > -1
      );
    });
  };

  // useMemo
  useMemo(() => initPage(), []);
  useEffect(() => {
    const search_list = fillterList(state.loc_list);
    changeState({ search_list });
  }, [state.search]);

  return (
    <>
      <LocationModalView
        toggle={toggle}
        popupHandler={_popupHandler}
        data={selectedLoc}
        process={process}
        seller={seller}
      />
      <div className={style.container}>
        {/* sub header */}
        <div className={style.sub_header}>
          <div className={style.search}>
            <Input
              value={state.search}
              onChange={(e) => handleSearch(e)}
              placeholder="Cari Alamat"
            />
          </div>
          <div className={style.btn_container}>
            <Button active onClick={() => handleAddLoc()}>
              + Tambah Alamat
            </Button>
          </div>
        </div>
        {/* body */}
        <div className={style.list_outer_container}>
          <div className={style.list_container}>
            {is_loading && <LocationCardLoading />}
            {!is_loading && search_list.length === 0 ? (
              <>Tidak ada Alamat</>
            ) : (
              search_list.map((data, idx) => {
                const primary = data.is_primary === 1;
                const pin_flag = data.latitude !== "";
                const { district, sub_province, zip } = data.zip;
                const address_dtl =
                  `${district}, ${sub_province}, ${zip}`.toLowerCase();
                return (
                  <div
                    key={`${data.id}${idx}`}
                    className={style.card_container}
                  >
                    <div className={style.dtl_container}>
                      <div className={style.dtl_container_header}>
                        <div id={style.alias}>{data.alias}</div>
                        {primary && <div id={style.badge}>{"Utama"}</div>}
                      </div>
                      <div id={style.name}>{`${data.name}`}</div>
                      <div>{data.hp}</div>
                      <div id={style.address}>
                        {`${data.address}, ${address_dtl}`}
                      </div>
                      <div id={style.pin_point}>
                        <i className="bi bi-geo-alt-fill" />
                        <label>
                          {pin_flag
                            ? "Sudah PinPoint"
                            : "Aktifkan PinPoint Anda"}
                        </label>
                      </div>
                    </div>
                    <div className={style.act_container}>
                      <div
                        className={style.action_i}
                        onClick={() => handleUpdateLoc(data)}
                      >
                        Ubah Alamat
                      </div>
                      {!primary && (
                        <div
                          className={style.action_i}
                          onClick={() => handleSetPrimary(data.id)}
                        >
                          Jadikan Alamat Utama
                        </div>
                      )}
                      {search_list.length !== 1 && (
                        <div
                          className={style.action_i}
                          onClick={() => handleDeleteLoc(data.id)}
                        >
                          Hapus
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationMain;
