import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import style from "../../../../css/varianForm/varianDetailForm.module.css";
import {
  BASE_URL_IMG,
  regexName,
  regexNumber,
} from "../../../../utils/Constants";
import useDebounce from "../../../../utils/useDebounce";
import { CCurrencyInput, Input } from "../../../CustomComponent/StyledComponent";
import Switcher from "../../../CustomComponent/Switcher";
import SelllerImageCropModalView from "../../SellerProfile/SelllerImageCropModalView";
import InputImageVarian from "./InputImageVarian";

function VarianDetailFormView({ data, onChange, ...props }) {
  const { name1, name2, group1, group2 } = data;
  const sellerId = useSelector((s) => s.global.userData.seller.id);
  const img_base_link = `${BASE_URL_IMG}50/50/${sellerId}/`;
  const _img_link = data.image ? `${img_base_link}${data.image}` : null;
  const group2_flag = group2 !== "";
  const [state, setState] = useState({
    price: data.price,
    stock: data.stock,
    sku: data.sku,
    image: data.image,
    img_link: _img_link,
    is_primary: data.is_primary === 1,
    is_active: data.is_active === 1,
    // crop
    crop_img: undefined,
    crop_toggle: false,
  });

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeNumber = (value, name, values) => changeState({ [name]: value ?? 0 });
  const changeSKU = (event) => {
    const { name, value } = event.target;
    if (regexName.test(value) || value === "") {
      changeState({ [name]: value });
    }
  };
  const onBlur = (event) => {
    const { name, value } = event.target;
    if (value === "") changeState({ [name]: data[name] ?? 0 });
  };
  const handleActive = (event) => changeState({ is_active: event.target.checked });
  const handlePrimary = (is_primary = !state.is_primary) => {
    changeState({ is_primary });
    props.onChangePrimary(props.idx, is_primary)
  }

  // image
  const changeImage = (event) => {
    const crop_img = event.target.files[0];
    changeState({ crop_img, crop_toggle: true });
  };
  const deleteImage = () => changeState({ image: null, img_link: null });
  const handleCropImg = async (img_res) => {
    const image = img_res?.img ?? state.image;
    const img_link = img_res?.img_url ?? state.img_link;
    changeState({ crop_img: undefined, crop_toggle: false, image, img_link });
  };

  // useEffect
  useEffect(() => {
    if (data) {
      const is_primary = data.is_primary === 1;
      const is_active = data.is_active === 1
      changeState({ price: data.price, stock: data.stock, sku: data.sku, is_primary, is_active });
    }
  }, [data.price, data.stock, data.sku, data.is_primary, data.is_active]);

  useEffect(() => onChange(props.idx, state), [state.image]);
  useDebounce(() => onChange(props.idx, state), 500,
    [state.price, state.stock, state.sku, state.is_active]
  );

  return (
    <>
      {state.crop_toggle && (
        <SelllerImageCropModalView
          toggle={state.crop_toggle}
          title={"Gambar Varian"}
          baseImg={state.crop_img}
          popupHandler={handleCropImg}
          _aspect={1}
          size={100}
        />
      )}
      <div className={`${style.container} ${style._main}`}>
        <div className={`${style.container}`}>
          <div className={style.input_container}>
            <label className={style.input_title}>{group1}</label>
            <label>{name1}</label>
          </div>
          {group2_flag && (
            <div className={style.input_container}>
              <label className={style.input_title}>{group2}</label>
              <label>{name2}</label>
            </div>
          )}
        </div>
        <div className={`${style.container}`}>
          <div className={style.input_container}>
            <label className={style.input_title}>Stok</label>
            <CCurrencyInput name="stock" value={state.stock ?? 0} onValueChange={changeNumber}
              onBlur={(e) => onBlur(e)}
              onFocus={e => e.target.select()}
            />
          </div>
          <div className={style.input_container}>
            <label className={style.input_title}>Harga</label>
            <div className={style.price_container}>
              <span>Rp.</span>
              <CCurrencyInput name="price" value={state.price ?? 0} onValueChange={changeNumber}
                onBlur={(e) => onBlur(e)}
                onFocus={e => e.target.select()}
              />
            </div>
          </div>
          <div className={style.input_container}>
            <label className={style.input_title}>SKU</label>
            <Input
              name="sku"
              value={state.sku}
              onBlur={(e) => onBlur(e)}
              onChange={(e) => changeSKU(e)}
            />
          </div>
        </div>
        <div className={`${style.action_container}`}>
          <InputImageVarian
            name={`${name1}_${name2}`}
            img_link={state.img_link}
            changeImage={changeImage}
            deleteImage={deleteImage}
          />
          <div className={`${style.action_part}`}>
            <Switcher
              id={`switch_${name1}_${name2}`}
              disabled={state.is_primary}
              toggle={state.is_active}
              onChangeToggle={handleActive}
            />
            <div className={style._primary}>
              {!state.is_primary && (
                <i
                  className={`bi bi-three-dots`}
                  onClick={() => handlePrimary()}
                />
              )}
              {state.is_primary && <span>Utama</span>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VarianDetailFormView;
