import React from "react";
import Skeleton from "react-loading-skeleton";

import { InlineWrapper } from "../../controller/loadingController";
import style from "../../css/userProfile/profile.module.css";

function UserProfileLoading(props) {
  return (
    <>
      <div className={style.container}>
        <div className={style.foto_container}>
          <Skeleton width={100} height={100} circle />
          <Skeleton
            width={100}
            height={30}
            wrapper={(obj) => InlineWrapper(obj, { marginTop: "0.5rem" })}
          />
        </div>
        <div className={style.form_container}>
          <Skeleton
            height={20}
            count={6}
            wrapper={(obj) => InlineWrapper(obj, { marginBottom: "0.2rem" })}
          />
        </div>
      </div>
      <div className={style.btn_container}>
        <Skeleton width={100} height={30} />
      </div>
    </>
  );
}

export default UserProfileLoading;
