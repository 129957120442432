import dayjs from "dayjs";
import { APIChat } from "../utils/API";
import { moveImage } from "./globalController";
import { uploadImg } from "./sellerProfileController";

// firebase
const isLetter = str => str.length === 1 && str.match(/[a-z]/i)
function generateId() {
    const str =
        Math.random().toString(36).slice(2, 22) +
        Math.random().toString(16).slice(2, 12);
    let modifiedStr = str;
    const charArr = [];
    for (let i = 0; i < str.length; i++)  if (isLetter(str[i])) charArr.push(i);
    for (let i = 0; i < charArr.length; i++) {
        const change = (Math.random() * 2).toFixed();
        if (change > 1) {
            const dataElement = charArr[i];
            modifiedStr =
                modifiedStr.substring(0, dataElement) +
                modifiedStr[dataElement].toUpperCase() +
                modifiedStr.substring(dataElement + 1);
        }
    }
    modifiedStr = modifiedStr.substring(0, 20);
    return modifiedStr;
};

export async function makeFirebaseListener(room) {

}

export function createChatRoomData(seller, user) {
    return {
        'room_id': generateId(),
        'seller_id': seller.id,
        'seller': { 'name': seller?.name ?? "", 'logo': seller?.logo ?? null },
        'user_id': user.id,
        'user': { 'name': user?.name ?? "", 'logo': user?.logo ?? null },
        'unread_message_seller': 0,
        'unread_message_buyer': 0,
        'room_hidden_seller': 0,
        'room_hidden_buyer': 0,
        'last_message': "",
        'created_at': dayjs().format("YYYY-MM-DD HH:mm:ss"),
        'updated_at': dayjs().format("YYYY-MM-DD HH:mm:ss"),
    }
}

export function createMsgData({ sender, room, type = "buyer", ...payload }) {
    if ([null, undefined].includes(sender) || [null, undefined].includes(room)) return null
    const sender_type = type === "buyer" ? 0 : 1;
    return {
        'id': generateId(),
        'room_id': room.room_id,
        'message_type': payload?.message_type ?? "",
        'message_status': payload?.message_status ?? "",
        'sender_id': sender.id,
        sender_type,
        'message_item': payload?.message_item ?? "",
        'message_text': payload?.message_text ?? "",
        'product_data': payload?.product_data ?? "",
        'hidden': payload?.hidden ?? 0,
        'created_at': payload?.created_at ?? dayjs().format("DD-MM-YYYY HH:mm:ss"),
        'updated_at': payload?.updated_at ?? dayjs().format("DD-MM-YYYY HH:mm:ss"),
    }
}

// 1toko
export async function getRoomChatList({ seller_id = null, user_id = null, cancel = null, ...payload }) {
    const post_data = new FormData();
    if (seller_id !== null) post_data.append('seller_id', seller_id)
    if (user_id !== null) post_data.append('user_id', user_id)
    if (user_id !== null) post_data.append('user_id', user_id)
    if (payload?.status) post_data.append('filter', payload.status)

    const page = `?page=${payload?.page ?? 1}`
    const limit = `&limit=${payload?.limit ?? 10}`
    const search = `&q=${payload?.search ?? ""}`
    return APIChat.post(`room/list${page}${limit}${search}`, post_data, { signal: cancel?.signal ?? null })
        .then((res) => res.data).catch((err) => undefined)
}

export async function checkRoomChat(seller_id = null, user_id = null, cancel = null) {
    const post_data = new FormData();
    if (seller_id !== null) post_data.append('seller_id', seller_id)
    if (user_id !== null) post_data.append('user_id', user_id)
    return APIChat.post(`room/check`, post_data, { signal: cancel?.signal ?? null })
        .then((res) => res.data.data.room_id).catch((err) => undefined)
}

export async function addUpdateRoom(post_data) {
    return APIChat.post(`room/create`, post_data)
        .then((res) => res.data).catch((err) => undefined)
}

export async function getMessageList({ room_id, ...payload }) {
    const page = `?page=${payload?.page ?? 1}`
    const limit = `&limit=${payload?.limit ?? 10}`
    return APIChat.post(`message/list${page}${limit}`, { room_id })
        .then((res) => res.data).catch((err) => undefined);
}

export async function uploadMsgImage(imgList = [], seller_id = null) {
    if (imgList.length === 0 || seller_id === null) return imgList;
    let temp = [];
    imgList.map((img) => temp.push(uploadImg(img)))
    const data = await Promise.all(temp);

    temp = [];
    data.map((data) => temp.push(moveImage(data, `img/${seller_id}`)))
    await Promise.all(temp)

    return { data, upload_at: new Date() }
}

export async function createMessage(post_data) {
    delete post_data.unread_message_buyer;
    delete post_data.unread_message_seller;
    return APIChat.post(`message/create`, post_data).then((res) => res.data).catch((err) => undefined)
}

export async function readMessage({ room_id, sender_id, sender_type }) {
    const post_data = new FormData();
    post_data.append('room_id', room_id)
    post_data.append('sender_id', sender_id)
    post_data.append('sender_type', sender_type)
    return APIChat.post(`message/read`, post_data).then((res) => res.data).catch((err) => undefined)
}

export async function hideMessage(message_id) {
    const post_data = new FormData();
    post_data.append('id', message_id)
    return APIChat.post(`message/hide`, post_data).then((res) => res.data).catch((err) => undefined)
}