import React from "react";
import CustomModal, { HeaderModal } from "../../../CustomComponent/CustomModal";
import style from "../../../../css/sellerProfile/payoutTransaction.module.css";
import trasn_style from "../../../../css/sellerProfile/sellerTransaction.module.css";
import { formatCurrency } from "../../../../controller/globalController";
import InvoiceView from "./InvoiceView";

function PayoutTransRptDtlModalView({ toggle, popupHandler, request, detail = [] }) {
  const title = `Detail Payout : ${request.id}`;
  const admin_fee =
    parseInt(request.remaining_amount) + parseInt(request.admin_fee);
  const courier_fee =
    parseInt(request.courier_amount) +
    parseInt(request.courier_insurance_amount);
  const { gross_amount, paid_amount } = request;

  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={() => popupHandler()}
      size="lg"
    >
      <HeaderModal title={title} modalHandler={() => popupHandler()} />
      <div className={trasn_style.trans_detail}>
        <table className={trasn_style.detail} style={{ width: "unset" }}>
          <tbody>
            <tr>
              <td>Total Pendapatan Kotor</td>
              <td>:</td>
              <td>{formatCurrency(gross_amount)}</td>
            </tr>
            <tr>
              <td>Total Biaya Admin</td>
              <td>:</td>
              <td>{formatCurrency(admin_fee)}</td>
            </tr>
            <tr>
              <td>Total Biaya Pengiriman</td>
              <td>:</td>
              <td>{formatCurrency(courier_fee)}</td>
            </tr>
            <tr>
              <td>Total Pendapatan Bersih</td>
              <td>:</td>
              <td>{formatCurrency(paid_amount)}</td>
            </tr>
            <tr>
              <td colSpan={3}>
                <hr style={{ margin: "0.5rem" }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={style.list_container}>
        {detail.map((data, idx) => {
          const key = `${request.id}_${idx}`;
          const open_flag = idx === 0;
          if (data) {
            return <InvoiceView key={key} data={data} toggle={open_flag} />;
          }
          return;
        })}
      </div>
    </CustomModal>
  );
}

export default PayoutTransRptDtlModalView;
