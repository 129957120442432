import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import style from "../../../../css/sellerProfile/payoutTransaction.module.css";
import DatePicker from "react-datepicker";
import id from "date-fns/locale/id";
import {
  CDropdownLine2,
  InputDate,
  InputLine,
} from "../../../CustomComponent/StyledComponent";
import PayoutTransRptDtlModalView from "./PayoutTransRptDtlModalView";
import { formatCurrency } from "../../../../controller/globalController";
import CustomMutipleSelectDropdown from "./CustomMutipleSelectDropdown";
import {
  getPayoutStatus,
  tableStyle,
  getPayoutDetail,
  getPayoutRpt,
} from "../../../../controller/payoutTransactionController";
import DataTable from "react-data-table-component";
import PayoutReportExcel from "./PayoutReportExcel";
import PayoutLoading from "../../../Loading/PayoutLoading";
import { getDetailPrint } from "../../../../controller/transactionController";
import InvoiceDetailModalView from "../../PayoutTransaction/InvoiceDetailModalView";
import { getPayoutRptExcelData } from "../../../../controller/reportController";
import useDebounce from "../../../../utils/useDebounce";

const date = dayjs();
const from_date = date.subtract(30, "day").format("YYYY-MM-DD");
const to_date = date.format("YYYY-MM-DD");
const _FDate = "dd - MM - yyyy";

const initState = {
  list: [],
  rpt_list: [],
  type_list: [
    { id: 1, name: "Payout" },
    { id: 2, name: "Invoice" },
  ],
  payout_status: [],
  selected_data: undefined,
  selected_dtl: undefined,
  from_date: dayjs(from_date).toDate(),
  to_date: dayjs(to_date).toDate(),

  // table
  type: { id: 1, name: "Payout" },
  search: "",
  filter: [],
  filter_text: "",
  current_page: 1,
  total_per_page: 5,
  total_data: 1,

  // other
  is_loadingPage: false,
  is_loading: false,
  toggle: false,
};

function PayoutTransReportMain(props) {
  const [state, setState] = useState(initState);
  const { search, filter, type, current_page, total_per_page } = state;
  const from_date = dayjs(state.from_date).format("YYYY-MM-DD");
  const to_date = dayjs(state.to_date).format("YYYY-MM-DD");
  const flag_type = type.name === "Payout";

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeSearch = (search) => changeState({ search });
  const changeFilter = (filter, filter_text) => {
    changeState({ filter, filter_text });
  };
  const changePerRows = (total_per_page) => changeState({ total_per_page });
  const changePage = (current_page) => changeState({ current_page });
  const changeType = (type) => changeState({ type });
  const changeDate = (date, type) => {
    const data = { [`${type}_date`]: date };
    if (type === "from") data.to_date = dayjs(date).add(30, "day").toDate();
    changeState(data);
  };

  const handlePopup = async (toggle = !state.toggle, data = undefined) => {
    let selected_dtl = undefined;
    if (toggle && data) {
      Swal.fire({
        didOpen: () => Swal.showLoading(),
        allowOutsideClick: false,
      });
      if (flag_type) selected_dtl = await getPayoutDetail(data.id);
      if (!flag_type) selected_dtl = await getDetailPrint(data.invoice_id);
      Swal.close();
    }
    changeState({ toggle, selected_data: data, selected_dtl });
  };

  const loadPage = async (filter, type, page = 1, limit = 5) => {
    changeState({ is_loading: true });
    const post_data = { from_date, to_date, search, filter, page, limit, type };
    const list = await getPayoutRpt(post_data);
    const rpt_list = await getPayoutRptExcelData(post_data);
    const data = { is_loading: false };
    if (list) {
      data.list = list.data;
      data.total_data = list.total;
      data.current_page = list.current_page;
      data.rpt_list = rpt_list;
    }
    changeState(data);
  };
  const initPage = async () => {
    changeState({ is_loadingPage: true });
    const payout_status = await getPayoutStatus();
    const limit = state.total_per_page;
    const post_data = { from_date, to_date, search, filter, limit, type };
    const list = await getPayoutRpt(post_data);
    const rpt_list = await getPayoutRptExcelData(post_data);
    payout_status.shift();
    const data = { is_loadingPage: false };
    data.payout_status = payout_status;
    if (list) {
      data.list = list.data;
      data.total_data = list.total;
      data.current_page = list.current_page;
      data.rpt_list = rpt_list;
    }
    changeState(data);
  };

  // useEffect
  useEffect(() => initPage(), []);
  useEffect(() => {
    if (state.payout_status.length > 0) {
      loadPage(filter, type, current_page, total_per_page);
    }
  }, [current_page, total_per_page, from_date, to_date, state.total_per_page]);
  useDebounce(() => loadPage(filter, type, current_page, total_per_page), 500, [
    search,
    filter,
    type,
  ]);

  // table
  const inv_col = [
    {
      name: "No. Invoice",
      selector: (row) => row.invoice_id,
      minWidth: "200px",
      format: (row) => (
        <a className={style.link} onClick={() => handlePopup(true, row)}>
          {row.invoice_id}
        </a>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Tanggal Transaksi",
      selector: (row) => row.date,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status Penarikan Dana",
      selector: (row) => row.status,
      style: { margin: "0" },
      sortable: true,
      wrap: true,
    },
    {
      name: "Nominal",
      selector: (row) => row.paid_amount,
      format: (row) => formatCurrency(row.paid_amount),
      sortable: true,
      right: true,
    },
  ];
  const payout_col = [
    {
      name: "No. Permintaan",
      selector: (row) => row.invoice_id,
      minWidth: "200px",
      format: (row) => (
        <a className={style.link} onClick={() => handlePopup(true, row)}>
          {row.id}
        </a>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status_name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tanggal Pengajuan",
      selector: (row) => dayjs(row.request_date).format("DD - MM - YYYY"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Tanggal Disetujui",
      selector: (row) =>
        row.approve_date
          ? dayjs(row.approve_date).format("DD - MM - YYYY")
          : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Tanggal Ditolak",
      selector: (row) =>
        row.reject_date ? dayjs(row.reject_date).format("DD - MM - YYYY") : "-",
      sortable: true,
      wrap: true,
    },
    {
      name: "Jumlah Diterima",
      selector: (row) => formatCurrency(row.paid_amount),
      sortable: true,
      wrap: true,
    },
  ];

  if (state.is_loadingPage) return <PayoutLoading />;
  return (
    <>
      {state.toggle && state.selected_dtl && flag_type && (
        <PayoutTransRptDtlModalView
          toggle={state.toggle}
          request={state.selected_data}
          detail={state.selected_dtl}
          popupHandler={handlePopup}
        />
      )}
      {state.toggle && state.selected_dtl && !flag_type && (
        <InvoiceDetailModalView
          toggle={state.toggle}
          invoice={state.selected_dtl}
          popupHandler={handlePopup}
        />
      )}
      <div className={style.container}>
        <div className={`${style.out_date_container} ${style.right_position}`}>
          <span>Rentang Tanggal</span>
          <div className={style.date_container}>
            <DatePicker
              locale={id}
              dateFormat={_FDate}
              selected={state.from_date}
              onChange={(date) => changeDate(date, "from")}
              // popperClassName={style.date_poper}
              customInput={<InputDate />}
            />
            <span>s/d</span>
            <DatePicker
              locale={id}
              dateFormat={_FDate}
              minDate={state.from_date}
              selected={state.to_date}
              onChange={(date) => changeDate(date, "to")}
              customInput={<InputDate />}
            />
          </div>
        </div>
        <div className={`${style.btn_container} ${style.right_position}`}>
          <PayoutReportExcel
            type={type.name}
            dataset={state.rpt_list}
            disabled={state.rpt_list.length === 0}
          />
        </div>
        <div className={style.filter_container}>
          <InputLine
            value={state.search}
            onChange={(e) => changeSearch(e.target.value)}
            placeholder="Cari Data"
          />
          <CDropdownLine2>
            <CDropdownLine2.Toggle id="dropdown-autoclose-true">
              <span>{type?.name}</span>
              <i className={`bi bi-chevron-down`} />
            </CDropdownLine2.Toggle>
            <CDropdownLine2.Menu>
              {state.type_list.map((data, idx) => {
                return (
                  <CDropdownLine2.Item
                    key={`${data.id}_${idx}`}
                    onClick={() => changeType(data)}
                  >
                    {data.name}
                  </CDropdownLine2.Item>
                );
              })}
            </CDropdownLine2.Menu>
          </CDropdownLine2>
          <CustomMutipleSelectDropdown
            option={state.payout_status}
            selected={state.filter}
            onChange={changeFilter}
            text={state.filter_text}
          />
        </div>
        <div className={style.table_container}>
          <DataTable
            keyField="payout"
            responsive
            fixedHeader
            fixedHeaderScrollHeight="400px"
            columns={flag_type ? payout_col : inv_col}
            data={state.list}
            progressPending={state.is_loading}
            progressComponent={<>Loading...</>}
            dense={true}
            noDataComponent={<>Tidak ada data</>}
            pagination
            paginationServer
            paginationTotalRows={state.total_data}
            paginationPerPage={state.total_per_page}
            paginationRowsPerPageOptions={[5, 10, 20]}
            customStyles={tableStyle}
            onChangeRowsPerPage={changePerRows}
            onChangePage={changePage}
          />
        </div>
      </div>
    </>
  );
}

export default PayoutTransReportMain;
