import React, { useState } from "react";
import { useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import style from "../../../../css/varianForm/varianDropdown.module.css";
import { DEFAULT_VARIAN_TYPE } from "../../../../utils/ConstantSeller";
import { Input } from "../../../CustomComponent/StyledComponent";
import InputTypeVarianView from "./InputTypeVarianView";

function DropdownVarianType({ list = [], value = "", used_type = [], changeType, onChange, ...props }) {
  const [state, setState] = useState({ _list: list, new_type: "", is_add: false, toggle: false });
  const { is_edit, _list, toggle } = state;
  const menu_style = `${style.dropdown_list} ${style[toggle ? "down" : "up"]}`;
  //  function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeNewType = (e) => changeState({ new_type: e.target.value });
  const handleAdd = () => changeState({ is_add: true, new_type: "" });
  const checkList = (value = state.new_type) => _list.find((d) => d === value);
  const handleClose = () => {
    changeState({ new_type: "", is_add: false, is_edit: false });
  };
  const handleChangeType = (disable = false, data) => {
    if (!disable && !is_edit) {
      changeState({ toggle: false });
      changeType(props.id, data);
    }
  };
  const handleChange = (idx, type, varian_type) => {
    if (type === "edit") {
      const old = _list[idx] === varian_type;
      if (checkList(varian_type) === undefined || old) {
        _list[idx] = varian_type;
      } 
    }

    if (type === "delete") {
      _list.splice(idx, 1);
      changeState({ _list });
    }
  };
  const handleAddSubmit = (e) => {
    e.preventDefault();
    const temp_list = [..._list];
    if (checkList() === undefined) temp_list.push(state.new_type);
    changeState({ is_add: false, _list: temp_list });
  };

  useEffect(() => onChange(_list), [_list]);
  useEffect(() => changeState({ _list: list }), [list]);
  useEffect(() => changeState({ is_add: false }), [state.toggle]);
  return (
    <div className={style.container}>
      <OutsideClickHandler
        onOutsideClick={() => changeState({ toggle: false })}
      >
        <div
          className={style.dropdown_btn}
          onClick={() => changeState({ toggle: !toggle })}
        >
          <span>{value !== "" ? value : "Pilih Type"}</span>
          <i className={`bi bi-chevron-${toggle ? "up" : "down"}`} />
        </div>
        <ul className={menu_style}>
          {_list.map((data, idx) => {
            const key = `${props.id}_${idx}`;
            const flag = DEFAULT_VARIAN_TYPE.includes(data);
            const disable = used_type.includes(data);
            let _style = flag ? "" : style.new_type;
            if (disable) _style = `${_style} ${style.disable}`;
            if (flag) {
              return (
                <li
                  key={key}
                  className={_style}
                  onClick={() => handleChangeType(disable, data)}
                >
                  <span>{data}</span>
                </li>
              );
            } else {
              return (
                <InputTypeVarianView
                  key={key}
                  idx={idx}
                  value={data}
                  selected={disable}
                  toggle={state.toggle}
                  className={_style}
                  handleChange={handleChange}
                  handleChangeType={() => handleChangeType(disable, data)}
                />
              );
            }
          })}
          <li className={style.add_btn_container}>
            {!state.is_add && (
              <div className={style.add_btn} onClick={() => handleAdd()}>
                <i className="bi bi-plus-circle" id={props.id} />
                <span>Buat tipe varian</span>
              </div>
            )}
            {state.is_add && (
              <>
                <div className={style.input_title}>
                  <span>Buat varian baru</span>
                  <span>{`${state.new_type.length} / 30`}</span>
                </div>
                <div className={style.input_container}>
                  <form onSubmit={(e) => handleAddSubmit(e)}>
                    <Input
                      value={state.new_type}
                      onChange={(e) => changeNewType(e)}
                      maxLength={30}
                      autoFocus
                    />
                  </form>
                  <i className="bi bi-x-lg" onClick={() => handleClose()} />
                </div>
              </>
            )}
          </li>
        </ul>
      </OutsideClickHandler>
    </div>
  );
}

export default DropdownVarianType;
