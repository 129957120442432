import React, { useMemo, useState } from "react";
import { formatCurrency, formatDate } from "../../../../controller/globalController";
import { getTransItemList } from "../../../../controller/transactionController";
import style from "../../../../css/sellerProfile/sellerTransaction.module.css";
import TransactionItem from "../../../Transaction/TransactionItem";

const dateFormat = "DD - MM - YYYY";
function SalesReportCardView({ data, lastElRef }) {
  const [toggle, setToggle] = useState(false);
  const { invoice_id, cart, customer_details, transaction_details } = data;
  const { address, city, postal_code, phone } =
    customer_details.shipping_address;
  const _address = `${address}, ${city}, ${postal_code}`.toLowerCase();
  const item_list = useMemo(() => getTransItemList([cart]));

  return (
    <div className={style.trans_container_item} ref={lastElRef}>
      <div style={{ width: "100%" }}>
        <div className={style.inner_trans_container1}>
          <div className={style.inv_no}>{`No. Invoice : ${invoice_id}`}</div>
          <div id={style.stat}>
            {`Tanggal Transaksi : ${formatDate(
              data.transaction_date,
              dateFormat
            )}`}
          </div>
        </div>
        <div className={style.inner_trans_container2}>
          <div className={`${style.trans_item_container}`}>
            <div className={`${style.trans_item} ${toggle ? style.show : ""}`}>
              {item_list.map((data, idx) => {
                const key = `${data._product.dtl_product_id}_${idx}`;
                if ((!toggle && idx === 0) || toggle) {
                  return <TransactionItem key={key} data={data} />;
                }
                return;
              })}
            </div>
            {item_list.length > 1 && (
              <div
                className={style.show_more}
                onClick={() => setToggle(!toggle)}
              >
                {!toggle
                  ? `+${item_list.length - 1} Produk lainnya`
                  : "Lihat lebih sedikit"}
              </div>
            )}
          </div>

          <div className={style.trans_detail}>
            <table className={style.detail}>
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{customer_details.first_name}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{_address}</td>
                </tr>
                <tr>
                  <td>No.Hp</td>
                  <td>:</td>
                  <td>{phone}</td>
                </tr>
                <tr>
                  <td className={style.detail_title}>Total Belanja</td>
                  <td>:</td>
                  <td>{formatCurrency(transaction_details.gross_amount)}</td>
                </tr>
                <tr>
                  <td className={style.detail_title}>Metode Pembayaran</td>
                  <td>:</td>
                  <td>{transaction_details.payment_type.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesReportCardView;
