import React, { useState } from "react";
import { Input } from "../../CustomComponent/StyledComponent";
import style from "../../../css/sellerProfile/etalaseInputView.module.css";
import OutsideClickHandler from "react-outside-click-handler";

function EtalaseInputView({ is_private, value = "", list = [], onChange }) {
  const [toggle, setToggle] = useState(false);
  const _value = value?.name ?? value ?? "";
  const list_style = style[toggle ? "down" : "up"];
  const searchList = list.filter((data) => {
    if (_value === "") return data;
    return data.name.indexOf(value) > -1;
  });

  // function
  const handleOnClick = (data) => { setToggle(false); onChange(data); };

  return (
    <OutsideClickHandler onOutsideClick={() => setToggle(false)}>
      <div className={style.container}>
        <Input
          value={_value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setToggle(true)}
          placeholder="Pilih Etalase"
          disabled={list.length === 0}
        />
        <ul className={`${style.list_container} ${list_style}`}>
          {searchList.length === 0 && (_value === "" || is_private) && (
            <li className={style.empty}>Tidak ada data</li>
          )}

          {searchList.length === 0 && !(_value === "" || is_private) && (
            <li onClick={() => handleOnClick(_value)}>{_value}</li>
          )}

          {searchList.map((data, idx) => {
            return (
              <li key={`etalase_${idx}`} onClick={() => handleOnClick(data)}>
                {data.name}
              </li>
            );
          })}
        </ul>
      </div>
    </OutsideClickHandler>
  );
}

export default EtalaseInputView;
