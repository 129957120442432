export const SET_CART_LIST = "SET_CART_LIST";
export const SET_USER_LOC_LIST = "SET_USER_LOC_LIST";
export const INIT_SHIPMENT = "INIT_SHIPMENT";
export const SET_USER_LOC = "SET_USER_LOC";
export const SET_TPR = "SET_TPR"; // total promo
export const SET_TCP = "SET_TCP"; // total courier price
export const SET_TIN = "SET_TIN"; // total courier insurance

// action function
// export const setLoading = (loading) => ({ type: SET_TRANS_LOADING, loading });

export const initShipment = (
  shipmentList,
  userLocationList,
  paymentList = []
) => {
  const userLocation = userLocationList[0];
  const totalData = shipmentList.reduce(
    ({ totalItem, totalPrice }, cart) => {
      const { qty, price } = cart.detail_checked.reduce(
        ({ qty, price }, item) => {
          const { varian, product } = item;
          let flag = parseInt(item.is_checked) === 1;
          let temp_qty = parseInt(flag ? item.qty : 0);
          let temp_price = parseInt(
            varian ? varian.fix_price : product.fix_price
          );
          temp_price = temp_price * temp_qty;
          return {
            qty: qty + temp_qty,
            price: price + parseInt(flag ? temp_price : 0),
          };
        },
        { qty: 0, price: 0 }
      );
      return { totalItem: totalItem + qty, totalPrice: totalPrice + price };
    },
    { totalItem: 0, totalPrice: 0 }
  );
  const data = {
    shipmentList,
    totalData,
    userLocationList,
    userLocation,
    paymentList,
  };
  return { type: INIT_SHIPMENT, initShipment: data };
};

export const setCartList = (cartList) => {
  const { totalItem, totalPrice } = cartList.reduce(
    ({ totalItem, totalPrice }, cart) => {
      let { qty, price } = cart.detail.reduce(
        ({ qty, price }, item) => {
          const { varian, product } = item;
          const stock = parseInt(varian ? varian.stock : product.stock);
          const flag_out_stock = product.out_of_stock === 1 || stock === 0;
          const flag_low_stock = stock < product.minimum_order;
          const flag_buy = !flag_out_stock && !flag_low_stock;

          let flag = parseInt(item.is_checked) === 1 && flag_buy;
          let temp_qty = parseInt(flag ? item.qty : 0);
          let temp_price = parseInt(
            varian ? varian.fix_price : product.fix_price
          );
          temp_price = temp_price * temp_qty;
          return {
            qty: qty + temp_qty,
            price: price + parseInt(flag ? temp_price : 0),
          };
        },
        { qty: 0, price: 0 }
      );
      return { totalItem: totalItem + qty, totalPrice: totalPrice + price };
    },
    { totalItem: 0, totalPrice: 0 }
  );
  return { type: SET_CART_LIST, cartList, totalItem, totalPrice };
};

export const setUserLoc = (userLocation) => {
  return { type: SET_USER_LOC, userLocation };
};

export const setUserLocList = (userLocList) => {
  return { type: SET_USER_LOC_LIST, userLocList };
};

export const setInsurance = (cartId, insurance) => {
  const cartData = { id: cartId, insurance: insurance };
  return { type: SET_TIN, cartData };
};

export const setPromo = (detailPromo, totalPrice) => {
  let totalDiscount = 0;
  let totalCommision = 0;
  const { discount_unit, commission_unit } = detailPromo;
  const flag_promo = discount_unit === "percent";
  const flag_comm = commission_unit === "percent";

  totalDiscount = parseInt(detailPromo.discount_value);
  totalCommision = parseInt(detailPromo.commission_value);

  if (flag_promo) {
    const max_promo = parseInt(detailPromo.max_discount_value ?? 999999999999);
    totalDiscount = Math.round((parseInt(totalPrice) * totalDiscount) / 100);
    if (totalDiscount > max_promo) totalDiscount = max_promo;
  }

  if (flag_comm) {
    totalCommision = Math.round((parseInt(totalPrice) * totalCommision) / 100);
  }
  return { type: SET_TPR, detailPromo, totalDiscount, totalCommision };
};

export const setSelectedCourier = (cartId, service) => {
  const cartData = { id: cartId, service: service };
  return { type: SET_TCP, cartData };
};
