import React from "react";
import style from "../../../css/sellerProfile/storePerformance.module.css";

function DtlCardView({ title, content }) {
  return (
    <div className={style.dtl_card_container}>
      <div>{title}</div>
      <div>{content}</div>
    </div>
  );
}

export default DtlCardView;
