import React, { useState } from 'react';
import style from './upload.module.css'
import CustomModal, { HeaderModal } from '../../CustomComponent/CustomModal';
import { Button } from '../../CustomComponent/StyledComponent';
import { uploadProduct } from '../../../controller/sellerProductController';
import Swal from 'sweetalert2';


function SellerProductUploadModalView({ toggle = false, popupHandler, ...props }) {
    const title = `Unggah Produk`
    const exampleLink = 'https://api.auth.v2.1toko.com/template-import-item.xlsx'

    const [state, setState] = useState({ loading: false, file: undefined })
    const handleFile = (e) => setState((prev) => ({ ...prev, file: e.target.files[0] }))
    const handleUpload = async () => {
        if ([null, undefined, ""].includes(state.file)) return Swal.fire({ icon: 'error', text: 'Unggah file terlebih dahulu' })
        setState((prev) => ({ ...prev, loading: true }))
        const res = await uploadProduct(state.file);
        if (!res) {
            return Swal.fire({ icon: 'error', text: 'Gagal mengunggah produk' })
                .then(() => setState((prev) => ({ ...prev, loading: false })))
        }
        return Swal.fire({ icon: 'success', text: 'Berhasil mengunggah produk' }).then(() => popupHandler(false, res))
    }

    return (
        <CustomModal toggle={toggle} modalHandler={() => popupHandler(false)}>
            <HeaderModal title={title} modalHandler={() => popupHandler(false)} />
            <div className={`alert alert-warning ${style.warning_container}`} role="alert" >
                <i className="bi bi-exclamation-triangle-fill" style={{ size: '2rem' }} />
                <span>CATATAN : hanya format excel yang bisa diunggah !</span>
            </div>
            <label className={`${style.donwload_container}`}>
                <span>{`Unduh Contoh file EXCEL`}</span>
                <a href={exampleLink} style={{ textTransform: 'lowercase' }}>di sini !</a>
            </label>
            <div className={style.input_file}>
                <input className="form-control" type="file" disabled={state.loading}
                    onChange={handleFile}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <Button active onClick={() => handleUpload()} disabled={state.loading}>
                    {state.loading ? 'Menunggu...' : 'Unggah'}
                </Button>
            </div>
        </CustomModal>
    );
}

export default SellerProductUploadModalView;