import LocationMain from "../components/Location/LocationMain";
import UserProfileMain from "../components/ProfileUser/UserProfile/UserProfileMain";
import UserTransactionMain from "../components/ProfileUser/UserTransaction/UserTransactionMain";
import WishlistMainView from "../components/Wishlist/WishlistMainView";
import ChatMain from "../components/Chat/ChatMain";

// UserMenu
export const PROFILE_UMENU = "";
export const LOCATION_UMENU = "location";
export const TRANS_UMENU = "transaction";
export const WISHLIST_UMENU = "wishlist";
export const ORDER_COMPLAIN_UMENU = "order_complain";
export const CHAT_UMENU = "chat";

export const PROFILE_UMENU_T = "DATA DIRI";
export const LOCATION_UMENU_T = "DAFTAR ALAMAT";
export const TRANS_UMENU_T = "TRANSAKSI";
export const WISHLIST_UMENU_T = "WISHLIST";
export const ORDER_COMPLAIN_UMENU_T = "PESANAN DIKOMPLAIN";
export const CHAT_UMENU_T = "CHAT";

// transaction status
export const TRANS_PENDING = "pending";
export const TRANS_PACKED = "packed";
export const TRANS_SENT = "sent";
export const TRANS_FINISHED = "finished";
export const TRANS_CANCELED = "canceled";

export const TRANS_MENU = [
  { code: TRANS_PENDING, name: "belum dibayar" },
  { code: TRANS_PACKED, name: "pesanan dikemas" },
  { code: TRANS_SENT, name: "pesanan dikirim" },
  { code: TRANS_FINISHED, name: "pesanan selesai" },
  { code: TRANS_CANCELED, name: "pesanan dibatalkan" },
];

export const UMENU_ROUTES = [
  PROFILE_UMENU,
  LOCATION_UMENU,
  TRANS_UMENU,
  WISHLIST_UMENU,
  ORDER_COMPLAIN_UMENU,
  CHAT_UMENU,
];

export const UMENU_USER_ACCOUNT = [
  { link: PROFILE_UMENU, title: PROFILE_UMENU_T },
  { link: LOCATION_UMENU, title: LOCATION_UMENU_T },
  { link: WISHLIST_UMENU, title: WISHLIST_UMENU_T },
];

export const UMENU_TANSACTION = [
  { link: TRANS_UMENU, title: TRANS_UMENU_T },
  { link: ORDER_COMPLAIN_UMENU, title: ORDER_COMPLAIN_UMENU_T },
  { link: CHAT_UMENU, title: CHAT_UMENU_T },
];

export const getUserPage = (pageName) => {
  switch (pageName) {
    case PROFILE_UMENU:
      return { title: PROFILE_UMENU_T, component: <UserProfileMain /> };
    case LOCATION_UMENU:
      return { title: LOCATION_UMENU_T, component: <LocationMain /> };
    case TRANS_UMENU:
      return { title: TRANS_UMENU_T, component: <UserTransactionMain /> };
    case WISHLIST_UMENU:
      return { title: WISHLIST_UMENU_T, component: <WishlistMainView /> };
    case ORDER_COMPLAIN_UMENU:
      return {
        title: ORDER_COMPLAIN_UMENU_T,
        component: <>komplain pesanan</>,
      };
    case CHAT_UMENU:
      return { title: CHAT_UMENU_T, component: <ChatMain type="buyer" /> };
    default:
      return "";
  }
};
