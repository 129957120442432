import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getTransactionData } from "../../../controller/userTransactionController";
import style from "../../../css/userProfile/userTransaction.module.css";
import { TRANS_MENU, TRANS_PENDING } from "../../../utils/ConstantUser";
import { Button, InputDate } from "../../CustomComponent/StyledComponent";
import TransactionUserView from "./TransactionUserView";
import TransactionPendingView from "./TransactionPendingView";
import InvoiceCardLoading from "../../Loading/InvoiceCardLoading";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import id from "date-fns/locale/id";

const date = dayjs();
const from_date = date.subtract(30, "day").format("YYYY-MM-DD");
const to_date = date.format("YYYY-MM-DD");
const _FDate = "dd - MM - yyyy";

const initState = {
  transList: [],
  is_loading: false,
  from_date: dayjs(from_date).toDate(),
  to_date: dayjs(to_date).toDate(),
};

function UserTransactionMain(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.substring(1).split("/");
  const status = path[2];
  const baseLink = `/user_profile/transaction/`;
  const abortCtrl = new AbortController();

  const [state, setState] = useState(initState);
  const { transList, is_loading, from_date, to_date } = state;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeTab = (tab) => navigate(`${baseLink}${tab}`);
  const changeDate = (date, type) => {
    const data = { [`${type}_date`]: date };
    if (type === "from") data.to_date = dayjs(date).add(30, "day").toDate();
    changeState(data);
  };

  // useEffect
  const initPage = async () => {
    changeState({ status: path[2], is_loading: true });
    const data = [status, from_date, to_date, abortCtrl];
    let transList = await getTransactionData(...data);
    changeState({ status: path[2], transList, is_loading: false });
  };

  useEffect(() => {
    if (path[1] === "transaction") initPage();
    return () => { setState(initState); abortCtrl.abort(); }
  }, [status, state.from_date, state.to_date]);

  return <div className={style.container}>
    <div className={style.nav_menu}>
      {TRANS_MENU.map((data, idx) => (
        <Button
          key={`trans_menu_${idx}`}
          active={!(status === data.code)}
          onClick={() => changeTab(data.code)}
        >
          {data.name}
        </Button>
      ))}
    </div>
    <div className={style.sub_header}>
      <div id={style.search}>search</div>
      <div id={style.date}>
        <span>Tanggal Transaksi</span>
        <DatePicker
          locale={id}
          dateFormat={_FDate}
          selected={from_date}
          onChange={(date) => changeDate(date, "from")}
          customInput={<InputDate />}
        />
        <span>s/d</span>
        <DatePicker
          locale={id}
          dateFormat={_FDate}
          selected={to_date}
          onChange={(date) => changeDate(date, "to")}
          customInput={<InputDate />}
        />
      </div>
    </div>
    <div className={style.trans_container_outer}>
      <div className={style.trans_container}>
        {is_loading && <InvoiceCardLoading />}
        {!is_loading && (transList?.length ?? 0) === 0 && (
          <>Tidak ada transaksi</>
        )}
        {!is_loading &&
          (transList?.length ?? 0) > 0 &&
          transList.map((data, idx) => {
            const key = `${status}_${idx}`;
            const flag = status === TRANS_PENDING;
            const component = flag
              ? TransactionPendingView
              : TransactionUserView;
            return React.createElement(component, { key, data });
          })}
      </div>
    </div>
  </div>
}

export default UserTransactionMain;
