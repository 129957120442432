import React, { useState } from "react";
import { Button, CSellerNavDropdown } from "../CustomComponent/StyledComponent";
import style from "../../css/dropdown.module.css";
import { BASE_URL_IMG, DOMAIN, webLink } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { isDomain } from "../../controller/authController";

function SellerMenu({ seller = null, sub_seller = false, ...props }) {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  if (["", null, undefined].includes(seller)) return <></>;

  const { id, logo, name, subdomain, domain, is_private } = seller;
  const hostname = window.location.hostname;
  const inDomain = hostname.indexOf(isDomain ? domain : subdomain) > -1;
  const img_link = logo !== "" ? `${BASE_URL_IMG}50/50/${id}/${logo}` : "";
  const store_link = (isDomain && inDomain) ? `https://${webLink}` : `https://${subdomain}.${DOMAIN}`;
  const seller_dashboard_link = `${store_link}/seller_dashboard`;
  
  const toSellerDashboard = () => {
    const link = sub_seller && is_private === 1 ? "/seller_dashboard/performance" : "/seller_dashboard"
    const is_local = hostname === 'localhost' && [subdomain, domain].includes(webLink)
    if (inDomain || is_local) return navigate(link);
    if (!inDomain) return window.location.href = seller_dashboard_link;
  };

  return (
    <li>
      <div
        className={style.outer_navbar}
        onMouseEnter={() => setToggle(true)}
        onMouseLeave={() => setToggle(false)}
      >
        <CSellerNavDropdown
          title={`Toko Saya`}
          className={`${style.navdrop}`}
          onClick={() => setToggle(!toggle)}
          show={toggle}
          content="none"
        >
          <div className={style.seller_container}>
            <img src={img_link} onClick={() => (window.location = store_link)} />
            <div className={style.store_dtl}>
              <div className={style.store_name}>{name}</div>
              <Button
                active
                onClick={() => toSellerDashboard()}
                style={{ color: "white !important", whiteSpace: "nowrap" }}
              >
                Atur Toko
              </Button>
            </div>
          </div>
        </CSellerNavDropdown>
      </div>
    </li>

  );
}

export default SellerMenu;
