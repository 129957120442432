import React from "react";
import CustomModal, { HeaderModal } from "../CustomComponent/CustomModal";
import LocationFormView from "./LocationFormView";

function LocationModalView(props) {
  const { toggle, popupHandler, process } = props;
  const title = `${process === "ADD" ? "Tambah" : "Ubah"} Alamat`;
  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={() => popupHandler(false, "CLOSE")}
    >
      <HeaderModal
        title={title}
        modalHandler={() => popupHandler(false, "CLOSE")}
      />
      <LocationFormView {...props} />
    </CustomModal>
  );
}

export default LocationModalView;
