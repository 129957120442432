import { APIAuth } from "../utils/API";

// function
export const addUpdateBank = async (data) => {
  const { id } = data;
  let res = undefined;
  const send_data = {
    bank_id: data.bank.id,
    no_rek: data.no_rek,
    name: data.name,
  };
  if (!id) res = await storeBankAccount(send_data);
  if (id) res = await updateBankAccount(send_data, id);
  return res;
};

// call API
export const getBankAccountList = async () => {
  return APIAuth.get(`sellerbank`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getBankList = async () => {
  return APIAuth.get(`param/bank`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const storeBankAccount = async (data) => {
  return APIAuth.post(`sellerbank`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

const updateBankAccount = async (data, id) => {
  return APIAuth.put(`sellerbank/${id}`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const deleteBankAccount = async (id) => {
  return APIAuth.delete(`sellerbank/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const setAsPrimary = async (id) => {
  return APIAuth.put(`sellerbank/setprimary/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};
