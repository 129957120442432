import { APIAuth } from "../utils/API";

//function
export const addUpdateEtalase = async (data) => {
  const { id } = data;
  let res = undefined;
  if (!id) res = await storeEtalase(data.name);
  if (id) res = await updateEtalase(data);
  return res;
};

// call Api
export const getProductEtalaseList = async (status = undefined) => {
  return APIAuth.get(status ? `etalase?active=${status}` : `etalase`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const storeEtalase = async (name) => {
  return APIAuth.post(`etalase`, { name })
    .then((res) => res.data)
    .catch((err) => {
      // console.log(err);
      return undefined;
    });
};

const updateEtalase = async (data) => {
  const { id, name, is_active, seq } = data;
  return APIAuth.put(`etalase/${id}`, { name, is_active, seq })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const deleteEtalase = async (etalase_id) => {
  return APIAuth.delete(`etalase/${etalase_id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

// sub etalase
export const getProductSubEtalaseList = (sub_id) => {
  return APIAuth.get(`subetalase?id=${sub_id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
}

export const addUpdateSubEtalase = async (data) => {
  let res = undefined;
  if (!data?.id) res = await storeSubEtalase(data);
  if (data?.id) res = await updateSubEtalase(data);
  return res;
};

const storeSubEtalase = async (payload) => {
  const { name, etalase_id } = payload
  return APIAuth.post(`subetalase?id=${etalase_id}`, { name })
    .then((res) => res.data)
    .catch((err) => {
      // console.log(err);
      return undefined;
    });
};

const updateSubEtalase = async (data) => {
  const { id, name, is_active, seq } = data;
  return APIAuth.put(`subetalase/${id}`, { name, is_active, seq })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const deleteSubEtalase = async (sub_id) => {
  return APIAuth.delete(`subetalase/${sub_id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};