import React from "react";
import style from "../../css/chat.module.css";
import { BASE_URL_IMG } from "../../utils/Constants";
import { fixDate, formatDate } from "../../controller/globalController";

function ChatBubble({ chat = undefined, user_id = undefined, room = undefined }) {
  if (!chat || !user_id || !room) return <></>;
  const side_flag = chat.sender_id === user_id;
  const position = side_flag ? style.chat_right : style.chat_left;
  // const product = JSON.parse(chat.product_data);
  const imgList = !["", undefined, null].includes(chat.message_item) ? chat.message_item.data : [];
  var chatTime = formatDate(new Date(chat.created_at), "DD/MM/YYYY HH:mm");
  if (isNaN(chatTime)) chatTime = formatDate(new Date(fixDate(chat.created_at)), "DD/MM/YYYY HH:mm")

  const seller_id = room.seller_id;
  const base_img = `${BASE_URL_IMG}100/100/${seller_id}/`;
  // let product_img = product?.image ?? product?.photo[0]?.name;
  // if (product_img === "") product_img = null;
  const chatClass = `${style.chat_bubble} ${position}`;

  return (
    <>
      {imgList.map((img, img_idx) => (
        <div key={`{${img_idx}`} className={chatClass}>
          <img src={`${base_img}${img}`} />
          <span>{chat.message_status}</span>
        </div>
      ))}
      {/* {product !== "" && (
        <div className={chatClass}>
          <div className={style.product_chat}>
            <img src={`${base_img}${product_img}`} />
            <div>
              <div>{product.name}</div>
              <div></div>
            </div>
          </div>
          <span>{chat.message_status}</span>
        </div>
      )} */}
      {chat.message_text !== "" && (
        <div className={chatClass}>
          <div className={style.chat_text}>{chat.message_text}</div>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <span>{chat.message_status}</span>
            <span>{chatTime}</span>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatBubble;
