import React from "react";
import style from "../../../css/sellerProfile/storePerformance.module.css";

function RankCardView({ rank, image_lnk, name, qty }) {
  return (
    <div className={style.rank_card_container}>
      <div style={{ width: "30px" }}>{rank}</div>
      <img src={image_lnk} />
      <div>
        <div className={style.product_name}>{name}</div>
        <div style={{fontSize:'0.8rem'}}>{`Terjual ${qty}`}</div>
      </div>
    </div>
  );
}

export default RankCardView;
