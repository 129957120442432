import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  generateVarian,
  initVarianType,
} from "../../../../controller/sellerProductController";
import style from "../../../../css/sellerProfile/createProduct/sellerProductVarianForm.module.css";
import style_input from "../../../../css/varianForm/varianDetailForm.module.css";
import { regexName, regexNumber } from "../../../../utils/Constants";
import { DEFAULT_VARIAN_TYPE } from "../../../../utils/ConstantSeller";
import { Button, CCurrencyInput, Input } from "../../../CustomComponent/StyledComponent";
import DropdownVarianType from "./DropdownVarianType";
import InputVarianView from "./InputVarianView";
import VarianDetailFormView from "./VarianDetailFormView";

function VarianFormView({
  toggle = false,
  varian_group = [],
  varian = [],
  handleChange, // set varian ke main form
}) {
  const location = useLocation();
  const isEdit = location.pathname.indexOf("edit") > -1;
  const group_key = Object.keys(varian_group);
  const default_type = initVarianType(DEFAULT_VARIAN_TYPE, group_key);
  const def_type_list1 = varian_group[group_key[0]] ?? [];
  const def_type_list2 = varian_group[group_key[1]] ?? [];
  const [state, setState] = useState({
    varian_type1: group_key[0] ?? "",
    varian_type2: group_key[1] ?? "",
    varian_type1_list: [...def_type_list1],
    varian_type2_list: [...def_type_list2],
    type_list: [...default_type],
    varian_list: [...varian],
    varian_toggle: group_key[1] === undefined,
    // fill all
    fill_toggle: false,
    price: 0,
    stock: 0,
    sku: "",
  });

  const { type_list, varian_type1, varian_type2, varian_toggle } = state;
  const { varian_type1_list, varian_type2_list } = state;
  const used_type = [varian_type1, varian_type2];

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const fillToggle = (fill_toggle = !state.fill_toggle) => {
    changeState({ fill_toggle, price: 0, stock: 0, sku: "" });
  };
  const changeNumber = (value, name, values) => changeState({ [name]: value });
  const changeSKU = (event) => {
    const { name, value } = event.target;
    if (regexName.test(value) || value === "") {
      changeState({ [name]: value ?? 0 });
    }
  };
  const changeNewList = (type_list) => changeState({ type_list });
  const confirmMessage = async (title, text, confirm_text = "") => {
    return Swal.fire({
      // icon,
      title: title,
      text: text,
      cancelButtonText: "Batal",
      showCancelButton: true,
      confirmButtonText: confirm_text,
      confirmButtonColor: "var(--main_color)",
      cancelButtonColor: "#d33",
    }).then((res) => res);
  };
  const changeType = async (name, value) => {
    let flag = true;
    if (state.varian_type1 !== "") {
      const text =
        "Jika diubah,data varian yang telah dimasukkan akan terhapus.";
      const title = "Ubah Tipe Varian?";
      flag = (await confirmMessage(title, text, "Ya, ubah")).value;
    }

    if (flag) {
      const list_name = `${name}_list`;
      changeState({ [name]: value, varian_list: [], [list_name]: [] });
    }
  };
  const handleDeleteVarianType = async (name) => {
    const text =
      "Jika dihapus, data varian yang telah dimasukkan akan berubah dan tipe varian akan hilang dari pilihan varian.";
    const title = "Hapus tipe varian?";
    const flag = (await confirmMessage(title, text, "Ya, hapus")).value;
    if (flag) {
      const list_name = `${name}_list`;
      const data = { varian_list: [], varian_toggle: true };
      if (name === "varian_type1") {
        const flag = varian_type2 !== "";
        data[name] = flag ? state.varian_type2 : "";
        data[list_name] = flag ? state.varian_type2_list : [];
        data.varian_type2 = flag ? [] : state.varian_type2;
        data.varian_type2_list = flag ? [] : state.varian_type2_list;
      }

      if (name === "varian_type2") {
        data[name] = "";
        data[list_name] = [];
      }

      const varian_list = generateVarian(
        data.varian_type1 ?? varian_type1,
        data.varian_type2 ?? varian_type2,
        data.varian_type1_list ?? varian_type1_list,
        data.varian_type2_list ?? varian_type2_list
      );

      data.varian_list = varian_list;

      changeState(data);
    }
  };

  // varian function
  const handleChangeVarian = (name, list) => {
    const varian_list = generateVarian(
      varian_type1,
      varian_type2,
      name.indexOf("1") > -1 ? list : varian_type1_list,
      name.indexOf("2") > -1 ? list : varian_type2_list
    );
    changeState({ [name]: list, varian_list });
  };
  const handleDeleteVarian = (name, deleted, list) => {
    const varian_list = state.varian_list.filter((d) => {
      if (name.indexOf("1") > -1 && d.name1 !== deleted) return d;
      if (name.indexOf("2") > -1 && d.name2 !== deleted) return d;
    });
    changeState({ [name]: list, varian_list });
  };
  const handleChangeVarianList = (idx, data) => {
    const price = data.price === "" ? "" : parseInt(data.price);
    const stock = data.stock === "" ? "" : parseInt(data.stock);
    const varian_list = [...state.varian_list];
    varian_list[idx].price = price;
    varian_list[idx].stock = stock;
    varian_list[idx].sku = data.sku;
    varian_list[idx].is_active = +data.is_active;
    varian_list[idx].image = data.image;
    changeState({ varian_list });
  };
  const handlePrimaryChange = (_idx, is_primary) => {
    const varian_list = [...state.varian_list];
    varian_list.map((varian, idx) => {
      const flag = idx === _idx;
      varian.is_primary = flag ? +is_primary : 0
      if (is_primary && flag) varian.is_active = 1;
      return varian;
    })
    changeState({ varian_list });
  };

  const handleActiveChange = (_idx) => {
    const varian_list = state.varian_list.map((varian, idx) => {
      const flag = idx === _idx;
      varian.is_primary = flag ? 1 : 0;
      if (flag && !isEdit) varian.is_active = 1;
      return varian;
    });
    changeState({ varian_list });
  };

  const handleChangeAll = () => {
    const varian_list = state.varian_list.map((varian) => {
      varian.price = state.price;
      varian.stock = state.stock;
      varian.sku = state.sku;
      return varian;
    });
    changeState({ varian_list, price: 0, stock: 0, sku: "" });
  };

  // useEffect
  useEffect(() => {
    const vg = {};
    if (varian_type1 !== "") vg[varian_type1] = varian_type1_list;
    if (varian_type2 !== "") vg[varian_type2] = varian_type2_list;
    handleChange(state.varian_list, vg);
  }, [state.varian_list]);
  useEffect(() => {
    if (!toggle)
      setState({
        varian_type1: group_key[0] ?? "",
        varian_type2: group_key[1] ?? "",
        varian_type1_list: [...def_type_list1],
        varian_type2_list: [...def_type_list2],
        type_list: [...default_type],
        varian_list: [...varian],
        varian_toggle: group_key[1] === undefined,
      });
  }, [toggle]);
  useEffect(
    () => changeState({ fill_toggle: false }),
    [varian_type1, varian_type2]
  );

  // view
  return (
    <div className={`${style.container}`}>
      <div className={style.type_input_container}>
        <label className={style.title}>Tipe Varian</label>
        <div className={style.input_container}>
          <DropdownVarianType
            id={"varian_type1"}
            list={type_list}
            value={state.varian_type1}
            used_type={used_type}
            changeType={changeType}
            onChange={changeNewList}
          />
          <div className={style.input_part_container}>
            <InputVarianView
              name="varian_type1_list"
              disabled={varian_type1 === ""}
              varian_list={state.varian_type1_list}
              onChange={handleChangeVarian}
              onDelete={handleDeleteVarian}
            />
            <i
              className={`bi bi-trash-fill ${style.trash}`}
              onClick={() => handleDeleteVarianType("varian_type1")}
            />
          </div>
        </div>
        {!varian_toggle && (
          <div className={style.input_container}>
            <DropdownVarianType
              id={"varian_type2"}
              list={type_list}
              value={state.varian_type2}
              used_type={used_type}
              changeType={changeType}
              onChange={changeNewList}
            />
            <div className={style.input_part_container}>
              <InputVarianView
                name="varian_type2_list"
                disabled={varian_type2 === ""}
                varian_list={state.varian_type2_list}
                onChange={handleChangeVarian}
                onDelete={handleDeleteVarian}
              />
              <i
                className={`bi bi-trash-fill ${style.trash}`}
                onClick={() => handleDeleteVarianType("varian_type2")}
              />
            </div>
          </div>
        )}
        {varian_toggle && (
          <Button active onClick={() => changeState({ varian_toggle: false })}>
            Tambah Variasi
          </Button>
        )}
      </div>
      {varian_type1_list.length !== 0 && (
        <div className={style.varian_list_container}>
          <label className={style.title}>Daftar Varian</label>
          {state.varian_list.length > 1 && (
            <div className={style.input_all_container}>
              <Button
                active
                className={style.title}
                onClick={() => fillToggle()}
              >
                Isi Semua
              </Button>
              {state.fill_toggle && (
                <div className={`${style.input_container1}`}>
                  <div className={style_input.input_container}>
                    <label className={style_input.input_title}>Stok</label>
                    <CCurrencyInput name="stock" value={state.stock ?? 0} onValueChange={changeNumber}
                      onFocus={e => e.target.select()}
                    />
                  </div>
                  <div className={style_input.input_container}>
                    <label className={style_input.input_title}>Harga</label>
                    <div className={style_input.price_container}>
                      <span>Rp.</span>
                      <CCurrencyInput name="price" value={state.price ?? 0} onValueChange={changeNumber}
                        onFocus={e => e.target.select()}
                      />
                    </div>
                  </div>
                  <div className={style_input.input_container}>
                    <label className={style_input.input_title}>SKU</label>
                    <Input
                      name="sku"
                      value={state.sku ?? ""}
                      onChange={(e) => changeSKU(e)}
                    />
                  </div>
                  <Button active onClick={() => handleChangeAll()}>
                    Terapkan
                  </Button>
                </div>
              )}
            </div>
          )}
          {varian.map((varian, idx) => {
            const key = `varian_${idx}`;
            return (
              <VarianDetailFormView
                key={key}
                idx={idx}
                data={varian}
                isEdit={isEdit}
                onChange={handleChangeVarianList}
                onChangePrimary={handlePrimaryChange}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default VarianFormView;
