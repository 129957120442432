import dayjs from "dayjs";
import { APITrans } from "../utils/API";

export const addUpdatePromo = async (data) => {
  let { id, start_period, end_period } = data;
  start_period = dayjs(start_period).format("YYYY-MM-DD");
  end_period = dayjs(end_period).format("YYYY-MM-DD");
  // console.log(data)
  const send_data = new FormData();
  if (id) send_data.append("id", id);
  send_data.append("referral_code", data.promo_code);
  send_data.append("name", data.name);
  send_data.append("commission_value", data.commission_value);
  send_data.append("commission_unit", data.commission_unit.value);
  send_data.append("discount_value", data.discount_value);
  send_data.append("discount_unit", data.discount_unit.value);
  send_data.append("max_discount_value", data.max_discount_value);
  send_data.append("start_period", start_period);
  send_data.append("end_period", end_period);

  let res = undefined;
  if (!id) res = await storePromo(send_data);
  if (id) res = await updatePromo(send_data);

  return res;
};

export const getStatus = (start_period, end_period) => {
  const current_date = dayjs(dayjs().format("YYYY-MM-DD"));
  const start = dayjs(start_period);
  const end = dayjs(end_period);
  let status = "Aktif";
  if (current_date < start || current_date > end) status = "Tidak Aktif";
  return status;
};

// call api
const storePromo = async (data) => {
  return APITrans.post(`promo/store`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

const updatePromo = async (data) => {
  return APITrans.post(`promo/update`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const getPromoList = async () => {
  return APITrans.get(`promo/list`)
    .then((res) => res.data)
    .catch((err) => {
      return undefined;
    });
};

export const generatePromoCode = async () => {
  return APITrans.get(`promo/generate_code`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return "";
    });
};

export const checkPromo = async (code, seller_id) => {
  const _code = `?code=${code}`;
  const _sellerId = `&seller_id=${seller_id}`;
  return APITrans.get(`promo/check_code${_code}${_sellerId}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const getPromoDetail = async (code, seller_id) => {
  const _code = `?code=${code}`;
  const _sellerId = `&seller_id=${seller_id}`;
  return APITrans.get(`promo/get_promo${_code}${_sellerId}`)
    .then((res) => res.data)
    .catch((err) => {
      // console.log(err);
      return undefined;
    });
};
