import React, { useState } from 'react';
import dayjs from "dayjs";
import style from "../../../css/userProfile/userTransaction.module.css";
import { getTransItemList } from '../../../controller/transactionController';
import { formatDate } from '../../../controller/globalController';
import TransactionItem from '../../Transaction/TransactionItem';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../CustomComponent/StyledComponent';

const dateFormat = "DD MMMM YYYY HH : mm";
function TransactionPendingView(props) {
    const navigate = useNavigate();
    const [state, setState] = useState({ toggle: false });
    const { order_id, cart, customer_details, transaction_details } = props.data;
    const { address, city, postal_code, phone } = customer_details.shipping_address;
    const first_name = customer_details?.address?.name ?? customer_details.shipping_address.first_name;
    const _address = `${address}, ${city}, ${postal_code}`.toLowerCase();
    const item_list = getTransItemList(cart);
    const trans_exp = dayjs(props.data.expired_time);
    
    const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
    const goToChat = () => navigate("/seller_dashboard/chat", { state: { start_chat: true, cusData: customer_details } });

    return (
        <div className={style.trans_container_item}>
            <div className={style.inner_trans_container1}>
                <div className={style.inv_no}>{`No. Pembayanan : ${order_id}`}</div>
                <div id={style.stat}>{`Status Pesanan : Pesanan Belum Dibayar`}</div>
                <div id={style._date}>
                    {formatDate(props.data.created_at, dateFormat)}
                </div>
            </div>
            <div className={style.inner_trans_container2}>
                <div className={`${style.trans_item_container}`}>
                    <div className={`${style.trans_item} ${state.toggle ? style.show : ""}`}>
                        {item_list.map((data, idx) => {
                            const key = `${data._product.dtl_product_id}_${idx}`;
                            if ((!state.toggle && idx === 0) || state.toggle) {
                                return <TransactionItem key={key} data={data} />;
                            }
                        })}
                    </div>
                    {item_list.length > 1 && (
                        <div className={style.show_more} onClick={() => changeState({ toggle: !state.toggle })}>
                            {!state.toggle ? `+${item_list.length - 1} Produk lainnya` : "Lihat lebih sedikit"}
                        </div>
                    )}
                </div>

                <div className={style.trans_detail}>
                    <table className={style.detail}>
                        <tbody>
                            <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td>{first_name}</td>
                            </tr>
                            <tr>
                                <td>Alamat</td>
                                <td>:</td>
                                <td>{_address}</td>
                            </tr>
                            <tr>
                                <td>No.Hp</td>
                                <td>:</td>
                                <td>{phone}</td>
                            </tr>
                            <tr>
                                <td>Metode Pembayaran</td>
                                <td>:</td>
                                <td>{transaction_details.payment_type.name}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button active onClick={() => goToChat()} style={{ maxWidth: '120px' }}>
                            <i className="bi bi-chat-left-text"></i>
                            <span>Chat</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransactionPendingView;