import React from "react";
import style from "../../css/sellerProfile/payoutTransaction.module.css";
import loading_style from "../../css/loading.module.css";
import Skeleton from "react-loading-skeleton";

import { InlineWrapper } from "../../controller/loadingController";

function PayoutLoading(props) {
  return (
    <div className={style.container}>
      <div className={`${style.out_date_container} ${style.right_position}`}>
        <Skeleton width={300} count={1} />
      </div>
      <div className={`${style.btn_container} ${style.right_position}`}>
        <Skeleton width={120} count={1} />
        <Skeleton width={120} count={1} />
      </div>
      <div className={loading_style.flex_container}>
        <Skeleton count={1} />
        <Skeleton count={1} />
      </div>
      <hr />
      <Skeleton
        count={5}
        width={`15vw`}
        wrapper={(obj) => InlineWrapper(obj, { margin: "0.2rem 0" })}
        containerClassName={loading_style.line_container}
      />
      <Skeleton
        count={10}
        wrapper={(obj) => InlineWrapper(obj, { margin: "0.5rem 0" })}
      />
    </div>
  );
}

export default PayoutLoading;
