import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { updateStoreStatus } from "../../../controller/sellerStatusController";
import style from "../../../css/sellerProfile/storeStatus.module.css";
import { changeStore, changeUser } from "../../../redux/globalAction";
import { globalStorage } from "../../../utils/Storage";
import { Button } from "../../CustomComponent/StyledComponent";
import Loading from "../../Loading/Loading";

function StoreStatusMain(props) {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.global.userData);
  const storeData = useSelector((s) => s.global.storeData);
  const [state, setState] = useState({
    status: userData?.seller?.is_open ?? 0,
    is_loading: false,
  });
  const { status, is_loading } = state;
  
  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeStatus = async () => {
    changeState({ is_loading: true });
    const _status = [0, 2].includes(status) ? 1 : 2;
    const temp = await updateStoreStatus(_status);
    if (!temp.errors) {
      if (storeData) {
        storeData.is_open = temp.is_open;
        dispatch(changeStore(storeData));
        globalStorage.setItem("SD", storeData);
      }
      userData.seller.is_open = temp.is_open;
      dispatch(changeUser(userData));
      globalStorage.setItem("UD", userData);
      changeState({ is_loading: false, status: temp.is_open });
    } else {
      changeState({ is_loading: false });
      let error_text = `Silahkan pastikan toko telah memiliki alamat, akun bank dan minimal 1 produk untuk membuka toko`;
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: error_text,
      });
    }
  };

  return (
    <div className={style.container}>
      <Button
        active
        className={style.status_btn}
        onClick={() => changeStatus()}
        disabled={is_loading}
      >
        {is_loading && <Loading />}
        {!is_loading && [0, 2].includes(status) && "Buka"}
        {!is_loading && status === 1 && "Tutup"}
      </Button>
    </div>
  );
}

export default StoreStatusMain;
