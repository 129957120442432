import React, { useState } from "react";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import style from "../../../css/sellerProfile/sellerTransaction.module.css";
import { getTransItemList } from "../../../controller/transactionController";
import TransactionItem from "../../Transaction/TransactionItem";
import { formatCurrency } from "../../../controller/globalController";

const dateFormat = "DD MMMM YYYY";
function InvoiceDetailModalView({ toggle, popupHandler, invoice }) {
  const { invoice_id, cart, customer_details, transaction_details } = invoice;
  const { address, city, postal_code, phone } =
    customer_details.shipping_address;
  const _address = `${address}, ${city}, ${postal_code}`.toLowerCase();
  const item_list = getTransItemList([cart]);
  const [toggle1, setToggle1] = useState(false);
  const title = `Detail Invoice : ${invoice_id}`;
  var courier = `${invoice.courier_name} ( ${invoice.courier_service} )`;
  if (["self_pickup", "self_courier"].includes(invoice.courier_code)) {
    courier = `${invoice.courier_name} `;
  }

  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={() => popupHandler()}
      size="lg"
    >
      <HeaderModal title={title} modalHandler={() => popupHandler()} />
      <div style={{ width: "100%" }}>
        <div className={style.inner_trans_container2}>
          <div className={`${style.trans_item_container}`}>
            <div className={`${style.trans_item} ${toggle1 ? style.show : ""}`}>
              {item_list.map((data, idx) => {
                const key = `${data._product.dtl_product_id}_${idx}`;
                if ((!toggle && idx === 0) || toggle) {
                  return <TransactionItem key={key} data={data} />;
                }
                return;
              })}
            </div>
            {item_list.length > 1 && (
              <div
                className={style.show_more}
                onClick={() => setToggle1(!toggle1)}
              >
                {!toggle1
                  ? `+${item_list.length - 1} Produk lainnya`
                  : "Lihat lebih sedikit"}
              </div>
            )}
          </div>

          <div className={style.trans_detail}>
            <table className={style.detail} style={{ width: "unset" }}>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <hr style={{ margin: "0" }} />
                  </td>
                </tr>
                <tr>
                  <td>Pengiriman</td>
                  <td>:</td>
                  <td>{courier}</td>
                </tr>
                <tr>
                  <td>Metode Pembayaran</td>
                  <td>:</td>
                  <td>{transaction_details.payment_type.name}</td>
                </tr>
                <tr>
                  <td>Total Bayar</td>
                  <td>:</td>
                  <td>{formatCurrency(transaction_details.gross_amount)}</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <hr style={{ margin: "0" }} />
                  </td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{customer_details.first_name}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{_address}</td>
                </tr>
                <tr>
                  <td>No.Hp</td>
                  <td>:</td>
                  <td>{phone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

export default InvoiceDetailModalView;
