import React, { useEffect, useMemo, useState } from "react";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import style from "../../../css/sellerProfile/etalase.module.css";
import { Button, Input } from "../../CustomComponent/StyledComponent";
import { addUpdateEtalase } from "../../../controller/productEtalaseController";
import Swal from "sweetalert2";

const initState = { id: undefined, name: "", error: "" };
function EtalaseFormModalView(props) {
  const { data, toggle, popupHandler } = props;
  const title = (data ? "Ubah" : "Tambah") + " Etalase";
  const [state, setState] = useState(initState);

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const validation = () => {
    const { name } = state;
    let temp = true;
    let error = "";
    const flag = name === "" || name.length < 2;
    if (flag) temp = false;
    if (flag) error = "Nama etalase minimal 2 huruf";
    changeState({ error });
    return temp;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validation()) { return Swal.close(); }
    Swal.fire({ didOpen: () => Swal.showLoading(), allowOutsideClick: false });
    const res = await addUpdateEtalase(state);
    if (!res) { return Swal.fire({ title: "Gagal", icon: "error", }) }
    Swal.fire({ title: "Berhasil", icon: "success" })
      .then(() => popupHandler(false, "FINISH"));
  };

  // useEffect
  useEffect(() => {
    if (data) changeState({ id: data.id, name: data.name });
    return () => setState(initState);
  }, [data]);

  //   view
  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={() => popupHandler(false, "CLOSE")}
      size="sm"
    >
      <HeaderModal
        title={title}
        modalHandler={() => popupHandler(false, "CLOSE")}
      />
      <form className={style.form_container} onSubmit={(e) => handleSubmit(e)}>
        <div className="form-group" style={{ margin: "5px 0" }}>
          <label htmlFor="name" style={{ margin: "0", fontSize: "0.9rem" }}>
            Nama Etalase
          </label>
          <Input
            type="text"
            id="name"
            className="form-control"
            value={state.name}
            onChange={(e) => changeState({ name: e.target.value, error: "" })}
            autoFocus={true}
          />
          {state.error !== "" && <div className="error_txt">{state.error}</div>}
        </div>
        <Button active type="submit">
          Simpan
        </Button>
      </form>
    </CustomModal>
  );
}

export default EtalaseFormModalView;
