import React from "react";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import queryString from "query-string";
import style from "../../../css/faspayView.module.css";
import { formatCurrency } from "../../../controller/globalController";
import { Button } from "../../CustomComponent/StyledComponent";
import { useNavigate } from "react-router-dom";

export const getPaymentImage = (type) => {
  switch (type) {
    case "va_permata":
      return "https://dev.faspay.co.id/__assets/img-newtpl/faspay_402_logo.png";
    default:
      return null;
  }
};

export const BankView = ({ data }) => {
  const { bill_no, trx_id } = data.param;
  const img_payment = getPaymentImage(data.payment);
  const copy = (text) => navigator.clipboard.writeText(text);

  return (
    <div className={style.bank_container}>
      <div className={style.via_container}>
        <div id={style.label}>Pembayaran Via:</div>
        <img
          src={img_payment}
          style={{ display: img_payment ? "block" : "none" }}
        />
      </div>
      <div className={style.dtl_bank_container}>
        <div className={style.text_container}>
          <div id={style.name}>Nomor Pesanan</div>
          <div id={style.value}>{bill_no}</div>
        </div>
        <div className={style.text_container}>
          <div id={style.name}>Nomor Akun Virtual</div>
          <div className={style.va_number_container}>
            <div id={style.value}>{trx_id}</div>
            <button className="bi bi-clipboard" onClick={() => copy(trx_id)} />
          </div>
        </div>
        <div className={style.text_container}>
          <div id={style.name}>Total Pembayaran</div>
          <div id={style.value}>{formatCurrency(data.gross_amount)}</div>
        </div>
      </div>
    </div>
  );
};

function FaypayPaymentModalView(props) {
  const navigate = useNavigate();
  const { data, toggle } = props;
  const url = new URL(data?.redirect_url);
  const param = queryString.parse(url?.search);
  const payment = data?.payment_methode.type;

  const title = "";
  const ovo_flag = payment === "ovo";

  const handleSubmit = () => {
    navigate("/user_profile/transaction/pending", { replace: true });
  };

  return (
    <CustomModal
      toggle={toggle}
      modalHandler={() => handleSubmit()}
      outsideClick
    >
      <HeaderModal title={title} modalHandler={() => handleSubmit()} />
      {!ovo_flag && (
        <BankView data={{ payment, param, gross_amount: data.gross_amount }} />
      )}
      <Button active onClick={() => handleSubmit()}>
        Selesai
      </Button>
    </CustomModal>
  );
}

export default FaypayPaymentModalView;
