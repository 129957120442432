import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import InputImage from "../components/CustomComponent/InputImage";
import { Button, CCurrencyInput, CDropdown, Input } from "../components/CustomComponent/StyledComponent";
import CreateProductLoading from "../components/Loading/CreateProductLoading";
import CourierFormView from "../components/ProfileSeller/SellerProductCreate/CourierFormView";
import EtalaseInputView from "../components/ProfileSeller/SellerProductCreate/EtalaseInputView";
import VarianFormView from "../components/ProfileSeller/SellerProductCreate/VarianComponent/VarianFormView";
import SelllerImageCropModalView from "../components/ProfileSeller/SellerProfile/SelllerImageCropModalView";
import { getProductDetail } from "../controller/homeController";
import { addUpdateProduct, getCategoryList, getCourierList, updateProductActive } from "../controller/sellerProductController";
import style from "../css/sellerProfile/createProduct/sellerProductForm.module.css";
import { BASE_URL_IMG, regexProductName } from "../utils/Constants";
import { COURIER_RADIUS, LIST_PRODUCT_SMENU } from "../utils/ConstantSeller";
import { globalStorage } from "../utils/Storage";
import { getProductEtalaseList, getProductSubEtalaseList } from "../controller/productEtalaseController";
import SubEtalaseInputView from "../components/ProfileSeller/SellerProductCreate/SubEtalaseInputView";

const intiError = {
  err_img: "",
  err_name: "",
  err_category: "",
  err_etalase: "",
  err_varian: "",
  err_price: "",
  err_stock: "",
  err_weight: "",
  err_min_order: "",
  err_self_courier: "",
  err_pre_order: "",
}
const initState = {
  id: undefined,
  name: "",
  price: 0,
  stock: 0,
  discount: 0,
  weight: 0,
  height: "",
  width: "",
  length: "",
  min_order: 1,
  sku: "",
  etalase: "",
  sub_etalase: "",
  description: "",
  category: undefined,
  pre_order: "0",
  pre_order_time: 0,
  varian: [],
  varian_group: [],
  photo1: null,
  photo2: null,
  photo3: null,
  photo4: null,
  photo5: null,

  prev_photo1: null,
  prev_photo2: null,
  prev_photo3: null,
  prev_photo4: null,
  prev_photo5: null,

  video_link1: "",
  video_link2: "",
  video_link3: "",

  // kurir
  self_pickup: "0",
  self_courier: "0",
  selected_radius: undefined,
  self_courier_radius: 0,
  self_courier_price: 0,
  is_custom_courier: "0",
  is_shown_mitra: 0,
  custom_courier: [],

  // crop
  crop_for: "",
  crop_img: undefined,
  crop_toggle: false,

  // param
  courier_list: [],
  category_list: [],
  etalase_list: [],
  sub_etalase_list: [],

  //err
  ...intiError,

  // other
  is_loading: false,
  varian_toggle: false,
};

function ProductSellerForm(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const param = useParams();
  const isEdit = location.pathname.indexOf("edit") > -1;
  const userData = globalStorage.getItem("UD");
  const storeData = userData.seller;
  const [state, setState] = useState(initState);
  const { is_loading, varian_toggle } = state;

  // variable
  const is_private = storeData.is_private === 1;
  const is_mitra_store = storeData.is_mitra === 1;
  const self_pickup_flag = state.self_pickup === "1";
  const shown_mitra_flag = parseInt(state.is_shown_mitra) === 1;
  const self_courier_flag = state.self_courier === "1";
  const pre_order_flag = state.pre_order === "1";
  const no_courier_flag = state.is_custom_courier === "1" && state.custom_courier === null;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeName = (event) => {
    const { name, value } = event.target;
    if (regexProductName.test(value) || value === "") {
      const data = { [name]: value.replaceAll('<script', ""), [`err_${name}`]: "" };
      changeState(data);
    }
  };
  const changeNumber = (value, name, values) => {
    const data = { [name]: value ?? 0, [`err_${name}`]: "" };
    changeState(data);
  };
  const changeVideoLink = (event) => {
    const { name, value } = event.target;
    changeState({ [name]: value });
  }
  const changeRadioBtn = (event) => {
    const { name, value } = event.target;
    const state_name = name === "no_courier" ? "is_custom_courier" : name;
    const data = { [state_name]: value, [`err_${state_name}`]: "" };
    if (name === "self_courier") {
      data.selected_radius = undefined;
      data.self_courier_price = 0;
      data.self_courier_radius = 0;
    }

    if (name === "pre_order") data.pre_order_time = 0;
    if (name === "is_custom_courier") data.custom_courier = [];
    if (name === "no_courier") data.custom_courier = null;
    changeState(data);
  };
  const handleSelectCategory = (category) => {
    changeState({ category, err_category: "" });
  };
  const handleSelectRadius = (selected_radius) => {
    const self_courier_radius = selected_radius.value;
    changeState({ selected_radius, self_courier_radius });
  };
  const handleChangeEtalase = (etalase) => {
    const sub_etalase_list = etalase.sub_etalase;
    changeState({ etalase, sub_etalase_list, sub_etalase: "", err_etalase: "" });
  };
  const handleChangeSubEtalase = (sub_etalase) => {
    changeState({ sub_etalase });
  };
  const handleChangeCustomeCourier = (custom_courier) => {
    changeState({ custom_courier });
  };
  // varian
  const handleVarianBtn = (varian_toggle = !state.varian_toggle) => {
    const varian_data = { varian_toggle, varian: [], varian_group: [] };
    changeState({ ...varian_data, price: 0, stock: 0, sku: "" });
  };
  const handleChangeVarian = (varian, varian_group) => {
    changeState({ varian, varian_group, err_varian: "" });
  };
  // crop function
  const changeImage = (event) => {
    const crop_img = event.target.files[0];
    const crop_for = event.target.name;
    const crop_toggle = true;
    changeState({ crop_img, crop_toggle, crop_for });
  };
  const deleteImage = (name) => {
    changeState({ [name]: null, [`prev_${name}`]: null });
  };
  const handleCropImg = async (img_res) => {
    const img = img_res?.img ?? state[state.crop_for];
    const prev_img = img_res?.img_url ?? state[state.crop_for];
    changeState({
      crop_img: undefined,
      crop_toggle: false,
      crop_for: "",
      [state.crop_for]: img,
      [`prev_${state.crop_for}`]: prev_img,
    });
  };
  // ////////////////////////////////////////
  const confirmMessage = async (title, text, confirm_text = "") => {
    return Swal.fire({
      title: title,
      text: text,
      cancelButtonText: "Batal",
      showCancelButton: true,
      confirmButtonText: confirm_text,
      confirmButtonColor: "var(--main_color)",
      cancelButtonColor: "#d33",
    }).then((res) => res);
  };

  // product
  const submitValidation = () => {
    let temp = true;
    const err = { ...intiError };
    const {
      photo1, category, etalase, name, price, varian_toggle, pre_order_time,
      self_courier_price, self_courier_radius,
    } = state;// stock,
    const empty = [name, photo1, category, etalase].includes("");
    const min_odr = state.min_order === "" || parseInt(state.min_order) === 0;
    const _price = (price === "" || parseInt(price) === 0) && !varian_toggle;
    // const _stock = (stock === "" || parseInt(stock) === 0) && !varian_toggle;
    const weight = state.weight === "" || parseInt(state.weight) === 0;
    const sc_price = parseInt(self_courier_price) === 0;
    const sc_rad = parseInt(self_courier_radius) === 0;
    const self_courier = parseInt(state.self_courier) === 1 && (sc_price || sc_rad);
    const pod_time = pre_order_time === "" || parseInt(pre_order_time) === 0;
    const pre_odr = parseInt(state.pre_order) === 1 && pod_time;
    const nameFlag = name !== "" && name.length > 200;
    const valid_varian = state.varian.find(
      // (d) => parseInt(d.price) === 0 || parseInt(d.stock) === 0
      (d) => parseInt(d.price) === 0
    );
    const varian = varian_toggle && (valid_varian !== undefined || state.varian.length === 0);
    if (photo1 === null || empty || min_odr || _price || weight || self_courier || pre_odr || varian || nameFlag) {
      // _stock ||
      temp = false;
    }
    if (photo1 === null) err.err_img = "Minimal terdapat 1 gambar produk.";
    if (name === "") err.err_name = "Nama produk tidak boleh kosong.";
    if (nameFlag) err.err_name = "Nama produk tidak boleh lebih dari 200 karakter.";
    // if (category === undefined) {
    //   err.err_category = "Kategori produk tidak boleh kosong.";
    // }
    if (etalase === "") err.err_etalase = "Etalase produk tidak boleh kosong.";
    if (varian) { err.err_varian = "Pastikan data varian sudah terisi dengan lengkap."; }
    if (_price) err.err_price = "Harga produk tidak boleh 0 atau kosong";
    // if (_stock) err.err_stock = "Stok produk tidak boleh 0 atau kosong";
    if (weight) err.err_weight = "Berat produk tidak boleh 0 atau kosong";
    if (self_courier) { err.err_self_courier = "Pastikan harga dan radius wilah telah terisi"; }
    if (pre_odr) err.err_pre_order = "Jumlah hari pre-order tidak boleh 0";

    changeState(err);
    return temp;
  };

  const showMsg = (icon, text) => {
    return Swal.fire({
      icon, text,
      didOpen: () => Swal.hideLoading(),
      confirmButtonColor: "var(--main_color)"
    });
  }

  const handleSubmit = async () => {
    const valid = submitValidation();
    if (!valid) { return showMsg("error", "Silahkan lengkapi atau perbaiki data produk") }

    Swal.fire({ didOpen: () => Swal.showLoading(), allowOutsideClick: false });
    const res = await addUpdateProduct({ ...state, is_private });
    if (!res) { return showMsg("error", "Gagal menambah produk, silahkan coba lagi.") }
    return showMsg("success", "Data berhasil disimpan")
      .then(() => navigate(`/seller_dashboard/list_product`))
  };

  const handleCancel = async () => {
    const title = "Batal Edit?";
    const text = "Data baru tidak akan hilang";
    const flag = (await confirmMessage(title, text, "Ok")).value;
    if (flag) navigate(`/seller_dashboard/${LIST_PRODUCT_SMENU}`);
  };
  const handleDelete = async () => {
    const title = "Hapus Produk?";
    const text = "Jika dihapus, data produk akan hilang.";
    const flag = (await confirmMessage(title, text, "Ya, hapus")).value;
    if (!flag) return;

    Swal.fire({ didOpen: () => Swal.showLoading(), allowOutsideClick: false });
    const res = await updateProductActive(state.id, 2);
    if (!res) { return showMsg("error", res.errors[0]) }
    return showMsg("success", `Berhasil dihapus !`)
      .then(() => navigate("/seller_dashboard/list_product"))
  };

  const initPage = async () => {
    changeState({ is_loading: true });
    const [courier_list, category_list, etalase_list] = await Promise.all([
      getCourierList(), getCategoryList(), getProductEtalaseList(1)
    ])
    const data = { is_loading: false, courier_list, category_list, etalase_list, sub_etalase_list: [] };
    const flag = Object.keys(param).length !== 0;
    if (flag) {
      const product = await getProductDetail(param.product_id);
      const sub_etalase_list = is_private ? await getProductSubEtalaseList(product.etalase_id) : [];
      const sellerId = product.seller_id;
      const b_img_link = `${BASE_URL_IMG}100/100/${sellerId}/`;
      const cat_p = category_list.find((d) => d.id === product.category_id);
      const photo1 = product.photo[0]?.name ?? null;
      const photo2 = product.photo[1]?.name ?? null;
      const photo3 = product.photo[2]?.name ?? null;
      const photo4 = product.photo[3]?.name ?? null;
      const photo5 = product.photo[4]?.name ?? null;

      const courierFlag = product.custom_courier && product.custom_courier !== "";
      const custom_courier = courierFlag ? JSON.parse(product.custom_courier) : [];
      data.id = isEdit ? param.product_id : undefined;
      data.name = product.name;
      data.category = cat_p;
      data.etalase = { id: product.etalase_id ?? "", name: product.etalase_name ?? "" };
      data.sub_etalase_list = sub_etalase_list ?? [];
      data.sub_etalase = sub_etalase_list.find(data => data.id === product.sub_etalase_id)
      data.description = product.description;
      data.varian = product.varian;
      data.varian_group = product.group ?? [];
      data.varian_toggle = product.varian.length > 0;
      data.price = product.price;
      data.stock = product.stock ?? 0;
      data.discount = product.discount;
      data.weight = product.weight;
      data.height = product.height;
      data.width = product.width;
      data.length = product.length;
      data.min_order = product.minimum_order;
      data.sku = product.sku ?? "";
      data.pre_order = `${product.is_po}`;
      data.pre_order_time = product.po_expired_time;
      // photo
      data.photo1 = photo1;
      data.photo2 = photo2;
      data.photo3 = photo3;
      data.photo4 = photo4;
      data.photo5 = photo5;
      data.prev_photo1 = photo1 ? `${b_img_link}${photo1}` : null;
      data.prev_photo2 = photo2 ? `${b_img_link}${photo2}` : null;
      data.prev_photo3 = photo3 ? `${b_img_link}${photo3}` : null;
      data.prev_photo4 = photo4 ? `${b_img_link}${photo4}` : null;
      data.prev_photo5 = photo5 ? `${b_img_link}${photo5}` : null;
      data.video_link1 = product?.external_video?.[0] ?? "";
      data.video_link2 = product?.external_video?.[1] ?? "";
      data.video_link3 = product?.external_video?.[2] ?? "";
      // kurir
      data.self_pickup = `${product.self_pickup}`;
      data.self_courier = `${product.self_courier}`;
      data.selected_radius = COURIER_RADIUS.find(
        (d) => d.value === product.self_courier_radius
      );
      data.self_courier_radius = product.self_courier_radius;
      data.self_courier_price = product.self_courier_price;
      data.is_custom_courier = `${product.is_custom_courier}`;
      data.custom_courier = custom_courier;
      data.is_shown_mitra = parseInt(product.is_shown_mitra);
    }
    changeState(data);
  };

  // useEffect
  useEffect(() => initPage(), [location]);

  // view
  const list_photo = () => {
    const list_photo = [];
    for (let i = 0; i < 5; i++) {
      list_photo.push(
        <InputImage
          key={`photo${i + 1}`}
          name={`photo${i + 1}`}
          btn_text={"Pilih Gambar"}
          img_link={state[`prev_photo${i + 1}`]}
          changeImage={changeImage}
          deleteImage={deleteImage}
        />
      );
    }
    return list_photo;
  };

  const videoLink = () => {
    const list_link = [];
    for (let i = 0; i < 3; i++) {
      const name = `video_link${i + 1}`;
      list_link.push(
        <Input key={name} name={name} value={state[name]}
          onChange={(e) => changeVideoLink(e)}
        />
      )
    }
    return list_link;
  }

  return (
    <>
      {state.crop_toggle && (
        <SelllerImageCropModalView
          toggle={state.crop_toggle}
          title={"Gambar Produk"}
          baseImg={state.crop_img}
          popupHandler={handleCropImg}
          _aspect={1}
          size={100}
        />
      )}
      <div className={style.container}>
        {is_loading && <CreateProductLoading />}
        {!is_loading && (
          <>
            <div className={style.part_container}>
              <label className={style.title}>Upload Foto Produk</label>
              <div className={style.photo_container}>{list_photo()}</div>
              {state.err_img !== "" && (
                <label className={style.error}>{state.err_img}</label>
              )}
            </div>
            <div className={style.part_container}>
              <label className={style.title}>Detail Produk</label>
              <div className={style.input_container}>
                <div className={`${style.left} ${style.input_title}`}>
                  Nama Produk
                </div>
                <div className={style.right}>
                  <Input
                    name="name"
                    value={state.name}
                    onChange={(e) => changeName(e)}
                    maxLength={200}
                  />
                  {state.err_name !== "" && (
                    <label className={style.error}>{state.err_name}</label>
                  )}
                </div>
              </div>
              <div className={style.input_container}>
                <div className={`${style.left} ${style.input_title}`}>
                  Kategori
                </div>
                <div className={style.right}>
                  <CDropdown
                    bg_color={"white"}
                    color="black"
                    className={style.dropdown}
                  >
                    <CDropdown.Toggle id="dropdown-autoclose-true">
                      <span className={style.selected_text}>
                        {state.category?.name ?? "Pilih Kategori"}
                      </span>
                    </CDropdown.Toggle>
                    <CDropdown.Menu>
                      {state.category_list.map((kat, idx) => {
                        return (
                          <CDropdown.Item
                            key={`kat_${idx}`}
                            onClick={() => handleSelectCategory(kat)}
                          >
                            {kat.name}
                          </CDropdown.Item>
                        );
                      })}
                    </CDropdown.Menu>
                  </CDropdown>
                  {state.err_category !== "" && (
                    <label className={style.error}>{state.err_category}</label>
                  )}
                </div>
              </div>
              <div className={style.input_container}>
                <div className={`${style.left} ${style.input_title}`}>
                  Etalase
                </div>
                <div className={style.right}>
                  <EtalaseInputView
                    is_private={is_private}
                    value={state.etalase}
                    list={state.etalase_list}
                    onChange={handleChangeEtalase}
                  />
                  {state.err_etalase !== "" && (
                    <label className={style.error}>{state.err_etalase}</label>
                  )}
                </div>
              </div>
              {is_private && (
                <div className={style.input_container}>
                  <div className={`${style.left} ${style.input_title}`}>
                    Sub Etalase
                  </div>
                  <div className={style.right}>
                    <SubEtalaseInputView
                      value={state.sub_etalase}
                      list={state.sub_etalase_list}
                      onChange={handleChangeSubEtalase}
                    />
                  </div>
                </div>
              )}
              <div className={style.input_container}>
                <div className={`${style.left}`}>
                  <label className={style.input_title}>Deskripsi Produk</label>
                  <p className={style.note}>
                    Pastikan deskripsi produk memuat spesifikasi, ukuran, bahan,
                    masa berlaku, dan lainnya. Semakin detail, semakin berguna
                    bagi pembeli
                  </p>
                </div>
                <div className={`${style.right} ${style.input_textarea}`}>
                  <textarea
                    name="description"
                    maxLength={3000}
                    value={state.description}
                    onChange={(e) =>
                      changeState({ description: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className={style.input_container}>
                <div className={`${style.left} ${style.input_title}`}>
                  Video Link
                </div>
                <div className={`${style.right} ${style.video_container}`}>
                  {videoLink()}
                </div>
              </div>
            </div>
            <div className={`${style.note_container} ${style.warning}`}>
              <i className="bi bi-exclamation-triangle" />
              <div>
                <span>
                  Akan dikenakan potongan sesuai dengan metode pembayaran yang
                  dipilih.
                </span>
                <br />
                <span>- Transfer akan dikenakan biaya Rp. 5.000,00.</span>
                <br />
                <span>
                  - E-wallet akan dikenakan biaya 2% dari nominal transaksi.
                </span>
              </div>
            </div>
            <div className={style.part_container}>
              <label className={style.title}>Varian Produk</label>
              <div className={style.varian_btn_container}>
                <div className={style.note}>
                  <label>
                    Tambahkan varian produk, seperti warna, ukuran atau lainnya.
                  </label>
                  <label>{`*) Isi SKU varian bila ada / dibutuhkan`} </label>
                </div>
                <Button
                  active={!varian_toggle}
                  onClick={() => handleVarianBtn()}
                >
                  {`${!varian_toggle ? "Tambah" : "Hapus"} Varian`}
                </Button>
              </div>
              <div className={style[varian_toggle ? "visible" : "hide"]}>
                <VarianFormView
                  varian={state.varian}
                  varian_group={state.varian_group}
                  toggle={varian_toggle}
                  handleChange={handleChangeVarian}
                />
              </div>
              {state.err_varian !== "" && (
                <label className={style.error}>{state.err_varian}</label>
              )}
            </div>
            <div className={style.part_container}>
              <label className={style.title}>Pengolahan Produk</label>
              {!state.varian_toggle && (
                <div className={style.input_container}>
                  <div className={`${style.left}`}>
                    <div className={` ${style.input_title}`}>Harga Satuan</div>
                    <p className={style.note}>Harga satuan dari produk Anda</p>
                  </div>
                  <div className={`${style.right}`}>
                    <div className={`${style.price_container}`}>
                      <span>Rp.</span>
                      <CCurrencyInput name="price" value={state.price} onValueChange={changeNumber}
                        onFocus={e => e.target.select()}
                      />
                    </div>
                    {state.err_price !== "" && (
                      <label className={style.error}> {state.err_price}</label>
                    )}
                  </div>
                </div>
              )}
              <div className={style.input_container}>
                <div className={`${style.left}`}>
                  <div className={` ${style.input_title}`}>
                    Minimum Pemesanan
                  </div>
                  <p className={style.note}>
                    Jumlah minimum yang harus dibeli untuk produk ini
                  </p>
                </div>
                <div className={`${style.right}`}>
                  <CCurrencyInput name="min_order" value={state.min_order} onValueChange={changeNumber}
                    onFocus={e => e.target.select()}
                  />
                  {state.err_min_order !== "" && (
                    <label className={style.error}>{state.err_min_order}</label>
                  )}
                </div>
              </div>
              {!state.varian_toggle && (
                <>
                  <div className={style.input_container}>
                    <div className={`${style.left} ${style.input_title}`}>
                      Stok Produk
                    </div>
                    <div className={`${style.right}`}>
                      <CCurrencyInput name="stock" value={state.stock} onValueChange={changeNumber}
                        onFocus={e => e.target.select()}
                      />
                      {state.err_stock !== "" && (
                        <label className={style.error}>{state.err_stock}</label>
                      )}
                    </div>
                  </div>
                  <div className={style.input_container}>
                    <div className={`${style.left} ${style.input_title}`}>
                      SKU
                    </div>
                    <div className={style.right}>
                      <Input
                        name="sku"
                        placeholder="Stock Keeping Unit"
                        value={state.sku}
                        onChange={(e) => changeName(e)}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className={style.input_container}>
                <div className={`${style.left} ${style.input_title}`}>
                  Diskon
                </div>
                <div className={`${style.right} ${style.discount}`}>
                  <CCurrencyInput name="discount" value={state.discount} onValueChange={changeNumber}
                    maxLength={2}
                    onFocus={e => e.target.select()}
                  />
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.part_container}>
              <label className={style.title}>Pengaturan Kurir Produk</label>
              <div className={style.input_container}>
                <div className={`${style.left}`}>
                  <label className={style.input_title}>Berat Produk</label>
                  <p className={style.note}>
                    Tulis berat produk Anda akan mempengaruhi harga pengiriman.
                    Perhatikan dengan baik berat produk agar tidak terjadi
                    selisih data dengan pihak kurir.
                  </p>
                </div>
                <div className={`${style.right}`}>
                  <div className={` ${style.discount}`}>
                    <CCurrencyInput name="weight" value={state.weight} onValueChange={changeNumber}
                      onFocus={e => e.target.select()}
                    />
                    <span>gr</span>
                  </div>
                  {state.err_weight !== "" && (
                    <label className={style.error}> {state.err_weight}</label>
                  )}
                </div>
              </div>
              <div className={style.input_container}>
                <div className={`${style.left}`}>
                  <label className={style.input_title}>
                    Ukuran / Volume Produk
                  </label>
                  <p className={style.note}>
                    Masukan ukuran produk setelah dikemas untuk menghitung berat
                    volume
                  </p>
                </div>
                <div className={`${style.right} ${style.volume_container}`}>
                  <div className={`${style.discount}`}>
                    <CCurrencyInput name="length" value={state.length} onValueChange={changeNumber}
                      onFocus={e => e.target.select()}
                    />
                    <span>cm</span>
                  </div>
                  <div className={`${style.discount}`}>
                    <CCurrencyInput name="width" value={state.width} onValueChange={changeNumber}
                      onFocus={e => e.target.select()}
                    />
                    <span>cm</span>
                  </div>
                  <div className={`${style.discount}`}>
                    <CCurrencyInput name="height" value={state.height} onValueChange={changeNumber}
                      onFocus={e => e.target.select()}
                    />
                    <span>cm</span>
                  </div>
                </div>
              </div>
              <div className={style.input_container}>
                <div className={`${style.left}`}>
                  <label className={style.input_title}>
                    Layanan Pengiriman
                  </label>
                  <p className={style.note}>
                    Atur layanan pengiriman sesuai jenis produk Anda
                  </p>
                </div>
                <div className={`${style.right} ${style.radio_btn_container}`}>
                  <div className={style.radio_input}>
                    <input
                      type="radio"
                      value={"0"}
                      id="custom_courier"
                      name="is_custom_courier"
                      className="form-check-input"
                      checked={state.is_custom_courier === "0"}
                      onChange={(e) => changeRadioBtn(e)}
                    />
                    <label htmlFor="custom_courier">Standar</label>
                  </div>
                  <div className={style.radio_input}>
                    <input
                      type="radio"
                      value={"1"}
                      id="custom_courier1"
                      name="is_custom_courier"
                      className="form-check-input"
                      checked={
                        state.is_custom_courier === "1" &&
                        state.custom_courier !== null
                      }
                      onChange={(e) => changeRadioBtn(e)}
                    />
                    <label htmlFor="custom_courier1">Custom</label>
                  </div>
                  <div className={style.radio_input}>
                    <input
                      type="radio"
                      value={"1"}
                      id="custom_courier2"
                      name="no_courier"
                      className="form-check-input"
                      checked={
                        state.is_custom_courier === "1" &&
                        state.custom_courier === null
                      }
                      onChange={(e) => changeRadioBtn(e)}
                    />
                    <label htmlFor="custom_courier2">Tanpa Ekspedisi</label>
                  </div>
                </div>
              </div>
              {!no_courier_flag && (
                <div
                  className={
                    style[state.is_custom_courier === "1" ? "visible" : "hide"]
                  }
                >
                  <CourierFormView
                    list={state.courier_list}
                    value={state.custom_courier ?? []}
                    handleChange={handleChangeCustomeCourier}
                  />
                </div>
              )}

              <div className={style.input_container}>
                <div className={`${style.left}`}>
                  <label className={style.input_title}>
                    Layanan Kurir Internal
                  </label>
                  {self_courier_flag && (
                    <p className={style.note}>
                      *Tentukan jarak dan harga pengiriman jika menggunakan
                      kurir internal
                    </p>
                  )}
                </div>
                <div className={`${style.right}`}>
                  <div className={`${style.radio_btn_container}`}>
                    <div className={style.radio_input}>
                      <input
                        type="radio"
                        value={"1"}
                        id="self_courier"
                        name="self_courier"
                        className="form-check-input"
                        checked={self_courier_flag}
                        onChange={(e) => changeRadioBtn(e)}
                      />
                      <label htmlFor="self_courier">Aktif</label>
                    </div>
                    <div className={style.radio_input}>
                      <input
                        type="radio"
                        value={"0"}
                        id="self_courier1"
                        name="self_courier"
                        className="form-check-input"
                        checked={!self_courier_flag}
                        onChange={(e) => changeRadioBtn(e)}
                      />
                      <label htmlFor="self_courier1">Non-Aktif</label>
                    </div>
                  </div>
                  {self_courier_flag && (
                    <div className={style.radius_container}>
                      <div className={`${style.price_container}`}>
                        <span>Rp.</span>
                        <CCurrencyInput name="self_courier_price" value={state.self_courier_price}
                          onValueChange={changeNumber}
                          onFocus={e => e.target.select()}
                        />
                      </div>
                      <CDropdown
                        bg_color={"white"}
                        color="black"
                        className={style.dropdown}
                      >
                        <CDropdown.Toggle id="dropdown-autoclose-true">
                          {state.selected_radius?.label ?? "Radius"}
                        </CDropdown.Toggle>
                        <CDropdown.Menu>
                          {COURIER_RADIUS.map((rad, idx) => {
                            return (
                              <CDropdown.Item
                                key={`rad_${idx}`}
                                onClick={() => handleSelectRadius(rad)}
                              >
                                {rad.label}
                              </CDropdown.Item>
                            );
                          })}
                        </CDropdown.Menu>
                      </CDropdown>
                    </div>
                  )}
                  {state.err_self_courier !== "" && (
                    <label className={style.error}>
                      {state.err_self_courier}
                    </label>
                  )}
                </div>
              </div>
              <div className={style.input_container}>
                <div className={`${style.left}`}>
                  <label className={style.input_title}>
                    Layanan Ambil Sendiri
                  </label>
                </div>
                <div className={`${style.right}`}>
                  <div className={`${style.radio_btn_container}`}>
                    <div className={style.radio_input}>
                      <input
                        type="radio"
                        value={"1"}
                        id="self_pickup"
                        name="self_pickup"
                        className="form-check-input"
                        checked={self_pickup_flag}
                        onChange={(e) => changeRadioBtn(e)}
                      />
                      <label htmlFor="self_pickup">Aktif</label>
                    </div>
                    <div className={style.radio_input}>
                      <input
                        type="radio"
                        value={"0"}
                        id="self_pickup1"
                        name="self_pickup"
                        className="form-check-input"
                        checked={!self_pickup_flag}
                        onChange={(e) => changeRadioBtn(e)}
                      />
                      <label htmlFor="self_pickup1">Non-Aktif</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.input_container}>
                <div className={`${style.left}`}>
                  <label className={style.input_title}>Preorder</label>
                  <p className={style.note}>
                    Aktifkan PreOrder jika Anda butuh waktu proses pengiriman
                    lebih lama
                  </p>
                </div>
                <div className={`${style.right} ${style.radio_btn_container}`}>
                  <div className={style.radio_input}>
                    <input
                      type="radio"
                      value={"1"}
                      id="pre_order"
                      name="pre_order"
                      className="form-check-input"
                      checked={pre_order_flag}
                      onChange={(e) => changeRadioBtn(e)}
                    />
                    <label htmlFor="pre_order">Aktif</label>
                  </div>
                  <div className={style.radio_input}>
                    <input
                      type="radio"
                      value={"0"}
                      id="pre_order1"
                      name="pre_order"
                      className="form-check-input"
                      checked={!pre_order_flag}
                      onChange={(e) => changeRadioBtn(e)}
                    />
                    <label htmlFor="pre_order1">Non-Aktif</label>
                  </div>
                </div>
              </div>
              {pre_order_flag && (
                <div className={style.input_container}>
                  <div className={`${style.left} ${style.input_title}`}>
                    Waktu Preorder
                  </div>
                  <div className={`${style.right} `}>
                    <div className={`${style.discount}`}>
                      <CCurrencyInput name="pre_order_time" value={state.pre_order_time}
                        onValueChange={changeNumber}
                        onFocus={e => e.target.select()}
                      />
                      <span>hari</span>
                    </div>
                    {state.err_pre_order !== "" && (
                      <label className={style.error}>
                        {state.err_pre_order}
                      </label>
                    )}
                  </div>
                </div>
              )}
              {is_mitra_store && (
                <div className={style.input_container}>
                  <div className={`${style.left}`}>
                    <label className={style.input_title}>
                      Tampilkan pada
                    </label>
                  </div>
                  <div className={`${style.right}`}>
                    <div className={`${style.radio_btn_container}`}>
                      <div className={style.radio_input}>
                        <input
                          type="radio"
                          value={1}
                          id="is_shown_mitra"
                          name="is_shown_mitra"
                          className="form-check-input"
                          checked={shown_mitra_flag}
                          onChange={(e) => changeRadioBtn(e)}
                        />
                        <label htmlFor="is_shown_mitra">Aplikasi Mobile Mitra</label>
                      </div>
                      <div className={style.radio_input}>
                        <input
                          type="radio"
                          value={0}
                          id="is_shown_mitra1"
                          name="is_shown_mitra"
                          className="form-check-input"
                          checked={!shown_mitra_flag}
                          onChange={(e) => changeRadioBtn(e)}
                        />
                        <label htmlFor="is_shown_mitra1">Aplikasi Mobile Mitra dan 1Toko</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={style.btn_container}>
              {isEdit && (
                <>
                  <Button active onClick={() => handleCancel()}>
                    BATAL
                  </Button>
                  <Button active onClick={() => handleDelete()}>
                    HAPUS
                  </Button>
                </>
              )}
              <Button active onClick={() => handleSubmit()}>
                SIMPAN
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ProductSellerForm;
