import React from "react";
import style from "../../../css/sellerProfile/createProduct/sellerProductCourier.module.css";

function CourierFormView({ list, value, handleChange }) {
  const key_list = Object.keys(list);
  // function
  const checkSelected = (courier) => {
    let temp = false;
    if (value.length === 0) temp = true;
    else temp = JSON.stringify(value).includes(courier.id.toString()) ? true : false;
    return temp;
  };

  const handleSelect = (c) => {
    if (JSON.stringify(value).includes(c.id.toString())) {
      value = value.filter((d) => parseInt(d) !== parseInt(c.id));
    } else value.push(parseInt(c.id));
    handleChange(value);
  };

  return (
    <div className={style.container}>
      {key_list.length !== 0 &&
        key_list.map((courier_key, idx) => {
          const courier_list = list[courier_key];
          const c_key = `courier_key${idx}`;
          return (
            <div key={c_key} className={style.container_group}>
              <label className={style.key_title}>{courier_key}</label>
              <div className={style.courier_container}>
                {courier_list.map((c, idx) => {
                  const key = `courier${idx}`;
                  const flag = checkSelected(c);
                  const active = flag ? style.active : "";
                  return (
                    <div
                      key={key}
                      className={`${style.card_container} ${active}`}
                      onClick={() => handleSelect(c)}
                    >
                      {c.name}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default CourierFormView;
