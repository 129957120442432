import dayjs from "dayjs";
import { APIPayment } from "../utils/API";
import {
  TRANS_CANCELED,
  TRANS_FINISHED,
  TRANS_PACKED,
  TRANS_PENDING,
  TRANS_SENT,
} from "../utils/ConstantUser";

export const getTransactionData = async (status, from_date, to_date, cancel) => {
  let res = [];
  if (status === TRANS_PENDING) {
    res = await getPendingList(cancel);
  } else {
    let stat = "";
    if (status === TRANS_PACKED) stat = "process";
    if (status === TRANS_SENT) stat = "delivery";
    if (status === TRANS_FINISHED) stat = "complete";
    if (status === TRANS_CANCELED) stat = "cancel";
    if (stat !== "") res = await getTransactionList(stat, from_date, to_date, cancel);
  }
  return res;
};

// call Api
const getPendingList = async (cancel = null) => {
  return APIPayment.get(`transaction/pending`, {}, { signal: cancel?.signal ?? null })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getTransactionList = async (status, from_date, to_date, cancel = null) => {
  const post = new FormData();
  post.append("status", status);
  post.append("start_date", dayjs(from_date).format("YYYY-MM-DD"));
  post.append("end_date", dayjs(to_date).format("YYYY-MM-DD"));
  return APIPayment.post(`status/transaction`, post, { signal: cancel?.signal ?? null })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};