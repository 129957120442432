import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function MidtransPage(props) {
  const param = useParams();
  const initPage = () => {
    const token = param.token;
    window.snap.pay(token, {
      onSuccess: function (result) { },
      onPending: function (result) { },
      onError: function (result) { },
      onClose: function () { },
      gopayMode: "qr",
    });
  };
  useEffect(() => initPage(), [param.token]);

  return <div></div>;
}

export default MidtransPage;
