import Swal from "sweetalert2";
import { APICourier, APITrans, APIAuth, APIPayment } from "../utils/API";

// function
export const notAbleForTransaction = (detail_list) => {
  const temp = detail_list.find((i) => {
    const { product, varian } = i;
    const stock = parseInt(varian ? varian.stock : product.stock);
    const flag_out_stock = product.out_of_stock === 1 || stock === 0;
    const flag_low_stock = stock < product.minimum_order;
    const flag_buy = !flag_out_stock && !flag_low_stock;
    return i.is_checked === 0 || !flag_buy;
  });
  return temp ? false : true;
};

export const checkClosedStore = (list = []) => {
  const temp = list.find((s) => s.seller.is_open === 2);
  return temp ? true : false;
};

export const addToCart = async (data) => {
  Swal.fire({
    didOpen: () => Swal.showLoading(),
    allowOutsideClick: false,
  });
  const res = await addUpdateCart(data);
  Swal.close();
  if (res) {
    Swal.fire({
      icon: "success",
      text: `Berhasil ditambahkan !`,
      allowOutsideClick: false,
      cancelButtonText: "Lihat Keranjang",
      showCancelButton: true,
      confirmButtonText: "Belanja Lagi",
      confirmButtonColor: "var(--main_color)",
      cancelButtonColor: "var(--main_color)",
    }).then((res) => {
      if (res.value) window.location = "/";
      else window.location = "/cart";
    });
  } else {
    Swal.fire({
      icon: "error",
      text: "Gagal menambahkan barang",
      confirmButtonColor: "var(--main_color)",
    });
  }
};

export const getTransItemList = (cart) => {
  let tempList = [];
  if (cart.length > 0) {
    cart.forEach((cart) => {
      const { code, courier_name, courier_service } = cart.courier_detail;
      const flag = !["self_pickup", "self_courier"].includes(code);
      let service = courier_service.replaceAll(`${courier_name}`, "");
      service = service.split("-")[0];
      const courier = `${courier_name}${flag ? ` ( ${service} )` : ""}`;
      const seller = cart.seller;
      cart.detail_checked.forEach((data) => {
        const { varian, product } = data;
        const id = varian ? varian.id : product.id;
        const price = varian ? varian.fix_price : product.fix_price;
        const name = `${product.name} ${varian?.name1 ?? ""} ${varian?.name2 ?? ""
          }`;
        const note = data.note;
        const qty = data.qty;
        let img = varian ? varian.image : product.photo[0].name;
        if (img === "" && product.photo[0].name !== "")
          img = product.photo[0].name;
        const _product = { id, img, name, price, qty, note };
        tempList.push({ _product, courier, seller });
      });
    });
  }
  return tempList;
};

// call api

// cart
export const updateChecked = async (payload) => {
  const data = new FormData();
  if (payload.cart_id) data.append("cart_id", payload.cart_id);
  if (payload.dtl_cart_id) data.append("dtl_cart_id", payload.dtl_cart_id);
  data.append("check", payload.check);
  return APITrans.post(`cart/check`, data)
    .then((res) => {
      // console.log(res);
      return true;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const deleteCartItem = async (cart_id, dtl_cart_id) => {
  const data = new FormData();
  if (cart_id) data.append("cart_id", cart_id);
  if (dtl_cart_id) data.append("dtl_cart_id", dtl_cart_id);

  return APITrans.post(`cart/delete`, data)
    .then(async (res) => true)
    .catch(async (err) => {
      console.log(err);
      return false;
    });
};

export const addUpdateCart = async (payload) => {
  const data = new FormData();
  data.append("product_id", payload?.product_id);
  data.append("dtl_product_id", payload?.dtl_product_id);
  data.append("qty", payload?.qty);
  data.append("note", payload?.note);
  data.append("seller_id", payload?.seller_id);
  if (payload.action) data.append("action", payload.action);

  return APITrans.post(`cart`, data)
    .then((res) => res.data)
    .catch((err) => undefined);
};

//shipment
export const paymentOrder = async (data) => {
  const { userData, userLoc, insurance_flag } = data;
  let tempCart = JSON.parse(data.cart);
  tempCart = tempCart.map((data) => {
    const { selected_courier } = data;
    // console.log(data);
    data.courier_detail = {
      code: selected_courier.code,
      courier_name: selected_courier.courier_name,
      courier_service: selected_courier.service,
      courier_price: selected_courier.price,
      insurance_price: data.insurance,
      estimate: selected_courier.estimate,
    };
    ["selected_courier", "courier_list", "custom_courier", "insurance"].forEach(
      (key) => delete data[key]
    );
    return data;
  });

  let item_details = [];
  tempCart.forEach((data) => {
    data.detail_checked.forEach((data) => {
      const { varian, product } = data;
      const id = varian ? varian.id : product.id;
      const price = varian ? varian.fix_price : product.fix_price;
      const name = `${product.name} ${varian?.name1 ?? ""} ${varian?.name2 ?? ""}`;
      const quantity = data.qty;
      item_details.push({ id, name, price, quantity });
    });
  });

  item_details.push({
    id: `COURIER - ${Date.now()}`,
    name: `Ongkos kirim ${insurance_flag ? "+ Asuransi" : ""}`,
    price: data.total_shipment_service,
    quantity: 1,
  });

  if (data.promo) {
    item_details.push({
      id: `DISCOUNT - ${Date.now()}`,
      name: `Diskon`,
      price: parseFloat(`-${data.total_discount}`),
      quantity: 1,
    });
  }

  // console.log(userLoc);
  const sent_data = {
    transaction_details: {
      gross_amount: data.gross_amount,
      payment_type: data.payment_methode,
      total_shipment_service: data.total_shipment_service,
      promo_detail: data.promo ?? "",
      total_discount: data.total_discount,
      total_commission: data.total_commission,
    },
    cart: tempCart,
    item_details,
    customer_details: {
      id: userData.id,
      email: userData.email,
      first_name: userData.name,
      last_name: "",
      phone: userData.handphone,
      billing_address: {
        address: userLoc.address,
        city: userLoc.zip.sub_province,
        country_code: "IDN",
        email: userData.email,
        first_name: userLoc.name,
        id: userLoc.id,
        last_name: "",
        phone: userLoc.hp,
        postal_code: userLoc.zip.zip,
        // latitude: userLoc.latitude,
        // longitude: userLoc.longitude,
      },
      shipping_address: {
        address: userLoc.address,
        city: userLoc.zip.sub_province,
        country_code: "IDN",
        email: userData.email,
        first_name: userLoc.name,
        id: userLoc.id,
        last_name: "",
        phone: userLoc.hp,
        postal_code: userLoc.zip.zip,
        // latitude: userLoc.latitude,
        // longitude: userLoc.longitude,
      },
      address: userLoc,
    },
    callbacks: data.callbacks,
  };
  // console.log(sent_data);
  // console.log(JSON.stringify(sent_data));
  // return undefined;
  const res = await APIPayment.post(`transaction`, sent_data)
    .then((res) => res.data)
    .catch((err) => { console.log(err?.response?.errors); return undefined });
  return res;
};

// other
export const getCartList = async () => {
  return APITrans.get(`cart`)
    .then((res) => {
      // console.log(res.data);
      return res.data;
    })
    .catch((res) => {
      // console.log(res);
      return [];
    });
};

export const getShipmentList = async () => {
  return APITrans.get(`cart/shipment`)
    .then((res) => {
      const temp = res.data.map((data) => {
        return { ...data, selected_courier: undefined, insurance: 0 };
      });
      return temp;
    })
    .catch((res) => {
      // console.log(res);
      return [];
    });
};

const courierItemList = (list) => {
  let tempList = [];
  list.map((data) => {
    // console.log(data);
    const { product, varian } = data;
    const value = varian ? varian.price : product.price;
    const name = `${product.name} ${varian?.name1 ?? ""} ${varian?.name2 ?? ""
      }`;
    const quantity = data.qty;
    const description = "";
    const { length, width, height, weight } = product;
    tempList.push({
      name,
      description,
      value,
      length,
      width,
      height,
      weight,
      quantity,
    });
  });
  return tempList;
};

export const getCourierList = async (payload) => {
  const data = new FormData();
  data.append("origin", JSON.stringify(payload.origin));
  data.append("destination", JSON.stringify(payload.destination));
  data.append("total_weight", payload.total_weight);
  data.append("total_price", payload.total_price);
  data.append("cart", JSON.stringify(payload.cart));
  data.append("items", JSON.stringify(courierItemList(payload.items)));
  return APICourier.post(`check_price`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getPaymentList = async () => {
  return APIAuth.get(`param/payment_type`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getDetailPrint = async (invoice_id) => {
  return APIPayment.post(`status/detail`, { invoice_id })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const courierTracking = async (awb, courier) => {
  return APICourier.get(`tracking/${courier}/${awb}`)
    .then((res) => res.data.tracks)
    .catch((err) => {
      console.log(err);
      return [];
    });
};
