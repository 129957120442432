import { APIAuth } from "../utils/API";

// fucntion
export const addUpdateSellerLoc = async (data, process) => {
  let res = undefined;
  if (process === "ADD") {
    const _data = new FormData();
    _data.append("name", data.name);
    _data.append("hp", data.hp);
    _data.append("alias", data.alias);
    _data.append("zip_id", data.zip_id);
    _data.append("address", data.address);
    _data.append("longitude", data.longitude);
    _data.append("latitude", data.latitude);
    _data.append("detail", data.detail);
    res = await storeSellerLoc(_data);
  }

  if (process === "UPDATE") {
    const _data = {
      name: data.name,
      hp: data.hp,
      alias: data.alias,
      zip_id: data.zip_id.toString(),
      address: data.address,
      longitude: data.longitude,
      latitude: data.latitude,
      detail: data.detail,
    };
    res = await updateSellerLoc(_data, data.id);
  }
  return res;
};

// call API
export const getSellerLocation = async () => {
  return APIAuth.get(`sellerlocation`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return undefined;
    });
};

const storeSellerLoc = async (data) => {
  return APIAuth.post(`sellerlocation`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

const updateSellerLoc = async (data, id) => {
  return APIAuth.put(`sellerlocation/${id}`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const deleteSellerLoc = async (id) => {
  return APIAuth.delete(`sellerlocation/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};

export const setLS_AsPrimary = async (id) => {
  return APIAuth.put(`sellerlocation/setprimary/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};
