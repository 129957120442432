import React, { useEffect, useState } from "react";
import { courierTracking } from "../../controller/transactionController";
import CustomModal, { HeaderModal } from "../CustomComponent/CustomModal";
import style from "../../css/sellerProfile/sellerTransaction.module.css";
import TrackingCardLoading from "../Loading/TrackingCardLoading";

const initState = {
  list: [],
  is_loading: false,
};

function TrackingModalView(props) {
  const { courier, toggle, popupHandler } = props;
  const [state, setState] = useState(initState);
  const title = "Status Pengiriman";

  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));

  useEffect(() => {
    const initComponent = async () => {
      let list = [];
      const { awb, code } = courier;
      if (toggle) {
        changeState({ is_loading: true });
        list = await courierTracking(awb, code);
        changeState({ list, is_loading: false });
      }
    };
    initComponent();
  }, [toggle]);

  return (
    <CustomModal outsideClick toggle={toggle} modalHandler={popupHandler}>
      <HeaderModal title={title} modalHandler={popupHandler} />
      <div className={style.tracking_container}>
        {state.is_loading ? (
          <TrackingCardLoading />
        ) : (
          <>
            {state.list.length > 0 &&
              state.list
                .map((data, idx) => {
                  const icon =
                    data.status === "OK" ||
                    data.status === "DELIVERED" ||
                    data.status === "DELIVERED TO"
                      ? "bi bi-hand-thumbs-up-fill"
                      : "bi bi-check-circle-fill";
                  return (
                    <div key={`track_${idx}`} className={style.tracking_card}>
                      <i className={icon}></i>
                      <div>
                        {data.date}
                        <div className={style.tracking_text_container}>
                          <div className={style.title}>Status</div>
                          <div className={style.text}>
                            {data.status.toLowerCase()}
                          </div>
                        </div>
                        <div className={style.tracking_text_container}>
                          <div className={style.title}>Deskripsi</div>
                          <div className={style.text}>
                            {data.desc.toLowerCase()}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                .reverse()}
            {state.list.length === 0 && (
              <div>
                Barang belum diambil kurir atau pengiriman sudah tidak valid
              </div>
            )}
          </>
        )}
      </div>
    </CustomModal>
  );
}

export default TrackingModalView;
