import { useMemo } from "react";

function useDeviceDetect(triger = "") {
  const isMobile = useMemo(() => {
    const navigator = window.navigator;
    const flag = typeof navigator === "undefined";
    const userAgent = flag ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    return mobile;
  }, [triger]);
  return isMobile;
}

export default useDeviceDetect;
