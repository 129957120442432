import { Map, Marker } from "google-maps-react";
import React, { useEffect, useState } from "react";
import CustomModal, { HeaderModal } from "../CustomComponent/CustomModal";
import style from "../../css/mapPopup.module.css";
import { Button } from "../CustomComponent/StyledComponent";

const initState = {
  lat: 0,
  lng: 0,
};

function MapModalView(props) {
  const { toggle, handleToggle = null, LatLng = { lat: 0, lng: 0 } } = props;
  const [state, setState] = useState(initState);
  const { lat, lng } = state;
  const flag = LatLng.lat === 0 && LatLng.lng === 0;

  // useEffect
  useEffect(() => {
    if (!flag) changeState({ lat: LatLng.lat, lng: LatLng.lng });
  }, [LatLng, flag]);

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));

  const centerMoved = (mapProps, map) => {
    const { center } = map;
    const lat = center.lat();
    const lng = center.lng();
    changeState({ lat, lng });
  };

  const onDragend = (map, coord) => {
    const { position } = coord;
    changeState({ lat: position.lat(), lng: position.lng() });
  };

  const handelPopup = (act) => {
    let _LatLng = act === "SUBMIT" ? { lat: lat, lng: lng } : LatLng;
    handleToggle(false, _LatLng);
  };

  // view
  if (flag) return <></>;
  return (
    <CustomModal
      toggle={toggle}
      size="lg"
      modalHandler={() => handelPopup("CLOSE")}
      outsideClick
    >
      <HeaderModal
        title="Ubah Lokasi"
        modalHandler={() => handelPopup("CLOSE")}
      />
      <div className={style.map_container}>
        <Map
          google={props.google}
          zoom={17}
          initialCenter={{ lat: LatLng.lat, lng: LatLng.lng }}
          center={{ lat, lng }}
          draggable={true}
          containerStyle={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
          // function
          onDragend={centerMoved}
          // control
          fullscreenControl={false}
          streetViewControl={false}
          scaleControl={false}
          mapTypeControl={false}
        >
          <Marker
            draggable={true}
            onDragend={onDragend}
            position={{ lat, lng }}
          />
        </Map>
      </div>

      <div className={style.btn_container}>
        <Button active onClick={() => handelPopup("CLOSE")}>
          NANTI SAJA
        </Button>
        <Button active onClick={() => handelPopup("SUBMIT")}>
          KONFIRMASI
        </Button>
      </div>
    </CustomModal>
  );
}

export default MapModalView;
