import React from "react";
import Skeleton from "react-loading-skeleton";

import { InlineWrapper } from "../../controller/loadingController";
import style from "../../css/sellerProfile/sellerProfile.module.css";

function SellerProfileLoading(props) {
  const Banner = () => {
    return (
      <div className={style.banner_container}>
        <Skeleton width={120} height={15} />
        <Skeleton height={90} />
      </div>
    );
  };

  return (
    <div className={style.container}>
      <div className={style.part_container}>
        <div>
          <Skeleton width={120} height={15} />
          <Skeleton height={20} />
        </div>
        <div>
          <Skeleton width={120} height={15} />
          <Skeleton height={20} />
        </div>
      </div>
      <div className={style.change_subdomain_container}>
        <Skeleton
          height={15}
          count={2}
          wrapper={(obj) => InlineWrapper(obj, { margin: 0, padding: 0 })}
        />
        <Skeleton width={120} height={15} />
      </div>
      <div className={style.part_container}>
        {/* left part */}
        <div>
          <div className={style.small_part_container}>
            <div className={style.logo_container}>
              <Skeleton
                width={120}
                height={15}
                wrapper={(obj) =>
                  InlineWrapper(obj, { marginBottom: "0.5rem" })
                }
              />
              <Skeleton height={115} circle />
            </div>
            <div>
              <Skeleton
                width={150}
                height={15}
                wrapper={(obj) =>
                  InlineWrapper(obj, { marginBottom: "0.5rem" })
                }
              />
              <div style={{ display: "flex", alignItems: "flex-end", gap:"0.5rem" }}>
                <Skeleton width={150} height={120} />
                <Skeleton width={100} height={70} />
              </div>
            </div>
          </div>
          <div style={{ margin: "0.5rem 0" }}>
            <div className={style.group_title}>
              <Skeleton width={120} height={15} />
              <Skeleton width={60} height={15} />
            </div>
            <Skeleton height={20} />
          </div>
          <div style={{ margin: "0.5rem 0" }}>
            <div className={style.group_title}>
              <Skeleton width={120} height={15} />
              <Skeleton width={60} height={15} />
            </div>
            <Skeleton height={50} />
          </div>
          <div style={{ margin: "0.5rem 0" }}>
            <div className={style.group_title}>
              <Skeleton width={120} height={15} />
              <Skeleton width={60} height={15} />
            </div>
            <Skeleton height={70} />
          </div>
        </div>
        {/* right part */}
        <div>
          <Banner />
          <Banner />
          <Banner />
        </div>
      </div>
      <div className={style.btn_container}>
        <Skeleton width={120} height={30} />
        <Skeleton width={120} height={30} />
      </div>
    </div>
  );
}

export default SellerProfileLoading;
