import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getTransactionData } from "../../../controller/sellerTransactionController";
import style from "../../../css/sellerProfile/sellerTransaction.module.css";
import { TMS_COMPLAINT, TMS_MENU, TMS_NOT_PAID, TRANS_SMENU } from "../../../utils/ConstantSeller";
import { Button, InputDate } from "../../CustomComponent/StyledComponent";
import InvoiceCardLoading from "../../Loading/InvoiceCardLoading";
import TransactionComplaintView from "./TransactionComplaintView";
import TransactionSellerView from "./TransactionSellerView";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import id from "date-fns/locale/id";
import { globalStorage } from "../../../utils/Storage";
import TransactionPendingView from "./TransactionPendingView";

const date = dayjs();
const from_date = date.subtract(30, "day").format("YYYY-MM-DD");
const to_date = date.format("YYYY-MM-DD");
const _FDate = "dd - MM - yyyy";

const initState = {
  transList: [],
  is_loading: false,
  from_date: dayjs(from_date).toDate(),
  to_date: dayjs(to_date).toDate(),
};

function SellerTransactionMain(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = globalStorage.getItem("UD");
  const path = location.pathname.substring(1).split("/");
  const status = path[2];
  const [state, setState] = useState(initState);
  const { transList, is_loading, from_date, to_date } = state;
  const baseLink = `/seller_dashboard/${TRANS_SMENU}/`;
  const abortCtrl = new AbortController();

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeTab = async (tab) => navigate(`${baseLink}${tab}`);
  const changeDate = (date, type) => {
    const data = { [`${type}_date`]: date };
    if (type === "from") data.to_date = dayjs(date).add(30, "day").toDate();
    changeState(data);
  };
  const loadData = async () => {
    changeState({ is_loading: true });
    const seller_id = userData?.seller?.id;
    const data = [seller_id, status, from_date, to_date, abortCtrl];
    let transList = await getTransactionData(...data);
    changeState({ transList, is_loading: false });
  };

  useEffect(() => {
    if (path[1] === TRANS_SMENU) loadData();
    return () => abortCtrl.abort();
  }, [status, state.from_date, state.to_date]);

  // view
  return (
    <div className={style.container}>
      <div className={style.nav_menu}>
        {TMS_MENU.map((data, idx) => (
          <Button
            key={`trans_menu_${idx}`}
            active={!(status === data.code)}
            onClick={() => changeTab(data.code)}
          >
            {data.name}
          </Button>
        ))}
      </div>
      <div className={style.sub_header}>
        <div id={style.search}>search</div>
        <div id={style.date}>
          <span>Tanggal Transaksi</span>
          <DatePicker
            locale={id}
            dateFormat={_FDate}
            selected={from_date}
            onChange={(date) => changeDate(date, "from")}
            customInput={<InputDate />}
          />
          <span>s/d</span>
          <DatePicker
            locale={id}
            dateFormat={_FDate}
            selected={to_date}
            onChange={(date) => changeDate(date, "to")}
            customInput={<InputDate />}
          />
        </div>
      </div>
      <div className={style.trans_container_outer}>
        <div className={style.trans_container}>
          {is_loading && <InvoiceCardLoading />}
          {!is_loading && (transList?.length ?? 0) === 0 && (
            <>Tidak ada transaksi</>
          )}
          {!is_loading &&
            (transList?.length ?? 0) > 0 &&
            transList.map((data, idx) => {
              const key = `${status}_${idx}`;
              let component = TransactionSellerView;
              if (status === TMS_COMPLAINT) component = TransactionComplaintView;
              if (status === TMS_NOT_PAID) component = TransactionPendingView;
              return React.createElement(component, { key, data });
            })}
        </div>
      </div>
    </div>
  );
}

export default SellerTransactionMain;
