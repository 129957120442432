import dayjs from "dayjs";
import React, { useState } from "react";
import style from "../../../css/userProfile/userTransaction.module.css";
import { Button } from "../../CustomComponent/StyledComponent";
import queryString from "query-string";
import FaspayPendingModalView from "../../Transaction/FaspayPendingModalView";
import TransactionItem from "../../Transaction/TransactionItem";
import { getTransItemList } from "../../../controller/transactionController";
import {
  formatCurrency,
  formatDate,
} from "../../../controller/globalController";

const initState = {
  toggle: false,
  faspay_toggle: false,
  faspay: undefined,
};
const dateFormat = "DD MMMM YYYY HH : mm";

function TransactionPendingView(props) {
  const [state, setState] = useState(initState);
  const { order_id, cart, customer_details, transaction_details } = props.data;
  const { address, city, postal_code, phone } = customer_details.shipping_address;
  const first_name = customer_details?.address?.name ?? customer_details.shipping_address.first_name;
  const _address = `${address}, ${city}, ${postal_code}`.toLowerCase();
  const item_list = getTransItemList(cart);
  const trans_exp = dayjs(props.data.expired_time);

  //   function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const popupHandler = (toggle = false) => {
    setState({
      ...state,
      faspay_toggle: toggle,
      faspay: toggle ? state.faspay : undefined,
    });
  };

  const payNow = () => {
    const link = props.data.redirect_url;
    const pg_id = transaction_details.payment_type.pg_id;
    const type = transaction_details.payment_type.type;
    if (pg_id === 1) {
      const token = link.split("/")[6];
      window.snap.pay(token, {
        onSuccess: function (result) {},
        onPending: function (result) {},
        onError: function (result) {},
        onClose: function () {},
        gopayMode: type === "gopay" ? "auto" : "qr",
      });
    }
    if ([2, 3].includes(pg_id)) {
      const faspay_token = "?" + link.split("?")[1];
      let faspay = {};
      faspay.param = queryString.parse(faspay_token);
      faspay.payment = transaction_details.payment_type.type;
      faspay.gross_amount = props.data.gross_amount;
      faspay.expire_date = trans_exp;
      changeState({ faspay, faspay_toggle: true });
    }
  };

  return (
    <>
      {state.faspay && (
        <FaspayPendingModalView
          data={state.faspay}
          toggle={state.faspay_toggle}
          popupHandler={popupHandler}
        />
      )}

      <div className={style.trans_container_item}>
        <div className={style.inner_trans_container1}>
          <div className={style.inv_no}>{`No. Pembayanan : ${order_id}`}</div>
          <div id={style.stat}>{`Status Pesanan : Pesanan Belum Dibayar`}</div>
          <div id={style._date}>
            {formatDate(props.data.created_at, dateFormat)}
          </div>
        </div>
        <div className={style.inner_trans_container2}>
          <div className={`${style.trans_item_container}`}>
            <div
              className={`${style.trans_item} ${state.toggle ? style.show : ""
                }`}
            >
              {item_list.map((data, idx) => {
                const key = `${data._product.dtl_product_id}_${idx}`;
                if ((!state.toggle && idx === 0) || state.toggle) {
                  return <TransactionItem key={key} data={data} />;
                }
              })}
            </div>
            {item_list.length > 1 && (
              <div
                className={style.show_more}
                onClick={() => changeState({ toggle: !state.toggle })}
              >
                {!state.toggle
                  ? `+${item_list.length - 1} Produk lainnya`
                  : "Lihat lebih sedikit"}
              </div>
            )}
          </div>

          <div className={style.trans_detail}>
            <table className={style.detail}>
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{first_name}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{_address}</td>
                </tr>
                <tr>
                  <td>No.Hp</td>
                  <td>:</td>
                  <td>{phone}</td>
                </tr>
                <tr>
                  <td>Metode Pembayaran</td>
                  <td>:</td>
                  <td>{transaction_details.payment_type.name}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <div className={style.btn_container}>
                {`Total dibayar ${formatCurrency(props.data.gross_amount)}`}
                <Button active onClick={() => payNow()}>
                  Bayar Sekarang
                </Button>
              </div>
              <div className={style.exp_date}>
                {`Bayar Sebelum ${formatDate(trans_exp, dateFormat)}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransactionPendingView;
