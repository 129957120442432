import React from "react";
import Skeleton from "react-loading-skeleton";

import { InlineWrapper } from "../../controller/loadingController";
import style from "../../css/sellerProfile/createProduct/sellerProductForm.module.css";

const Title = () => {
  return (
    <Skeleton
      height={20}
      width={150}
      count={1}
      style={{ marginBottom: "0.5rem" }}
      wrapper={InlineWrapper}
      borderRadius={"0.5rem"}
    />
  );
};

const Photo = ({ count }) => {
  const array = [];
  for (let i = 0; i < count; i++) {
    array.push(
      <Skeleton
        key={`loading_${i}`}
        height={100}
        wrapper={InlineWrapper}
        borderRadius={"0.5rem"}
      />
    );
  }
  return array;
};

const Input = ({ count, name = "" }) => {
  const array = [];
  for (let i = 0; i < count; i++) {
    array.push(
      <div className={style.input_container} key={`loading_${i}_${name}`}>
        <Skeleton
          height={20}
          count={1}
          style={{ minWidth: "20vw" }}
          wrapper={InlineWrapper}
          borderRadius={"0.5rem"}
        />
        <Skeleton
          height={20}
          count={1}
          style={{ minWidth: "50vw" }}
          wrapper={InlineWrapper}
          borderRadius={"0.5rem"}
        />
      </div>
    );
  }
  return array;
};

function CreateProductLoading(props) {
  return (
    <>
      <div className={style.part_container}>
        <Title />
        <div className={style.photo_container}>
          <Photo count={5} />
        </div>
      </div>
      <div className={style.part_container}>
        <Title />
        <Input count={3} name="part1" />
      </div>
      <div className={style.part_container}>
        <Title />
        <Skeleton
          height={40}
          count={1}
          style={{ marginBottom: "0.5rem" }}
          wrapper={InlineWrapper}
          borderRadius={"0.5rem"}
        />
      </div>
      <div className={style.part_container}>
        <Title />
        <Input count={5} name="part1" />
      </div>
    </>
  );
}

export default CreateProductLoading;
