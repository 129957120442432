export const MERCHANT_1TOKO_LINK = "1toko.com";
export const SHARE_LINK = "https://share.1toko.com";

export const DOMAIN = "1toko.com";
export const webLink = window.location.hostname;

export const BASE_URL = "https://api.auth.v2.1toko.com/";
export const BASE_URL_TRANS = "https://api.transaction.v2.1toko.com/";
export const BASE_URL_PAYMENT = "https://api.payment.v2.1toko.com/";
export const BASE_URL_COURIER = "https://api.courier.v2.1toko.com/";
export const BASE_URL_CHAT = "https://api.chat.v2.1toko.com/";
export const BASE_URL_UPLOAD_IMG = "https://api.image.v2.1toko.com/";
export const BASE_URL_IMG = "https://api.image.v2.1toko.com/cache/img/";
export const BASE_URL_IMG_LOGO = "https://api.image.v2.1toko.com/cache/logo/";

// regex
const regex = (reg) => new RegExp(reg);
export const regexName = regex(`^([a-zA-Z0-9\\.\\-_\\s])+$`);
export const regexProductName = regex(`^([a-zA-Z0-9\\.\\-_\\s()\\,\\"\\/\\+\\:])+$`);
export const regexSubdomain = regex(`^([a-zA-Z0-9\\-])+$`);
export const regexReferral = regex(`^([A-Z0-9])+$`);
export const regexNumber = regex(`^([0-9])+$`);
export const emailRegex = regex(
  "^([a-zA-Z0-9_.-])+\\@(([a-zA-Z0-9-])+\\.)+([a-zA-Z0-9]{2,4})+$"
);
export const phoneRegex = regex("^(\\+62|62|0)[2,8][1-9][0-9]{7,12}$");
export const phoneRegex2 = regex("^(\\8)[1-9][0-9]{7,12}$");
export const passwordRegex = regex(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z0-9~!@#$%^&-_+=]{6,}$"
);

// key
export const googleMapsKey = "AIzaSyB3YX-dK1z_Cv4GLRMfOoJEVVPHybkmFfg";

// print type
export const PRINT_INV = 1;
export const PRINT_SENT_LBL = 2;

export const FIREBAS_CONFIG = {
  apiKey: "AIzaSyAiZamhEAmgOyKvFdQZCmSYAabQQYRxVGM",
  authDomain: "satutoko-spin-international.firebaseapp.com",
  projectId: "satutoko-spin-international",
  storageBucket: "satutoko-spin-international.appspot.com",
  messagingSenderId: "456638859028",
  appId: "1:456638859028:web:bac57b83e9facbc6762401",
};

