import React from "react";
import style from "../../css/switcher.module.css";

function Switcher({ id, toggle, disabled, onChangeToggle, ...props }) {
  const onChange = (event) => onChangeToggle(event)
  return (
    <div className={style.container}>
      <input id={id}
        {...props}
        type={"checkbox"}
        checked={toggle}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id} className="slider" />
    </div>
  );
}

export default Switcher;
