import React from "react";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import style from "../../css/chat.module.css";
import { BASE_URL_IMG, BASE_URL_UPLOAD_IMG } from "../../utils/Constants";

function ChatHeader({
  selected_room = null,
  type = "buyer",
  toggle = false,
  changeToggle = () => {},
  ...props
}) {
  const s_flag = type === "seller";
  const selected_flag = ![null, undefined].includes(selected_room);
  if (!["buyer", "seller"].includes(type) || !selected_flag) return "";

  const active_user = s_flag ? selected_room.user : selected_room.seller;
  const active_id = s_flag ? selected_room.user_id : selected_room.seller_id;
  var img_link = !s_flag ? BASE_URL_IMG : `${BASE_URL_UPLOAD_IMG}cache/usr/`;
  img_link = `${img_link}30/30/${active_id}/${active_user.logo}`;

  const menu_style = `${style.actMenu} ${style[toggle ? "down" : "up"]}`;
  // const identity_style = { cursor: !s_flag ? "pointer": "default" };
  const identity_style = { cursor: false ? "pointer": "default" };

  const handleDelete = () => props.hideRoom(selected_room.room_id);
  // const goToStore = () => window.location.href = `https://`

  return (
    <div className={style.chat_header}>
      <div className={style.header_identity} style={identity_style}>
        <img src={img_link} />
        <div className={style.chat_identity}>
          <div id={style.name}>{active_user.name}</div>
          {/* <div>{selected_flag ? "Status" : ""}</div> */}
        </div>
      </div>

      <OutsideClickHandler onOutsideClick={() => changeToggle(false)}>
        <div className={style.action_container}>
          <div className={style.button} onClick={() => changeToggle(!toggle)}>
            <i className="bi bi-three-dots-vertical"></i>
          </div>
          <ul className={menu_style}>
            <li onClick={() => handleDelete()}>Hapus</li>
          </ul>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

export default ChatHeader;
