import React from 'react';
import style from './user_access_form.module.css'
import Skeleton from 'react-loading-skeleton';

function AccessFormView({
    loading = false, list = [], etalase = [], subEtalase = [],
    handleEtalaseCheck = () => { }, handleSubEtalaseCheck = () => { }
}) {
    return (
        <div className={style.list_container}>
            <label>Etalse Akes</label>
            {loading && (
                <div style={{ marginBlock: '0.5rem' }}>
                    <Skeleton height={100} />
                </div>
            )}
            <div className='scroll_container' style={{ overflowY: 'scroll', maxHeight: '150px' }}>
                {!loading && list.map((data, idx) => {
                    const { id: etalase_id, sub_etalase } = data
                    const flag = etalase.includes(etalase_id);
                    return (
                        <div key={`etalase${idx}`} className={style.etalase_container}>
                            <CheckboxView data={data} checked={flag} handleCheck={(e) => handleEtalaseCheck(e, sub_etalase)} />
                            <div className={style.subetalase_container}>
                                {sub_etalase.length > 0 && (
                                    sub_etalase.map((subdata, sub_idx) => {
                                        const subFlag = subEtalase.includes(subdata.id);
                                        return (
                                            <CheckboxView key={`subetalase${sub_idx}`} data={subdata}
                                                checked={subFlag} handleCheck={(e) => handleSubEtalaseCheck(e, data)}
                                            />
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default AccessFormView;

function CheckboxView({ data, checked = false, handleCheck = () => { } }) {
    const { id, name } = data;
    return (
        <div className={style.check_input}>
            <input type='checkbox' value={id} id={`${id}`} checked={checked}
                onChange={handleCheck}
            />
            <label htmlFor={`${id}`}>{name}</label>
        </div>
    );
}