import Cookies from "js-cookie"
import Swal from "sweetalert2"
import API, { APIAuth } from "../utils/API"
import { DOMAIN, emailRegex, passwordRegex, phoneRegex, webLink } from "../utils/Constants"
import { globalStorage } from "../utils/Storage"

const localhostFlag = window.location.hostname.indexOf("localhost") > -1
export const isDomain = webLink.indexOf(DOMAIN) < 0
export const CookiesDomain = (localhostFlag ? "" : isDomain ? webLink : DOMAIN).replace("www.", "")

export const checkValidEmailOrPhone = (username) => {
	let data = { is_valid_username: false, is_hp_or_email: "", error: "" }
	if (emailRegex.test(username) || phoneRegex.test(username)) {
		data.is_valid_username = true
		data.is_hp_or_email = emailRegex.test(username) ? "email" : "phone"
	} else {
		data.error = username === "" ? "" : "Email / Nomor Hp tidak valid"
	}
	return data
}

export const handleRegisterOTP = async ({ username, is_hp_or_email, changeState, color = "#583985" }) => {
	const flag = is_hp_or_email === "email"
	const use_user = flag ? "E-mail" : "Nomor handphone"
	const res = Swal.fire({
		html: `<div>Apakah ${use_user} <div class="font-weight-bolder">${username}</div> sudah tepat?</div>`,
		allowOutsideClick: false,
		cancelButtonText: "Ya, Lanjut",
		showCancelButton: true,
		confirmButtonColor: color,
		cancelButtonColor: color,
		confirmButtonText: "Ubah",
	}).then(async (res) => {
		if (res.value) {
			Swal.close()
			return false
		} else {
			changeState({ is_loading: true })
			let req = await getRegisterOTP(username.replace(/^0+/, "+62"))
			if (!req) {
				let text = `Lanjut masuk dengan ${use_user} ini ${username} ?`
				await Swal.fire({
					title: `${use_user} Sudah Terdaftar`,
					text: text,
					allowOutsideClick: false,
					cancelButtonText: "Ya, Masuk",
					showCancelButton: true,
					confirmButtonColor: "#804199",
					cancelButtonColor: "#804199",
					confirmButtonText: "Ubah",
				}).then((res) => {
					if (res.value) {
						Swal.close()
						return false
					} else {
						window.location = "/login"
					}
				})
			} else {
				return true
			}
		}
	})

	return res
}

export const login = async (payload) => {
	const { username, otp, password, is_hp_or_email } = payload
	let data = { error: "", userData: undefined }
	if (is_hp_or_email === "email") {
		if (password !== "") {
			const result = await loginEvent(username, password)
			data.error = result
		} else {
			data.error = "Kata sandi tidak boleh kosong"
		}
	}

	if (is_hp_or_email === "phone") {
		const _username = username.replace(/^0+/, "+62")
		if (await validateOTP(_username, otp)) {
			const result = await loginEvent(_username, password)
			data.error = result
		} else {
			data.error = "OTP salah atau sudah tidak valid"
		}
	}

	if (data.error === "") {
		const userData = await getUserProfile()
		data.userData = userData
	}

	return data
}

export const register = async (payload) => {
	const { name, password, conf_password, is_hp_or_email } = payload
	const email_flag = is_hp_or_email === "email"

	let res = { error: "" }
	let data = new FormData()
	data.append("name", name)
	if (name !== "") {
		if (email_flag) {
			data.append("email", payload.username)
			data.append("password", password)
			data.append("password_confirmation", conf_password)
			if (passwordRegex.test(password)) {
				if (password === conf_password) {
					res.error = await registerEvent(data)
				} else {
					Swal.fire({
						icon: "error",
						title: "Maaf...",
						text: "Kata sandi dan Konfirmasi kata sandi tidak sama",
					})
				}
			} else {
				let text = "Kata sandi harus memiliki minimal 1 huruf kapital, 1 huruf kecil dan angka"
				if (password.length < 6) text = "Kata sandi harus memiliki minimal 6 karakter"
				Swal.fire({
					icon: "error",
					title: "Maaf...",
					text: text,
				})
			}
		} else {
			data.append("handphone", payload.username)
			data.append("is_verified_handphone", 1)
			res.error = await registerEvent(data)
		}
	} else {
		Swal.fire({
			icon: "error",
			title: "Maaf...",
			text: "Nama tidak boleh kosong!",
		})
	}

	return res
}

export const logout = () => {
	Cookies.remove("access_token", { secure: true, domain: CookiesDomain })
	Cookies.remove("refresh_token", { secure: true, domain: CookiesDomain })
	globalStorage.removeItem("UD")
	globalStorage.removeItem("ED")
	window.location = "/"
}

// Call API
const loginEvent = async (username, password) => {
	const postData = new FormData()
	postData.append("id", username)
	postData.append("password", password)
	return API.post(`auth/login`, postData)
		.then((res) => {
			const data = res.data
			Cookies.set("access_token", data.access_token, { secure: true, domain: CookiesDomain })
			Cookies.set("refresh_token", data.refresh_token, { secure: true, domain: CookiesDomain })
			return ""
		})
		.catch((err) => {
			const error = err.response.data?.errors ?? "error"
			return error[0]
		})
}

export const loginEdesa = async (user, key, hp) => {
	return API.post(
		`e_desa/login`,
		{ id: hp },
		{
			auth: { username: user, password: key },
		}
	)
		.then(async (res) => {
			const data = res.data
			const domain = isDomain ? webLink : DOMAIN
			Cookies.set("access_token", data.access_token, { domain })
			Cookies.set("refresh_token", data.refresh_token, { domain })
			const userData = await getUserProfile()
			return userData
		})
		.catch((err) => {
			const error = err.response.data?.errors ?? "error"
			return error[0]
		})
}

const registerEvent = async (data) => {
	return API.post(`auth/register`, data)
		.then(async (res) => {
			return ""
		})
		.catch((err) => {
			const error = err.response.data
			return error
		})
}

// validasi
export const validateOTP = async (id, otp) => {
	return API.post(`auth/validateotp`, { id: id, otp: otp })
		.then((res) => {
			return true
		})
		.catch((err) => {
			return false
		})
}

export const checkCommunityCode = async (code) => {
	return API.get(`check/community?id=${code}`)
		.then((res) => true)
		.catch((err) => {
			return false
		})
}

export const checkSubdomain = async (subdomain) => {
	return API.get(`check/subdomain?subdomain=${subdomain}`)
		.then((res) => true)
		.catch((err) => {
			return false
		})
}

export const checkEcoSubdomain = async (subdomain = "") => {
	return API.get(`check/ecosystem?subdomain=${subdomain.replace(`.${DOMAIN}`, "")}`)
		.then((res) => true)
		.catch((err) => false)
}

// getData
export const getStoreInfo = async (id) => {
	return API.post(`seller/info`, { id })
		.then((res) => res.data)
		.catch((err) => undefined)
}

export const getLoginOTP = async (id, type = "wa") => {
	return API.post(`auth/otpvalid`, { id, type })
		.then((res) => true)
		.catch((err) => false)
}

export const getRegisterOTP = async (id, type = "wa") => {
	return API.post(`auth/otp`, { id, type })
		.then((res) => {
			return true
		})
		.catch((err) => {
			// const error = err.response.data.errors;
			return false
		})
}

export const getUserProfile = async () => {
	return APIAuth.get(`auth/profile`)
		.then((res) => res.data)
		.catch((err) => undefined)
}

export const resetPassword = async (data) => {
	return APIAuth.post(`auth/resetpassword`, { ...data })
		.then((res) => res.data)
		.catch((err) => {
			return undefined
		})
}
