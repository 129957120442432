import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleLogin } from "../../redux/globalAction";
import CustomModal, { HeaderModal } from "../CustomComponent/CustomModal";
import LoginForm from "./LoginForm";

const LoginModalView = (props) => {
  const toggle = useSelector((s) => s.global.loginModal);
  const location = useLocation();
  const dispatch = useDispatch();

  const _popupHandler = () => {
    dispatch(toggleLogin(!toggle));
  };

  useEffect(() => {
    dispatch(toggleLogin(false));
  }, [location]);

  return (
    <CustomModal toggle={toggle} size="sm" modalHandler={_popupHandler}>
      <HeaderModal title="Masuk" modalHandler={_popupHandler} />
      <LoginForm />
    </CustomModal>
  );
};

export default LoginModalView;
