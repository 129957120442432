import React, { useMemo, useState } from 'react';
import style from "../../../css/sellerProfile/etalase.module.css"
import { Button, CDropdown } from '../../CustomComponent/StyledComponent';
import Switcher from '../../CustomComponent/Switcher';
import useDebounce from '../../../utils/useDebounce';
import { addUpdateSubEtalase } from '../../../controller/productEtalaseController';

function SubEtalaseCardView({ data, handleDelete, handleEdit, length = 0 }) {
    const [status, setStatus] = useState(data.is_active === 1)
    const [seq, setSeq] = useState(data.seq)
    const seqList = useMemo(() => Array.from(Array(length), (_, index) => index + 1), [length])

    const _handleChecked = (event) => setStatus(event.target.checked);
    const _handleEdit = () => handleEdit(data)
    const _handleDelete = () => handleDelete(data.id);

    useDebounce(async () => {
        addUpdateSubEtalase({ ...data, is_active: status ? 1 : 0, seq })
    }, 500, [status, seq])

    return (
        <div className={style.sub_card} >
            <div className={style.name}>{data.name}</div>
            <div className={style.action_container}>
                <div className={style.seq_container}>
                    <label>Urutan :</label>
                    <CDropdown color="white" scroll_color='red'>
                        <CDropdown.Toggle id="dropdown-autoclose-true" style={{ minWidth: '50px' }}
                            disabled={seqList.length === 1}
                        >
                            {seq}
                        </CDropdown.Toggle>
                        <CDropdown.Menu className={style.seq_drop_menu}>
                            {seqList.map((item, index) => {
                                return (
                                    <CDropdown.Item
                                        key={`${data.name}_${index}`}
                                        onClick={() => setSeq(item)}
                                        className={style.seq_drop_item}
                                    >
                                        {item}
                                    </CDropdown.Item>
                                );
                            })}
                        </CDropdown.Menu>
                    </CDropdown>
                </div>
                {!status && (
                    <Button active onClick={_handleDelete}>
                        <i className="bi bi-trash-fill" />
                    </Button>
                )}
                <Switcher id={`user_acc${data.id}`} toggle={status} onChangeToggle={_handleChecked} />
                <Button active onClick={_handleEdit}>
                    <i className='bi bi-pencil-square' />
                </Button>
            </div>
        </div>
    );
}

export default SubEtalaseCardView;