import API from "../utils/API";
import { globalStorage } from "../utils/Storage";

// var device = ""; // console.log(navigator.userAgent)

export const getSearchData = async (data, type, page = 1, limit = 20, cancel) => {
  let list = [];
  const send_data = { ...data, page, limit, cancel };
  if (type === "product") list = await getShowProductList(send_data);
  if (type === "store") list = await getShowStoreList(send_data);
  if (type === "community") list = await getShowCommunityList(send_data);
  return list.length === 0 ? undefined : list;
};

// store home
export const getStoreHomeProduct = async (max, min, sellerId, etalaseId = "", subEtalaseId = "", page = 1, limit = 20, cancel) => {
  const data = { max, min, sellerId, etalaseId, subEtalaseId, page, limit, cancel };
  const list = await getShowProductList(data);
  return list;
};

// merchant home
export const getMerchantHomeProduct = async (page = 1, limit = 20, cancel) => {
  const list = await getShowProductList({ page, limit, cancel });
  return list;
};

export const getMerchantHomeStore = async (page = 1, limit = 20, cancel) => {
  const list = await getShowStoreList({ page, limit, cancel });
  return list;
};

export const getMerchantHomeCommunity = async (cancel = null) => {
  const list = await getShowCommunityList({ cancel });
  return list;
};

//ecosystem home
export const getEcoHomeCommunity = async (seller_id, cancel = null) => {
  const list = await getEcosystemCommunityList({ seller_id, cancel });
  return list;
};

export const getEcoHomeStore = async (seller_id, page = 1, limit = 20, cancel) => {
  const list = await getEcosystemStoreList({ seller_id, page, limit, cancel });
  return list;
};

export const getEcoHomeProduct = async (seller_id, page = 1, limit = 20, cancel) => {
  const list = await getEcosystemProductList({ seller_id, page, limit, cancel });
  return list;
};


// community home
export const getCommunityHomeStore = async (
  communityId,
  page = 1,
  limit = 20,
  cancel
) => {
  const data = { communityId, page, limit, cancel };
  const list = await getShowStoreList(data);
  return list;
};

export const getCommunityHomeProduct = async (
  communityId,
  page = 1,
  limit = 20,
  cancel
) => {
  const data = { communityId, page, limit, cancel };
  const list = await getShowProductList(data);
  return list;
};

// api call
export const getStoreEtalase = async (sellerId) => {
  return API.get(`etalaselist?s=${sellerId}`)
    .then((res) => res.data)
    .catch((err) => {
      return [];
    });
};

const getShowProductList = async (payload) => {
  const _pages = `?page=${payload?.page ?? 1}`;
  const _limits = `&limit=${payload?.limit ?? 20}`;
  const _sellerId = payload?.sellerId ? `&s=${payload?.sellerId}` : "";
  const _etalaseId = payload?.etalaseId ? `&e=${payload?.etalaseId}` : "";
  const _subEtalaseId = payload?.subEtalaseId ? `&se=${payload?.subEtalaseId}` : "";
  const _communityId = payload?.communityId ? `&c=${payload?.communityId}` : "";
  const _search = payload?.search ? `&q=${payload?.search}` : "";
  const _tag = `&tag=${globalStorage.getItem("STag")}`;

  let route = `products${_pages}${_limits}${_sellerId}${_etalaseId}${_subEtalaseId}${_communityId}${_search}${_tag}`;
  if (![undefined, "", null, 0].includes(payload?.max)) route = route + `&max=${payload.max}`;
  if (![undefined, "", null, 0].includes(payload?.min)) route = route + `&min=${payload.min}`;
  return API.get(route, { signal: payload?.cancel?.signal ?? null })
    .then((res) => {
      let data = res.data;
      let temp = res.data.data.map((data) => {
        const dp = data.price - (data.price * data.discount) / 100;
        return { ...data, discounted_price: dp };
      });
      data.data = temp;
      return data;
    })
    .catch((err) => {
      return [];
    });
};

const getShowStoreList = async (payload) => {
  const _pages = `?page=${payload?.page ?? 1}`;
  const _limits = `&limit=${payload?.limit ?? 20}`;
  const _communityId = payload?.communityId ? `&c=${payload?.communityId}` : "";
  const _search = payload?.search ? `&q=${payload?.search}` : "";
  const _tag = `&tag=${globalStorage.getItem("STag")}`;
  return API.get(`shop${_pages}${_limits}${_communityId}${_search}${_tag}`, {
    signal: payload?.cancel?.signal ?? null,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return [];
    });
};

const getShowCommunityList = async (payload) => {
  const _search = payload?.search ? `?q=${payload?.search}` : "";
  return API.get(`community${_search}`, {
    signal: payload?.cancel?.signal ?? null,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return [];
    });
};

export const getCommunityHomeList = async (cancel = null) => {
  return API.get(`community?q=&with=store_list`, {
    signal: cancel?.signal ?? null,
  })
    .then((res) => res.data)
    .catch((err) => {
      // console.log(err);
      return [];
    });
};

export const getEcoCommunityHomeList = async (seller_id, cancel) => {
  return API.get(`ecosystem_community/${seller_id}?with=store_list`, {
    signal: cancel?.signal ?? null,
  })
    .then((res) => res.data)
    .catch((err) => {
      // console.log(err);
      return [];
    });
};

export const getProductDetail = async (product_id, type = "") => {
  if (type !== "") { type = `?type=${type}`; }
  return API.get(`product/single/${product_id}${type}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return undefined;
    });
};

const getEcosystemCommunityList = async (payload) => {
  const _search = payload?.search ? `?q=${payload?.search}` : "";
  return API.get(`ecosystem_community/${payload.seller_id}${_search}`, {
    signal: payload?.cancel?.signal ?? null,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return [];
    });
}

const getEcosystemStoreList = async (payload) => {
  const _pages = `?page=${payload?.page ?? 1}`;
  const _limits = `&limit=${payload?.limit ?? 20}`;
  const _search = payload?.search ? `&q=${payload?.search}` : "";
  const _tag = `&tag=${globalStorage.getItem("STag")}`;
  return API.get(`ecosystem_store/${payload.seller_id}${_pages}${_limits}${_search}${_tag}`, {
    signal: payload?.cancel?.signal ?? null,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      // console.log(err);
      return [];
    });
}

const getEcosystemProductList = async (payload) => {
  const _pages = `?page=${payload?.page ?? 1}`;
  const _limits = `&limit=${payload?.limit ?? 20}`;
  const _search = payload?.search ? `&q=${payload?.search}` : "";
  const _tag = `&tag=${globalStorage.getItem("STag")}`;
  return API.get(
    `ecosystem_product/${payload.seller_id}${_pages}${_limits}${_search}${_tag}`,
    { signal: payload?.cancel?.signal ?? null }
  )
    .then((res) => {
      let data = res.data;
      let temp = res.data.data.map((data) => {
        const dp = data.price - (data.price * data.discount) / 100;
        return { ...data, discounted_price: dp };
      });
      data.data = temp;
      return data;
    })
    .catch((err) => {
      // console.log(err);
      return [];
    });

}