import React, { useState } from "react";
import Swal from "sweetalert2";
import { changePassword } from "../../../controller/userProfileController";
import { passwordRegex } from "../../../utils/Constants";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import { Button, Input } from "../../CustomComponent/StyledComponent";

const PasswordInput = (payload) => {
  const { label, error, value, name, showPass } = payload;
  const { setShowPass, onChange } = payload;
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div className="input-group">
        <Input
          name={name}
          type={showPass ? "text" : "password"}
          className={`form-control`}
          value={value}
          onChange={(e) => onChange(e.target)}
        />
        <div className="input-group-append">
          <span className={`input-group-text`} id="basic-addon">
            <i
              className={showPass ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}
              onClick={() => setShowPass(!showPass)}
            />
          </span>
        </div>
      </div>
      {error !== "" && <label style={{ color: "red" }}>{error}</label>}
    </div>
  );
};

const initState = {
  old_pass: "",
  new_pass: "",
  conf_pass: "",
  is_loading: false,

  err_old_pass: "",
  err_new_pass: "",
  err_conf_pass: "",
  err: "",
};

function ChangePasswordModalView(props) {
  const { toggle, popupHandler } = props;
  const [state, setState] = useState(initState);
  const [showPass, setShowPass] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const { old_pass, new_pass, conf_pass } = state;
  const { err_old_pass, err_new_pass, err_conf_pass, err } = state;

  //   function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changePass = (event) => changeState({ [event.name]: event.value });
  const validatePass = () => {
    let res = true;
    let _err = {
      err: "",
      err_old_pass: "",
      err_new_pass: "",
      err_conf_pass: "",
    };

    const not_match_flag = new_pass !== conf_pass;
    const pass_flag = !passwordRegex.test(new_pass);
    const empty_flag = old_pass === "" || new_pass === "" || conf_pass === "";
    if (empty_flag || not_match_flag || pass_flag) res = false;
    if (old_pass === "") _err.err_old_pass = "Kata Sandi Lama tidak boleh kosong";
    if (new_pass === "") _err.err_new_pass = "Kata Sandi Baru tidak boleh kosong";
    if (conf_pass === "") _err.err_conf_pass = "Kata Sandi Konfirmasi tidak boleh kosong";
    if (not_match_flag && new_pass !== "") _err.err = "Kata Sandi Baru tidak sama dengan Kata Sandi Konfirmasi";
    if (pass_flag && new_pass !== "")
      _err.err = "Kata Sandi harus minimal 6 karakter serta mengandung 1 huruf kapital, 1 huruf kecil dan angka";
    changeState({ ..._err });
    return res;
  };

  const handleSubmit = async () => {
    let res = undefined;
    if (!validatePass()) return;
    changeState({ is_loading: true });
    res = await changePassword({ old_pass, new_pass, conf_pass });
    changeState({ is_loading: false });
    if (res && res.errors === undefined) {
      Swal.fire({
        title: "Berhasil",
        icon: "success",
        confirmButtonColor: "var(--main_color)",
      }).then(() => popupHandler());
    } else {
      Swal.fire({
        title: "Gagal",
        text: res.errors[0],
        icon: "error",
        confirmButtonColor: "var(--main_color)",
      });
    }
  };

  return (
    <CustomModal outsideClick toggle={toggle} modalHandler={popupHandler} size="sm">
      <HeaderModal title="Ubah Kata Sandi" modalHandler={popupHandler} />
      <PasswordInput
        label="Kata Sandi Lama"
        error={err_old_pass}
        value={old_pass}
        name="old_pass"
        showPass={showPass}
        setShowPass={setShowPass}
        onChange={changePass}
      />
      <PasswordInput
        label="Kata Sandi Baru"
        error={err_new_pass}
        value={new_pass}
        name="new_pass"
        showPass={showPass1}
        setShowPass={setShowPass1}
        onChange={changePass}
      />
      <PasswordInput
        label="Konfirmasi Kata Sandi"
        error={err_conf_pass}
        value={conf_pass}
        name="conf_pass"
        showPass={showPass2}
        setShowPass={setShowPass2}
        onChange={changePass}
      />
      <Button active style={{ marginTop: "1rem" }} onClick={() => handleSubmit()}>
        Simpan
      </Button>
      {state.err !== "" && <label style={{ color: "red" }}>{state.err}</label>}
    </CustomModal>
  );
}

export default ChangePasswordModalView;
