import React from "react";
import Skeleton from "react-loading-skeleton";

import { InlineWrapper } from "../../controller/loadingController";
import style from "../../css/loading.module.css";

function StorePerformanceLoading({ type = 1 }) {
  if (type === 1) {
    return (
      <>
        <Skeleton height={40} count={1} />
        <Skeleton height={40} count={1} />
      </>
    );
  }
  if (type === 2) return <Skeleton height={200} count={1} />;
}

export default StorePerformanceLoading;
