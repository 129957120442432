import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import style from "../../css/userProfile/changeEmailHp.module.css";
import { Input } from "./StyledComponent";

function InputOTP_ChangeEmailHp(props) {
  const { user_data, type, value, onChange, reCall } = props;
  const [time, setTime] = useState(120000);
  var otp_label = type === "email" ? `Email Anda` : `HP Anda melalui WhatsApp`;
  otp_label = `OTP telah dikirimkan ke ${otp_label}, silahkan memasukkan input OTP Anda`;

  //   function
  const showTimer = () => {
    const duration = require("dayjs/plugin/duration");
    dayjs.extend(duration);
    let minutes = "0" + dayjs.duration(time).minutes();
    let seconds = "0" + dayjs.duration(time).seconds();
    return `${minutes.substring(minutes.length - 2)} : ${seconds.substring(
      seconds.length - 2
    )}`;
  };

  const reCallOTP = () => {
    reCall(user_data, type);
    setTime(120000);
  };

  //   useEffect
  useEffect(() => {
    //timer re-req otp
    let timer = null;
    timer = setTimeout(() => {
      if (time > 0) setTime(time - 1000);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className={style.opt_container}>
      <label className={style.otp_opt_title}>{otp_label}</label>
      <Input value={value} onChange={(e) => onChange(e)} />
      <div className={style.timer_container}>
        {time > 0 ? (
          <span>{`Kirim ulang OTP dalam waktu ${showTimer()}`}</span>
        ) : (
          <span className={style.re_call_otp} onClick={() => reCallOTP()}>
            kirim ulang OTP
          </span>
        )}
      </div>
    </div>
  );
}

export default InputOTP_ChangeEmailHp;
