import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import CustomModal, { HeaderModal } from "../CustomComponent/CustomModal";
import { BankView } from "../Shipment/Faspay/FaypayPaymentModalView";
import style from "../../css/userProfile/userTransaction.module.css";

const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

function FaspayPendingModalView(props) {
  const { data, toggle, popupHandler } = props;
  const exp_date = data.expire_date;
  const initTime = exp_date.valueOf() - dayjs().valueOf();
  const [time, setTime] = useState(initTime);
  const exp_flag = time > 0;
  const title = "Transaksi";

  useEffect(() => {
    let timer = null;
    if (data) {
      timer = setTimeout(() => {
        if (time > 0) setTime(time - 1000);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  });

  const showTimer = () => {
    let hour = "0" + dayjs.duration(time).hours();
    let minutes = "0" + dayjs.duration(time).minutes();
    let seconds = "0" + dayjs.duration(time).seconds();

    hour = hour.substring(minutes.length - 2);
    minutes = minutes.substring(minutes.length - 2);
    seconds = seconds.substring(seconds.length - 2);
    if (hour > 0) return `${hour} : ${minutes} : ${seconds}`;
    return `${minutes} : ${seconds}`;
  };

  return (
    <CustomModal toggle={toggle} modalHandler={() => popupHandler(false)}>
      <HeaderModal title={title} modalHandler={() => popupHandler(false)} />
      <div className={`${exp_flag ? style.exp_time : style.exp_text}`}>
        {exp_flag ? (
          <>
            <div>Batas waktu transaksi</div>
            <div>{showTimer()}</div>
          </>
        ) : (
          "Transaksi telah Kadaluarsa"
        )}
      </div>
      <BankView data={data} />
    </CustomModal>
  );
}

export default FaspayPendingModalView;
