import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../../../controller/globalController";
import style from "../../../../css/sellerProfile/payoutTransaction.module.css";
import trans_style from "../../../../css/sellerProfile/sellerTransaction.module.css";
import item_style from "../../../../css/transactionItem.module.css";
import { BASE_URL_IMG } from "../../../../utils/Constants";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";

function InvoiceView({ data, ...props }) {
  const {
    seller_details,
    item_details,
    customer_details,
    transaction_details,
  } = data;
  var courier = `${data.courier_name} ( ${data.courier_service} )`;
  if (data.courier_code.indexOf("self") > -1) courier = `${data.courier_name}`;
  const { address, city, postal_code, phone } =
    customer_details.shipping_address;
  const _address = `${address}, ${city}, ${postal_code}`.toLowerCase();

  const [toggle, setToggle] = useState(props.toggle ?? false);
  const [toggle1, setToggle1] = useState(false);

  useEffect(() => {
    if (!toggle) setToggle1(false);
  }, [toggle]);

  return (
    <OutsideClickHandler onOutsideClick={() => setToggle(false)}>
      <div className={style.card_container}>
        <div className={style.item_btn} onClick={() => setToggle(!toggle)}>
          <span>{data.invoice_id}</span>
          <i className={`bi bi-chevron-${toggle ? "up" : "down"}`} />
        </div>
        <div
          className={`${style.detail_container} ${toggle ? style.show : ""}`}
        >
          <div className={trans_style.inner_trans_container2}>
            <div className={`${trans_style.trans_item_container}`}>
              <div
                className={`${trans_style.trans_item} ${
                  toggle1 ? trans_style.show : ""
                }`}
              >
                {item_details.map((_data, idx) => {
                  const key = `${_data.dtl_product_id}_${idx}`;
                  const { product, varian } = _data;
                  const img = varian ? varian.image : product.photo[0].name;
                  const img_link = `${BASE_URL_IMG}100/100/${seller_details.id}/${img}`;
                  const price = varian ? varian.fix_price : product.fix_price;
                  const name = `${product.name} ${varian?.name1 ?? ""} ${
                    varian?.name2 ?? ""
                  }`;
                  let subTotal = _data.qty * price;
                  let note = _data.note;
                  let note_flg = note === "";
                  note = note_flg ? "Tidak ada catatan" : note;

                  if ((!toggle && idx === 0) || toggle) {
                    return (
                      <div className={item_style.p_container} key={key}>
                        <img src={img_link} />
                        <table className={item_style.trans_item_detail}>
                          <tbody>
                            <tr style={{ fontSize: "1.2rem" }}>
                              <td colSpan={3}>{seller_details.name}</td>
                            </tr>
                            <tr>
                              <td colSpan={3}>{`${_data.qty} x ${name}`}</td>
                            </tr>
                            <tr className={item_style.smallText}>
                              <td>Subtotal</td>
                              <td>:</td>
                              <td>{formatCurrency(subTotal)}</td>
                            </tr>
                            <tr className={item_style.note}>
                              <td>Catatan</td>
                              <td>:</td>
                              <td className={note_flg ? item_style._note : ""}>
                                {note}
                              </td>
                            </tr>
                            <tr className={item_style.smallText}>
                              <td>Pengiriman</td>
                              <td>:</td>
                              <td>{courier}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    );
                  }
                  return;
                })}
              </div>
              {item_details.length > 1 && (
                <div
                  className={trans_style.show_more}
                  onClick={() => setToggle1(!toggle1)}
                >
                  {!toggle1
                    ? `+${item_details.length - 1} Produk lainnya`
                    : "Lihat lebih sedikit"}
                </div>
              )}
            </div>
          </div>
          <div className={trans_style.trans_detail}>
            <table className={trans_style.detail} style={{ width: "unset" }}>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <hr style={{ margin: "0" }} />
                  </td>
                </tr>
                <tr>
                  <td>Metode Pembayaran</td>
                  <td>:</td>
                  <td>{transaction_details.payment_type.name}</td>
                </tr>
                <tr>
                  <td>Total Bayar</td>
                  <td>:</td>
                  <td>{formatCurrency(transaction_details.gross_amount)}</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <hr style={{ margin: "0" }} />
                  </td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>:</td>
                  <td>{customer_details.first_name}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>:</td>
                  <td>{_address}</td>
                </tr>
                <tr>
                  <td>No.Hp</td>
                  <td>:</td>
                  <td>{phone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
}

export default InvoiceView;
