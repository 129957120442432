import React from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import { formatDate } from "../../../../controller/globalController";
import { Button } from "../../../CustomComponent/StyledComponent";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function SalesReportExcel({ dataset, ...props }) {
  return (
    <ExcelFile
      element={
        <Button active disabled={dataset.length === 0}>
          Ekspor Excel
        </Button>
      }
      filename="Laporan Penarikan Dana"
    >
      <ExcelSheet data={dataset} name="Laporan Penjualan">
        <ExcelColumn label="Nomor" value="no" />
        <ExcelColumn label="Nomor Invoice" value="invoice_id" />
        <ExcelColumn
          label="Tanggal Pembayaran"
          value={(col) =>
            formatDate(col.transaction_date, "DD-MM-YYYY HH:mm:ss")
          }
        />
        <ExcelColumn label="Status Terakhir" value="stat_name" />
        <ExcelColumn
          label="Tanggal Pesanan Selesai"
          value={(col) => {
            if (col.stat_id === 5)
              return formatDate(col.updated_at, "DD-MM-YYYY");
            return "";
          }}
        />
        <ExcelColumn
          label="Waktu Pesanan Selesai"
          value={(col) => {
            if (col.stat_id === 5)
              return formatDate(col.updated_at, "HH:mm:ss");
            return "";
          }}
        />
        <ExcelColumn
          label="Tanggal Pesanan Dibatalkan"
          value={(col) => {
            if (col.stat_id === 99)
              return formatDate(col.updated_at, "DD-MM-YYYY");
            return "";
          }}
        />
        <ExcelColumn
          label="Waktu Pesanan Dibatalkan"
          value={(col) => {
            if (col.stat_id === 99)
              return formatDate(col.updated_at, "HH:mm:ss");
            return "";
          }}
        />
        <ExcelColumn label="Nama Produk" value="product_name" />
        <ExcelColumn label="Nomor SKU" value="sku" />
        <ExcelColumn label="Catatan Produk Pembeli" value="note" />
        <ExcelColumn label="Jumlah Produk Dibeli" value="qty" />
        <ExcelColumn label="Harga Awal (IDR)" value="price" />
        <ExcelColumn label="Diskon Produk (IDR)" value="product_discount" />
        <ExcelColumn label="Harga Jual (IDR)" value="fix_price" />
        <ExcelColumn
          label="Nilai Voucher Toko Terpakai (IDR)"
          value="promo_discount"
        />
        <ExcelColumn
          label="Kode Voucher Toko Yang Digunakan"
          value="promo_code"
        />
        <ExcelColumn label="Total Penjualan (IDR)" value="total_price" />
        <ExcelColumn label="Nama Pembeli" value="user_name" />
        <ExcelColumn label="No Telp Pembeli" value="user_phone" />
        <ExcelColumn label="Nama Penerima" value="receiver_name" />
        <ExcelColumn label="No Telp Penerima" value="receiver_phone" />
        <ExcelColumn label="Alamat Pengiriman" value="address" />
        <ExcelColumn label="Kota" value="city" />
        <ExcelColumn label="Provinsi" value="province" />
        <ExcelColumn label="Nama Kurir" value="courier" />
        <ExcelColumn label="No Resi / Kode Booking" value="awb" />
      </ExcelSheet>
    </ExcelFile>
  );
}

export default SalesReportExcel;
