import React, { useState } from "react";
import style from "../../../css/sellerProfile/promo.module.css";
import { PROMOTION, REFERAL } from "../../../utils/ConstantSeller";
import { Button } from "../../CustomComponent/StyledComponent";
import PromotionSettingView from "./PromotionSettingView";
import ReferralSettingView from "./ReferralSettingView";

function PromoMain(props) {
  const [tab, setTab] = useState(REFERAL);

  return (
    <div className={style.container}>
      <div className={style.menu_container}>
        <Button active onClick={() => setTab(REFERAL)}>
          Pengaturan Kode Referral
        </Button>
        <Button active onClick={() => setTab(PROMOTION)}>
          Pengaturan Promosi
        </Button>
      </div>
      <div className={style.content}>
        {tab === REFERAL && <ReferralSettingView />}
        {tab === PROMOTION && <PromotionSettingView />}
      </div>
    </div>
  );
}

export default PromoMain;
