import dayjs from "dayjs";
import React, { useState, useMemo } from "react";
import { formatDate } from "../../../controller/globalController";
import style from "../../../css/userProfile/profile.module.css";
import _style from "../../../css/fileInputButton.module.css";

import { Button } from "../../CustomComponent/StyledComponent";
import ChangeEmailHpModalView from "./ChangeEmailHpModalView";
import ChangePasswordModalView from "./ChangePasswordModalView";
import ChangeProfileModalView from "./ChangeProfileModalView";
import { BASE_URL_UPLOAD_IMG } from "../../../utils/Constants";
import UserImageCropModalView from "./UserImageCropModalView";
import { getUserProfile } from "../../../controller/authController";
import UserProfileLoading from "../../Loading/UserProfileLoading";

const initState = {
  id: "",
  name: "",
  email: "",
  handphone: "",
  img: null,
  prev_img: null,
  dob: null,
  gender: null,
  is_verified_email: 0,
  is_verified_handphone: 0,
  referral_code: null,

  // crop
  image_crop: undefined,
  crop_toggle: false,

  //
  pass_toggle: false,
  profile_toggle: false,
  hp_email_toggle: false,
  type: "",
  is_loading: false,
};

function UserProfileMain(props) {
  const [state, setState] = useState({ initState });
  const { profile_toggle, hp_email_toggle, type } = state;

  // page variable
  const dob_flag = state.dob === null;
  const gender_flag = state.gender === null;
  const email_flag = state.email === "";
  const hp_flag = state.handphone === "";
  const dob_btn_lbl = dob_flag ? "Tambah Tanggal Lahir" : "Ubah";
  const gender_btn_lbl = gender_flag ? "Tambah Jenis Kelamin" : "Ubah";
  const email_btn_lbl = email_flag ? "Tambah Email" : "Ubah";
  const hp_btn_lbl = hp_flag ? "Tambah Nomor Telepon" : "Ubah";

  // fucntion
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeModalProfile = (profile_toggle = !profile_toggle, type, data) => {
    const new_data = data ?? state[type];
    changeState({ profile_toggle, type: data ? "" : type, [type]: new_data });
  };

  const changeImage = async (e) => {
    const image_crop = e.target.files[0];
    changeState({ image_crop, crop_toggle: true });
  };

  const setCroppedImage = (logo) => {
    const img = logo;
    const prev_img = `${BASE_URL_UPLOAD_IMG}/cache/usr/100/100/${state.id}/${logo}`;
    changeState({ image_crop: undefined, crop_toggle: false, img, prev_img });
  };

  const changeModalEmailHp = (hp_email_toggle, type, data) => {
    const new_data = data ?? state[type];
    const verified = `is_verified_${type === "email" ? "email" : "handphone"}`;
    const _data = { hp_email_toggle, type: data ? "" : type, [type]: new_data };
    changeState({ ..._data, [verified]: 1 });
  };

  const initPage = async () => {
    changeState({ is_loading: true });
    const userData = await getUserProfile();
    if (userData) {
      const { logo } = userData;
      userData.img = logo ?? null;
      userData.prev_img = logo
        ? `${BASE_URL_UPLOAD_IMG}/cache/usr/100/100/${userData.id}/${logo}`
        : null;
      delete userData[("seller", "logo")];
      changeState({ ...userData, is_loading: false });
    }
  };

  // useEffect
  useMemo(() => initPage(), []);

  if (state.is_loading) return <UserProfileLoading />;
  return (
    <>
      {/* modal */}
      {state.pass_toggle && (
        <ChangePasswordModalView
          toggle={state.pass_toggle}
          popupHandler={() => changeState({ pass_toggle: false })}
        />
      )}
      {profile_toggle && type !== "" && (
        <ChangeProfileModalView
          type={type}
          toggle={profile_toggle}
          popupHandler={changeModalProfile}
        />
      )}
      {hp_email_toggle && type !== "" && (
        <ChangeEmailHpModalView
          value={state[type]}
          type={type}
          toggle={hp_email_toggle}
          popupHandler={changeModalEmailHp}
        />
      )}
      {state.crop_toggle && state.image_crop && (
        <UserImageCropModalView
          baseImg={state.image_crop}
          toggle={state.crop_toggle}
          popupHandler={setCroppedImage}
        />
      )}
      {/* main */}
      <div className={style.container}>
        <div className={style.foto_container}>
          <img className={style.foto} src={state.prev_img} />
          <div className={_style.container}>
            <input
              id="file"
              type="file"
              value=""
              onChange={(e) => changeImage(e)}
              accept="image/png, image/jpeg, image/jpg"
            />
            <label htmlFor="file" className={_style.btn}>
              {"Ubah"}
            </label>
          </div>
        </div>
        <div className={style.form_container}>
          <div className={style.title}>Biodata Diri</div>
          <div className={style.field_container}>
            <label className={style.input_label}>Nama</label>
            <div className={style.input_container}>
              <label className={style.text}>{state.name}</label>
              <label
                className={style.btn_text}
                onClick={() => changeModalProfile(true, "name")}
              >
                Ubah
              </label>
            </div>
          </div>
          <div className={style.field_container}>
            <label className={style.input_label}>Tanggal Lahir</label>
            <div className={style.input_container}>
              {!dob_flag && (
                <label className={style.text}>
                  {formatDate(dayjs(state.dob).toDate(), "DD MMMM YYYY")}
                </label>
              )}
              <label
                className={style.btn_text}
                onClick={() => changeModalProfile(true, "dob")}
              >
                {dob_btn_lbl}
              </label>
            </div>
          </div>
          <div className={style.field_container}>
            <label className={style.input_label}>Jenis Kelamin</label>
            <div className={style.input_container}>
              {!gender_flag && (
                <label className={style.text}>
                  {state.gender === "1" ? "Laki -  laki" : "Perempuan"}
                </label>
              )}
              <label
                className={style.btn_text}
                onClick={() => changeModalProfile(true, "gender")}
              >
                {gender_btn_lbl}
              </label>
            </div>
          </div>
          <div className={style.title}>Kontak</div>
          <div className={style.field_container_contact}>
            <label className={style.input_label}>Email</label>
            <div className={style.input_container}>
              {!email_flag && (
                <div className={style.inner_container}>
                  <label className={style.text}>{state.email}</label>

                  <label className={style.text_verification}>
                    {state.is_verified_email === 1
                      ? "Terverifikasi"
                      : "Belum Terverifikasi"}
                  </label>
                </div>
              )}
              <label
                className={style.btn_text}
                onClick={() => changeModalEmailHp(true, "email")}
              >
                {email_btn_lbl}
              </label>
            </div>
          </div>
          <div className={style.field_container_contact}>
            <label className={style.input_label}>Nomor HP</label>
            <div className={style.input_container}>
              {!hp_flag && (
                <div className={style.inner_container}>
                  <label className={style.text}>{state.handphone}</label>
                  <label className={style.text_verification}>
                    {state.is_verified_handphone === 1
                      ? "Terverifikasi"
                      : "Belum Terverifikasi"}
                  </label>
                </div>
              )}
              <label
                className={style.btn_text}
                onClick={() => changeModalEmailHp(true, "handphone")}
              >
                {hp_btn_lbl}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className={style.btn_container}>
        {state.email !== "" && (
          <Button
            active
            onClick={() => changeState({ pass_toggle: !state.pass_toggle })}
          >
            Ubah Kata Sandi
          </Button>
        )}
      </div>
    </>
  );
}

export default UserProfileMain;
