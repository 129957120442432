import React from "react";
import { useState } from "react";
import style from "../../css/fileInputButton.module.css";

function InputImage({
  changeImage,
  deleteImage,
  input_text = undefined,
  btn_text,
  img_link,
  name,
  round = false,
  is_edit = true,
  ...props
}) {
  const [error, setError] = useState("");
  const img_flag = img_link !== null;
  const _input_text = round ? "+" : "+ Pilih Gambar";
  const input_class = `${style.btn_input} ${round ? style.round : ""}`;
  const id = `file_${name}`;
  const cursorstyle = { cursor: is_edit ? "pointer" : "initial" };
  const container_style = props.container_style ?? {
    width: round ? "fit-content" : "100%",
  };

  const onChangeImg = (e) => {
    const img_size = e.target.files[0].size;
    const flag_img_size = img_size <= 16000000;
    setError(flag_img_size ? "" : "Maksimal ukuran file gambar adalah 16Mb.");
    if (flag_img_size) changeImage(e);
  };

  return (
    <div className={style.container} style={container_style}>
      <input
        id={id}
        type="file"
        value=""
        name={name}
        onChange={(e) => onChangeImg(e)}
        accept="image/png, image/jpeg, image/jpg"
        disabled={!is_edit}
      />
      {!img_flag && (
        <label htmlFor={id} className={input_class} style={cursorstyle}>
          {input_text !== undefined ? input_text : _input_text}
        </label>
      )}
      {img_flag && (
        <>
          <div className={style.img_btn_container}>
            <label htmlFor={id} className={style.img_btn}>
              <img src={img_link} style={cursorstyle} />
            </label>
            {is_edit && (
              <i
                className="bi bi-trash-fill"
                onClick={() => deleteImage(name)}
              />
            )}
          </div>
          {is_edit && (
            <label htmlFor={id} className={style.btn}>
              {btn_text}
            </label>
          )}
        </>
      )}
      <div className={`error_txt`} style={{ paddingTop: "0.5rem" }}>
        {error}
      </div>
    </div>
  );
}

export default InputImage;
