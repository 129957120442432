import React from "react";
import style from "../../css/chat.module.css";
import { BASE_URL_IMG, BASE_URL_UPLOAD_IMG } from "../../utils/Constants";

function ChatGroupCard({
  data,
  type = "buyer",
  selected_room = null,
  ...props
}) {
  if (!["buyer", "seller"].includes(type)) return "";
  const s_flag = type === "seller";
  const selected_flag = selected_room?.room_id === data.room_id;
  const last_msg_flag = data.last_message !== "";

  const unread_flag = data[`unread_message_${s_flag ? "seller" : "buyer"}`] > 0;
  var unread = data[`unread_message_${s_flag ? "seller" : "buyer"}`];
  if (parseInt(unread) > 99) unread = `99 +`;
  const active_user = s_flag ? data.user : data.seller;
  const active_id = s_flag ? data.user_id : data.seller_id;
  var img_link = !s_flag ? BASE_URL_IMG : `${BASE_URL_UPLOAD_IMG}cache/usr/`;
  img_link = `${img_link}30/30/${active_id}/${active_user.logo}`;

  const card_class = `${style.group_chat_container} ${
    selected_flag ? style.active_room : ""
  }`;

  return (
    <div className={card_class} onClick={() => props.onClick(data)}>
      <img src={img_link} />
      <div className={style.chat_identity}>
        <div id={style.name}>{active_user.name}</div>
        {last_msg_flag && (
          <div id={style.small_text}>{`Pesan : ${data.last_message}`}</div>
        )}
      </div>
      {unread_flag && <label className={style.badge}>{unread}</label>}
    </div>
  );
}

export default ChatGroupCard;
