import React, { useState, useEffect } from 'react';
import style from "../../../css/sellerProfile/etalase.module.css";
import CustomModal, { HeaderModal } from '../../CustomComponent/CustomModal';
import { Button, Input } from '../../CustomComponent/StyledComponent';
import { addUpdateSubEtalase } from '../../../controller/productEtalaseController';
import Swal from 'sweetalert2';

const initState = { id: undefined, name: "", error: "" };
function SubEtalaseFormModalView({ data, etalase, toggle = false, popupHandler = () => { }, ...props }) {
    const title = `${data ? "Ubah" : "Tambah"} Sub Etalase ${etalase?.name ?? ""}`;
    const [state, setState] = useState(initState)

    const changeState = (data) => setState((prev) => ({ ...prev, ...data }))
    const handleClose = () => popupHandler(false, undefined, "CLOSE")
    const validation = () => {
        const { name } = state;
        let temp = true;
        let error = "";
        const flag = name === "" || name.length < 2;
        if (flag) temp = false;
        if (flag) error = "Nama etalase minimal 2 huruf";
        changeState({ error });
        return temp;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validation()) { return Swal.close(); }
        Swal.fire({ didOpen: () => Swal.showLoading(), allowOutsideClick: false });
        const res = await addUpdateSubEtalase({ ...state, etalase_id: etalase.id });
        if (!res) { return Swal.fire({ title: "Gagal", icon: "error" }) }
        Swal.fire({ title: "Berhasil", icon: "success" })
            .then(() => popupHandler(false, undefined, "FINISH"));
    };

    // useEffect
    useEffect(() => {
        if (data) changeState({ id: data.id, name: data.name });
        return () => setState(initState);
    }, [data]);

    return (
        <CustomModal outsideClick toggle={toggle} size="sm" modalHandler={handleClose}>
            <HeaderModal title={title} modalHandler={handleClose} />
            <form className={style.form_container} onSubmit={(e) => handleSubmit(e)}>
                <div className="form-group" style={{ margin: "5px 0" }}>
                    <label htmlFor="name" style={{ margin: "0", fontSize: "0.9rem" }}>
                        Nama Sub Etalase
                    </label>
                    <Input
                        type="text"
                        id="name"
                        className="form-control"
                        value={state.name}
                        onChange={(e) => changeState({ name: e.target.value, error: "" })}
                        autoFocus={true}
                    />
                    {state.error !== "" && <div className="error_txt">{state.error}</div>}
                </div>
                <Button active type="submit"> Simpan</Button>
            </form>
        </CustomModal>
    );
}

export default SubEtalaseFormModalView;