import Cookies from "js-cookie";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loading from "../components/Loading/Loading";
import MidtransPage from "../pages/MidtransPage";
import { ADD_PRODUCT_SMENU, EDIT_PRODUCT_SMENU, TRANS_SMENU, getSellerMenu } from "../utils/ConstantSeller";
import { TRANS_UMENU, UMENU_ROUTES } from "../utils/ConstantUser";
import { globalStorage } from "../utils/Storage";
import { isDomain } from "../controller/authController";

// pages / component
const Cart = React.lazy(() => import("../pages/Cart"));
const DetailProduct = React.lazy(() => import("../pages/ProductDetail"));
const Login = React.lazy(() => import("../pages/Login"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
const NoMatch = React.lazy(() => import("../pages/NoMatchPage"));
const Print = React.lazy(() => import("../pages/Print"));
const ProfileUser = React.lazy(() => import("../pages/ProfileUser"));
const ProfileSeller = React.lazy(() => import("../pages/ProfileSeller"));
const Register = React.lazy(() => import("../pages/Register"));
const Search = React.lazy(() => import("../pages/Search"));
const Shipment = React.lazy(() => import("../pages/Shipment"));
const CommunityListHome = React.lazy(() =>
  import("../pages/CommunityListHome")
);
const TermAndPolicy = React.lazy(() => import("../pages/TermAndPolicy"));
// const EcosystemGatePage = React.lazy(() =>
//   import("../pages/EcosystemGatePage")
// );

// store
const StoreHome = React.lazy(() => import("../pages/StoreHome"));
// merchant
const MerchantHome = React.lazy(() => import("../pages/MerchantHome"));
const CreateStore = React.lazy(() => import("../pages/CreateStore"));
// community
const CommunityHome = React.lazy(() => import("../pages/CommunityHome"));
// ecosystem
const EcosystemHome = React.lazy(() => import("../pages/EcosystemHome"));

export function LazyComponent(component, fallback = <Loading />) {
  return <React.Suspense fallback={fallback}>{component}</React.Suspense>;
}

function AuthLazyComponent(component, fallback = <Loading />) {
  const token = Cookies.get("access_token");
  const path = useLocation().pathname;
  if (!token) return <Navigate to="/login" state={{ prev: path }} />;
  return <React.Suspense fallback={fallback}>{component}</React.Suspense>;
}

function LandingPage(c_flag, s_flag, eco_flag) {
  if (c_flag) return LazyComponent(<CommunityHome />);
  if (eco_flag) return LazyComponent(<EcosystemHome />);
  if (s_flag && !c_flag && !eco_flag) return LazyComponent(<StoreHome />);
  return LazyComponent(<MerchantHome />);
}

function RestrictPage(flag, component) {
  return LazyComponent(flag ? component : <NoMatch />);
}

function RestricSellerDashboard(current_store, sub_seller) {
  const acc_from = isDomain ? 'domain' : 'subdomain';
  const userData = globalStorage.getItem("UD");
  const own_store = userData?.seller?.[acc_from] ?? "";
  if (current_store?.[acc_from] !== own_store) return <Navigate replace to={"/"} />;
  const admin_flag = userData.user_level_id === 2 && (userData?.seller?.is_private ?? 0)
  return AuthLazyComponent(<ProfileSeller sub_seller={sub_seller} admin_user={admin_flag} />);
}

export default function Routers() {
  const current_store = globalStorage.getItem("SD");

  const s_flag = current_store !== undefined;
  const c_flag = useSelector((s) => s.global.communityFlag);
  const cg_flag = useSelector((s) => s.global.cgFlag);

  const seller = useSelector((s) => s.global.userData?.seller);
  const sub_seller = useSelector((s) => s.global.userData?.user_level_id) === 8;

  const eco_flag = current_store?.is_ecosystems === 1 ?? false;
  const seller_menu = getSellerMenu(cg_flag, sub_seller);

  // route var
  const store_nav = sub_seller ? "/seller_dashboard/performance" : "/seller_dashboard"
  const s_nav = seller !== null ? store_nav : "/"; // nav route if not seller or cg seller

  return (
    <Routes>
      <Route path="/" element={LandingPage(c_flag, s_flag, eco_flag)} />
      <Route path="/login" element={LazyComponent(<Login />)} />
      <Route path="/terms" element={LazyComponent(<TermAndPolicy type="term" />)} />
      <Route path="/privacy" element={LazyComponent(<TermAndPolicy type="privasi" />)} />
      <Route
        path="/reset_password"
        element={LazyComponent(<ResetPassword />)}
      />
      <Route
        path="/register"
        element={LazyComponent(<Register process="register" />)}
      />
      <Route path="/search" element={LazyComponent(<Search />)} />
      <Route
        path="/midtrans/:token"
        element={LazyComponent(<MidtransPage />)}
      />
      <Route path="/no_page" element={LazyComponent(<NoMatch />)} />
      <Route path="*" element={LazyComponent(<NoMatch />)} />

      {/* product route */}
      <Route path="/category">
        <Route path="" element={LazyComponent(s_flag ? <StoreHome /> : <NoMatch />)} />
        <Route path=":id" element={LazyComponent(s_flag ? <StoreHome /> : <NoMatch />)} />
        <Route path=":id/:sub_id" element={LazyComponent(s_flag ? <StoreHome /> : <NoMatch />)} />
      </Route>
      <Route
        path="/product/:product_id"
        element={LazyComponent(<DetailProduct />)}
      />

      {/* auth route */}
      <Route path="/print/:type/:inv" element={AuthLazyComponent(<Print />)} />
      <Route path="/cart">
        <Route path="" element={AuthLazyComponent(<Cart />)} />
        <Route path="shipment" element={AuthLazyComponent(<Shipment />)} />
      </Route>
      <Route path="/user_profile">
        {UMENU_ROUTES.map((data, idx) => {
          const flag = data === TRANS_UMENU;
          const path = flag ? `${data}/:process` : data;
          return (
            <Route
              key={`um_${idx}`}
              path={path}
              element={AuthLazyComponent(<ProfileUser />)}
            />
          );
        })}
      </Route>

      <Route path="/seller_dashboard" >
        {sub_seller && <Route path="" element={<Navigate replace to={s_nav} />} />}
        <Route path="*" element={<Navigate replace to={s_nav} />} />
        {seller !== null &&
          seller_menu.map((data, idx) => {
            let path = data;
            if (data === TRANS_SMENU) path = `${data}/:process`;
            if (data === EDIT_PRODUCT_SMENU) path = `${data}/:product_id`;
            return (
              <Route
                key={`sm_${idx}`}
                path={path}
                element={RestricSellerDashboard(current_store, sub_seller)}
              />
            );
          })}
        <Route
          path={`${ADD_PRODUCT_SMENU}/:product_id`}
          element={RestricSellerDashboard(current_store, sub_seller)}
        />
      </Route>

      {/* outside store route */}
      <Route
        path="/create_store"
        element={AuthLazyComponent(
          !s_flag || eco_flag ? <CreateStore /> : <NoMatch />
        )}
      />
      <Route path="/community_home">
        <Route path="" element={RestrictPage(!s_flag, <CommunityListHome />)} />
        <Route
          path=":seller_id"
          element={RestrictPage(!s_flag || eco_flag, <CommunityListHome />)}
        />
      </Route>
      {/* <Route
        path="/ecosystem_login/:user/:key/:hp"
        element={RestrictPage(eco_flag, <EcosystemGatePage />)}
      /> */}
    </Routes>
  );
}
