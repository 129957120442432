import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';

const firebase = {
    apiKey: 'AIzaSyAiZamhEAmgOyKvFdQZCmSYAabQQYRxVGM',
    authDomain: 'satutoko-spin-international.firebaseapp.com',
    projectId: 'satutoko-spin-international',
    storageBucket: 'satutoko-spin-international.appspot.com',
    messagingSenderId: '456638859028',
    appId: '1:456638859028:web:bac57b83e9facbc6762401',
}
const app = initializeApp(firebase);
const db = getDatabase(app);
const firestore = getFirestore(app);

export { db }
export default firestore;