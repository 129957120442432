import React, { useEffect } from "react";
import { useState } from "react";
import LocationInputView from "./LocationInputView";
import LocInputAutoComplete from "./LocInputAutoComplete";
import { Button, Input } from "../CustomComponent/StyledComponent";
import style from "../../css/location/locationForm.module.css";
import MapModalView from "./MapModalView";
import { googleMapsKey, phoneRegex2, regexName } from "../../utils/Constants";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { addUpdateUserLoc } from "../../controller/userLocationController";
import { addUpdateSellerLoc } from "../../controller/sellerLocationController";
import Swal from "sweetalert2";
import { geocodeByAddress } from "react-places-autocomplete";
import LocationFormLoading from "../Loading/LocationFormLoading";
import { useMemo } from "react";

function InputView(props) {
  const { value, onChange, label, name, placeholder = "", max = 50 } = props;
  const error = value[`error_${name}`];
  return (
    <div className="line_input_container">
      <label htmlFor={name}>{label}</label>
      <Input
        value={value[name]}
        id={name}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        maxLength={max}
      />
      {error !== "" && <label className="error_txt">{error}</label>}
    </div>
  );
}

function InputPhoneView(props) {
  const { value, onChange, label, name } = props;
  const error = value[`error_${name}`];
  return (
    <div className="line_input_container">
      <label htmlFor={name}>{label}</label>
      <div className="group_input">
        <span id="front">+62</span>
        <Input value={value[name]} id={name} onChange={(e) => onChange(e)} />
      </div>
      {error !== "" && <label className="error_txt">{error}</label>}
    </div>
  );
}

const initState = {
  id: undefined,
  name: "",
  hp: "",
  alias: "",
  address: "",
  dtlAddress: undefined,
  locDetail: undefined,
  lng: 0,
  lat: 0,
  detail: "",
  map_toggle: false,
  is_loading: true,

  // error
  error_name: "",
  error_hp: "",
  error_alias: "",
  error_address: "",
  error_locDetail: "",
};

function LocationFormView(props) {
  const { data = undefined, process = "ADD", popupHandler, seller } = props;
  const [state, setState] = useState(initState);
  const { lng, lat, map_toggle } = state;
  const flag = lat !== 0 && lng !== 0;
  const sent_data = {
    id: state.id,
    name: state.name,
    hp: state.hp,
    alias: state.alias,
    zip_id: state.locDetail?.zip_id ?? undefined,
    address: state.address,
    longitude: state.lng,
    latitude: state.lat,
    detail: state.detail,
  };

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));

  const setUpdateData = () => {
    const { zip } = data;
    const locDetail = {
      district: zip.district,
      province: zip.province,
      subDistrict: zip.sub_district,
      subProvince: zip.sub_province,
      zip: zip.zip,
      zip_id: zip.id,
    };
    const dtlAddress = `${zip.province}, ${zip.subProvince}, ${zip.district}, ${zip.subDistrict}, ${zip.zip}`;
    changeState({
      id: data.id,
      name: data.name,
      hp: data.hp.replaceAll("+62", ""),
      alias: data.alias,
      address: data.address,
      dtlAddress,
      locDetail,
      lng: data.longitude,
      lat: data.latitude,
      detail: data.detail,
      is_loading: false,
    });
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    changeState({ [id]: value, [`error_${id}`]: "" });
  };

  const handleChangeName = (event) => {
    const { id, value } = event.target;
    if (regexName.test(value) || value === "") {
      changeState({ [id]: value, [`error_${id}`]: "" });
    }
  };

  const handleChangePhone = (event) => {
    const { id, value } = event.target;
    const phoneregex = /^$|^[^0\D][0-9]{0,13}$/;
    if (phoneregex.test(value) || value === "") {
      changeState({ [id]: value, error_hp: "" });
    }
  };

  const handleChangeLoc = async (address, detail) => {
    let temp1 = await geocodeByAddress(address);
    changeState({
      dtlAddress: address,
      locDetail: detail,
      lng: temp1[0].geometry.location.lng(),
      lat: temp1[0].geometry.location.lat(),
      address: "",
    });
  };

  const handleChangeAddress = async (address, LatLng) => {
    let { lat, lng } = LatLng;
    if (lat !== state.lat && lng !== state.lng) {
      changeState({ address, lat: lat, lng: lng });
    } else changeState({ address });
  };

  const handleMapModal = (toggle, LatLng) => {
    changeState({ map_toggle: toggle, lat: LatLng.lat, lng: LatLng.lng });
  };

  const checkSubmit = () => {
    let res = true;
    let _err = {
      error_name: "",
      error_hp: "",
      error_alias: "",
      error_address: "",
      error_locDetail: "",
    };
    const { name, hp, address, alias, zip_id } = sent_data;
    const phone_flag = !phoneRegex2.test(hp) || hp === "";
    const empty_flag = name === "" || address === "" || alias === "";
    const length_flag = name.length < 3 || alias.length < 3;
    if (empty_flag || phone_flag || length_flag) res = false;
    if (name === "") _err.error_name = "Nama Penerima tidak boleh kosong";
    if (name.length < 3) _err.error_name = "Nama Penerima minimal 3 karakter";
    if (!phoneRegex2.test(hp)) _err.error_hp = "Nomor telepon tidak valid";
    if (hp === "") _err.error_hp = "Nomor telepon tidak boleh kosong";
    if (address === "") _err.error_address = "Alamat tidak boleh kosong";
    if (!zip_id) _err.error_locDetail = "Lokasi tidak boleh kosong";
    if (alias === "") _err.error_alias = "Label Alamat tidak boleh kosong";
    if (alias.length < 3) _err.error_alias = "Label Alamat minimal 3 karakter";

    changeState(_err);
    return res;
  };

  const handleSubmit = () => {
    if (checkSubmit()) {
      Swal.fire({
        didOpen: async () => {
          Swal.showLoading();
          let res = undefined;
          if (!seller) res = await addUpdateUserLoc(sent_data, process);
          if (seller) res = await addUpdateSellerLoc(sent_data, process);
          Swal.close();
          if (res) popupHandler(false, "FINISH");
        },
        allowOutsideClick: false,
      });
    }
  };

  const maps = useMemo(() => {
    if (state.is_loading) return <></>;
    return (
      <Map
        google={props.google}
        zoom={17}
        initialCenter={{ lat, lng }}
        center={{ lat, lng }}
        disableDefaultUI={true}
        draggable={false}
        containerStyle={{
          position: "relative",
          width: "100%",
          height: "100px",
        }}
        visible={flag}
      >
        <Marker position={{ lat, lng }} />
      </Map>
    );
  }, [lat, lng]);

  // useEffect
  useEffect(() => {
    if (data) setUpdateData();
    else changeState({ is_loading: false });
    return () => setState(initState);
  }, []);

  if (state.is_loading) return <LocationFormLoading />;
  return (
    <>
      {flag && (
        <MapModalView
          google={props.google}
          toggle={map_toggle}
          handleToggle={handleMapModal}
          LatLng={{ lat, lng }}
        />
      )}
      <div>
        <InputView
          label="Label Alamat"
          name="alias"
          value={state}
          onChange={handleChange}
          placeholder="Cth: Rumah, Kantor, dll"
        />
        <div className={style.line_container}>
          <InputView
            label="Nama Penerima"
            name="name"
            value={state}
            onChange={handleChangeName}
          />
          <InputPhoneView
            label="No.Ponsel"
            name="hp"
            value={state}
            onChange={handleChangePhone}
          />
        </div>
        <LocationInputView
          location={state.locDetail}
          getLocation={handleChangeLoc}
          error={state.error_locDetail}
        />
        <LocInputAutoComplete
          _loc={state.locDetail}
          address={state.address}
          lng={state.lng}
          lat={state.lat}
          getAddress={handleChangeAddress}
          error={state.error_address}
        />
        <InputView
          label="Detail Lainnya"
          name="detail"
          value={state}
          placeholder="Cth: Blok / Unit No. , Patokan"
          onChange={handleChange}
          max={150}
        />
        <div className={style.map_container}>
          <div
            className={`${style.map_cover} ${!flag ? style.disabled : ""}`}
            onClick={() => {
              if (state.address !== "") changeState({ map_toggle: true });
            }}
          >
            <div id={style.btn}>Tambah Lokasi</div>
          </div>
          {maps}
        </div>
        <div className={style.line_container}>
          <Button active onClick={() => popupHandler(false, "CLOSE")}>
            BATAL
          </Button>
          <Button active onClick={() => handleSubmit()}>
            SIMPAN
          </Button>
        </div>
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: googleMapsKey,
  libraries: ["places", "geometry"],
  language: "ID",
  region: "ID",
})(LocationFormView);
